<div class="lounges-container">
  <ng-container *ngIf="showFullContent">
    <div class="header-container">
      <div class="header-content">
        <h2 class="header-title">Lounges</h2>
        <p class="description">
          List of lounges and their corresponding airport details.
        </p>
      </div>
    </div>
    <aims-layout-toolbar>
      <button mat-icon-button (click)="addNew()">
        <mat-icon>add</mat-icon>
      </button>
      <button mat-icon-button (click)="refresh()">
        <mat-icon>refresh</mat-icon>
      </button>
    </aims-layout-toolbar>

    <aims-query-layout>
      <aims-search-bar
        left
        componentName="lounges"
        matTooltip="Enter at least 3 characters for search"
        matTooltipPosition="above"
        (termsChanged$)="onSearchResults($event)"
        (reset$)="onReset()"
        (clearTerms$)="fetchAllOnClear()"
      ></aims-search-bar>
    </aims-query-layout>
  </ng-container>

  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="loungeName">
        <th mat-header-cell *matHeaderCellDef>Lounge Name</th>
        <td mat-cell *matCellDef="let lounge">{{ lounge.loungeName }}</td>
      </ng-container>

      <ng-container matColumnDef="loungeId">
        <th mat-header-cell *matHeaderCellDef>Lounge ID</th>
        <td mat-cell *matCellDef="let lounge">{{ lounge.loungeId }}</td>
      </ng-container>

      <ng-container matColumnDef="airportCode">
        <th mat-header-cell *matHeaderCellDef>Airport Code</th>
        <td mat-cell *matCellDef="let lounge">
          {{ getAirportCode(lounge.airportId) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="airportId">
        <th mat-header-cell *matHeaderCellDef>Airport Id</th>
        <td mat-cell *matCellDef="let lounge">
          {{ lounge.airportId }}
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let lounge">
          <button
            mat-icon-button
            (click)="openEditDialog(lounge); $event.stopPropagation()"
            aria-label="Edit lounge"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="deleteLounge(lounge); $event.stopPropagation()"
            aria-label="Delete lounge"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: getDisplayedColumns()"
        (click)="redirectToLoungeDetails(row.loungeId)"
      ></tr>
    </table>
  </div>

  <div class="paginator-container">
    <aims-query-page
      [paginationModel]="paginationModel"
      (pageChange)="onPageChange($event)"
    ></aims-query-page>
  </div>
</div>
