import {
  Component,
  OnInit,
  AfterContentInit,
  ContentChild,
} from '@angular/core';
import { SearchBarComponent } from '../search-bar/search-bar.component';

@Component({
  selector: 'aims-query-layout',
  templateUrl: './query-layout.component.html',
  styleUrl: './query-layout.component.scss',
  standalone: false,
})
export class QueryLayoutComponent implements OnInit, AfterContentInit {
  @ContentChild(SearchBarComponent) searchBar!: SearchBarComponent;

  constructor() {}

  ngOnInit() {}

  ngAfterContentInit() {
    // programmatically set `noMargin` to true to ensure there is no margin when the query search component is transcluded
    if (this.searchBar) {
      this.searchBar.noMargin = true;
    }
  }
}
