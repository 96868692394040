<mat-form-field appearance="fill">
  <mat-label>{{ label }}</mat-label>
  <input
    type="text"
    matInput
    [formControl]="control"
    [matAutocomplete]="auto"
    [placeholder]="placeholder"
    autocomplete="off"
  />
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="onOptionSelected($event)"
  >
    <mat-option *ngFor="let option of filteredOptions" [value]="option">
      {{ displayFn(option) }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
