import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'aims-layout-toolbar',
  templateUrl: './layout-toolbar.component.html',
  styleUrl: './layout-toolbar.component.scss',
  standalone: false,
})
export class LayoutToolbarComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
