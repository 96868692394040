import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { catchError, finalize, tap } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { CardValidationProgramDTO } from '../../../models/dtos/cardValidationProgramDTO';
import { PaginationModel } from '../../../models/pagination-model';
import { MatPaginator } from '@angular/material/paginator';
import { CardValidationService } from '../../../services/card-validation.service';
import { MatDialog } from '@angular/material/dialog';
import { of } from 'rxjs';
import Swal from 'sweetalert2';
import { CreateCardValidationProgramComponent } from '../create-card-validation-program/create-card-validation-program.component';
import { EditCardValidationProgramComponent } from '../edit-card-validation-program/edit-card-validation-program.component';
import { Router } from '@angular/router';

@Component({
  selector: 'aims-card-validation-program',
  templateUrl: './card-validation-program.component.html',
  styleUrl: './card-validation-program.component.scss',
  standalone: false,
})
export class CardValidationProgramComponent implements OnInit, AfterViewInit {
  dataSource = new MatTableDataSource<CardValidationProgramDTO>([]);
  paginationModel: PaginationModel = new PaginationModel();
  loadingPrograms: boolean = true;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private cardValidationService: CardValidationService,
    public dialog: MatDialog,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.fetchCardValidationPrograms();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  fetchCardValidationPrograms(): void {
    const pageIndex = this.paginationModel.pageIndex + 1;
    const pageSize = this.paginationModel.pageSize;

    this.loadingPrograms = true;

    this.cardValidationService
      .getCardValidationProgramsByPagination(pageIndex, pageSize)
      .pipe(
        tap((response: any) => {
          if (response && response.data) {
            this.dataSource.data = response.data.pageData || [];
            this.paginationModel.totalCount = response.data.totalCount;
          } else {
            this.dataSource.data = [];
          }
        }),
        finalize(() => (this.loadingPrograms = false)),
        catchError((error) => {
          console.error('Error fetching card validation programs', error);
          return of([]);
        }),
      )
      .subscribe();
  }

  onPageChange(event: { pageIndex: number; pageSize: number }): void {
    this.paginationModel.pageIndex = event.pageIndex;
    this.paginationModel.pageSize = event.pageSize;
    this.fetchCardValidationPrograms();
  }

  addNewCardValidationProgram(): void {
    const dialogRef = this.dialog.open(CreateCardValidationProgramComponent, {
      width: '550px',
    });

    dialogRef.afterClosed().subscribe((result: CardValidationProgramDTO) => {
      if (result) {
        this.cardValidationService
          .addCardValidationProgram(result)
          .pipe(
            catchError((error) => {
              console.error('Error adding card validation program', error);
              return of(null);
            }),
          )
          .subscribe((newProgram) => {
            if (newProgram) {
              this.dataSource.data.push(newProgram);
              this.dataSource._updateChangeSubscription();
            }
          });
      }
    });
  }

  refresh(): void {
    this.fetchCardValidationPrograms();
  }

  editCardValidationProgram(program: CardValidationProgramDTO): void {
    const dialogRef = this.dialog.open(EditCardValidationProgramComponent, {
      width: '550px',
      data: program,
    });

    dialogRef
      .afterClosed()
      .subscribe((updatedProgram: CardValidationProgramDTO) => {
        if (updatedProgram) {
          const index = this.dataSource.data.findIndex(
            (p) =>
              p.cardValidationProgramId ===
              updatedProgram.cardValidationProgramId,
          );
          if (index !== -1) {
            this.dataSource.data[index] = updatedProgram;
            this.dataSource._updateChangeSubscription();
          }
        }
      });
  }

  deleteProgram(program: CardValidationProgramDTO): void {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2893cc',
      cancelButtonColor: '#939597',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.cardValidationService
          .deleteCardValidationProgram(program.cardValidationProgramId)
          .pipe(
            tap(() => {
              Swal.fire({
                title: 'Deleted!',
                text: 'The program has been successfully deleted.',
                icon: 'success',
                confirmButtonColor: '#2893cc',
                confirmButtonText: 'OK',
              });

              this.dataSource.data = this.dataSource.data.filter(
                (p) =>
                  p.cardValidationProgramId !== program.cardValidationProgramId,
              );
              this.dataSource._updateChangeSubscription();
            }),
            catchError((error) => {
              const errorMessage =
                error?.message || 'Failed to delete program.';
              Swal.fire({
                title: 'Error',
                text: `Failed to delete program: ${errorMessage}`,
                icon: 'error',
                confirmButtonColor: '#2893cc',
                confirmButtonText: 'OK',
              });

              this.fetchCardValidationPrograms();

              return of(null);
            }),
          )
          .subscribe();
      }
    });
  }

  navigateToCardValidation(): void {
    this.router.navigate(['/app/card-validation']);
  }
}
