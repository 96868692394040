import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AirlineDTO } from '../../../models/dtos/airlineDTO';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AirlineService } from '../../../services/airline.service';
import { FilesService } from '../../../services/files.service';
import { GroupAssociationDTO } from '../../../models/dtos/groupAssociationDTO';
import { CardDTO } from '../../../models/dtos/cardDTO';
import { AirlinePartnerDialogComponent } from '../airline-partner-dialog/airline-partner-dialog.component';

@Component({
  selector: 'aims-airline-details',
  templateUrl: './airline-details.component.html',
  styleUrls: ['./airline-details.component.scss'],
  standalone: false,
})
export class AirlineDetailsComponent implements OnInit {
  airlineData?: AirlineDTO;
  imageUrl?: string;
  activeTab: 'overview' | 'accessDocuments' = 'overview';
  groupAssociationNames: string = 'N/A';
  cards: CardDTO[] = [];
  filteredCards: CardDTO[] = [];

  passengerFields: string[] = [
    'Name',
    'FF Number',
    'Flight',
    'PNR',
    'Origin & Destination',
    'Notes',
  ];

  guestFields: string[] = [
    'Name',
    'FF Number',
    'Flight',
    'PNR',
    'Origin & Destination',
    'Notes',
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private airlineService: AirlineService,
    private fileService: FilesService,
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const partnerId = +params['id'];
      if (partnerId) {
        this.getPartnerDetails(partnerId);
        this.fetchCards(partnerId);
      } else {
        Swal.fire({
          title: 'Error',
          text: 'Invalid airline ID.',
          icon: 'error',
          confirmButtonColor: '#2893cc',
          confirmButtonText: 'OK',
        });
      }
    });
  }

  fetchCards(airlineId: number): void {
    this.airlineService.getAirlineCards(airlineId).subscribe(
      (response: any) => {
        if (response && response.success && response.data) {
          this.cards = response.data;
          this.filteredCards = this.cards.filter(
            (card: CardDTO) => card.airlineId === airlineId,
          );
        } else {
          this.cards = [];
          this.filteredCards = [];
        }
      },
      (error) => {
        this.cards = [];
        this.filteredCards = [];
        Swal.fire({
          title: 'Error',
          text: `Failed to fetch cards: ${error.message}`,
          icon: 'error',
          confirmButtonColor: '#2893cc',
          confirmButtonText: 'OK',
        });
      },
    );
  }

  getPartnerDetails(partnerId: number): void {
    this.airlineService.getAirlineById(partnerId).subscribe({
      next: (airline) => {
        this.airlineData = airline;

        if (this.airlineData?.groupAssociations) {
          this.groupAssociationNames = this.extractGroupNames(
            this.airlineData.groupAssociations,
          );
        }

        if (this.airlineData && this.airlineData.airlineImageHandle) {
          this.fileService
            .getFileUri(this.airlineData.airlineImageHandle)
            .subscribe(
              (url) => {
                this.imageUrl = url;
              },
              (error) => {
                Swal.fire({
                  title: 'Error',
                  text: `Failed to fetch file URI: ${error.message}`,
                  icon: 'error',
                  confirmButtonColor: '#2893cc',
                  confirmButtonText: 'OK',
                });
              },
            );
        }
      },
      error: (error) => {
        Swal.fire({
          title: 'Error',
          text: `Failed to fetch airline details: ${error.message}`,
          icon: 'error',
          confirmButtonColor: '#2893cc',
          confirmButtonText: 'OK',
        });
      },
    });
  }

  extractGroupNames(
    groupAssociations: GroupAssociationDTO[] | { groupAssociationId: number }[],
  ): string {
    if (!groupAssociations || groupAssociations.length === 0) {
      return 'N/A';
    }

    if (
      typeof groupAssociations[0] === 'object' &&
      'groupName' in groupAssociations[0]
    ) {
      return (groupAssociations as GroupAssociationDTO[])
        .map((group) => group.groupName)
        .join(', ');
    }

    return (
      'Group IDs: ' +
      (groupAssociations as { groupAssociationId: number }[])
        .map((group) => group.groupAssociationId)
        .join(', ')
    );
  }

  editAirline(): void {
    const dialogRef = this.dialog.open(AirlinePartnerDialogComponent, {
      data: { airline: this.airlineData },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.airlineData = result;
        this.refresh();
      }
    });
  }
  deleteAirline(): void {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2893cc',
      cancelButtonColor: '#939597',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed && this.airlineData) {
        this.airlineService.deleteAirline(this.airlineData.airlineId).subscribe(
          () => {
            Swal.fire({
              title: 'Deleted!',
              text: 'Your airline has been deleted.',
              icon: 'success',
              confirmButtonColor: '#2893cc',
              confirmButtonText: 'OK',
            }).then(() => {
              this.router.navigate(['/app/partners']);
            });
          },
          (error) => {
            Swal.fire({
              title: 'Error',
              text: `Failed to delete airline: ${error.message}`,
              icon: 'error',
              confirmButtonColor: '#2893cc',
              confirmButtonText: 'OK',
            });
          },
        );
      }
    });
  }

  refresh() {
    window.location.reload();
  }

  switchToOverview() {
    this.activeTab = 'overview';
  }

  switchToAccessDocuments() {
    this.activeTab = 'accessDocuments';
  }

  getFieldNamesFromInteger(number: number, fields: string[]): string[] {
    const binaryOrder = [5, 4, 3, 1, 0, 2]; // This corresponds to: Name -> FF Number -> Flight -> PNR  -> Origin & Destination  -> Notes
    const binaryString = number.toString(2).padStart(6, '0');
    const reorderedBinaryString = binaryOrder
      .map((index) => binaryString[index])
      .join('');
    const userOrder = [
      'name',
      'ffNumber',
      'flight',
      'pnr',
      'originDestination',
      'notes',
    ];
    return userOrder
      .map((key, index) => {
        return reorderedBinaryString[index] === '1' ? fields[index] : null;
      })
      .filter((label) => label !== null) as string[];
  }

  navigateToPartners(): void {
    this.router.navigate(['/app/partners']);
  }
}
