import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { AirportService } from '../../../services/airport.service';
import { AirportDTO } from '../../../models/dtos/airportDTO';
import { LoadingService } from '../../../services/helpers/loading.service';

@Component({
  selector: 'aims-edit-airport',
  templateUrl: './edit-airport.component.html',
  styleUrls: ['./edit-airport.component.scss'],
  standalone: false,
})
export class EditAirportComponent implements OnInit {
  airportForm: FormGroup;
  initialAirportData: AirportDTO;

  constructor(
    private fb: FormBuilder,
    private airportService: AirportService,
    private loadingService: LoadingService,
    public dialogRef: MatDialogRef<EditAirportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AirportDTO,
  ) {
    this.airportForm = this.fb.group({
      airportName: [data.airportName, Validators.required],
      airportCode: [
        data.airportCode,
        [
          Validators.required,
          Validators.maxLength(3),
          Validators.pattern(/^[A-Z]{3}$/),
        ],
      ],
    });
    this.initialAirportData = { ...data };
    this.airportForm.get('airportCode')?.valueChanges.subscribe((value) => {
      this.airportForm
        .get('airportCode')
        ?.setValue(value.toUpperCase(), { emitEvent: false });
    });
  }

  ngOnInit(): void {}

  onSubmit(): void {
    if (this.airportForm.valid) {
      const updatedAirport: AirportDTO = {
        airportId: this.initialAirportData.airportId,
        airportName: this.airportForm.value.airportName,
        airportCode: this.airportForm.value.airportCode,
      };
      this.loadingService.show();

      this.airportService.updateAirport(updatedAirport).subscribe(
        (response) => {
          this.loadingService.hide();
          Swal.fire({
            title: 'Success!',
            text: 'Airport details updated successfully.',
            icon: 'success',
            confirmButtonColor: '#2893cc',
            confirmButtonText: 'OK',
          }).then(() => {
            this.dialogRef.close(response);
            window.location.reload();
          });
        },
        (error) => {
          this.loadingService.hide();
          this.airportService.showError(
            'Failed to update airport details.',
            error.message,
          );
        },
      );
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
