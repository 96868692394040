<div class="dialog-container">
  <aims-loading></aims-loading>
  <button mat-icon-button class="close-button" (click)="onCancel()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-vertical-stepper [linear]="true" #stepper>
    <!-- Step 1: Group Association Information -->
    <mat-step [stepControl]="groupAssociationForm.controls['groupName']">
      <ng-template matStepLabel>Group Association Information</ng-template>
      <form [formGroup]="groupAssociationForm">
        <div class="input-group">
          <mat-form-field appearance="fill">
            <mat-label>Group Name</mat-label>
            <input
              matInput
              formControlName="groupName"
              type="text"
              placeholder="Example: Star Alliance"
            />
          </mat-form-field>
        </div>
        <div class="input-group">
          <div *ngIf="currentImageHandle">
            <mat-form-field appearance="fill">
              <mat-label>Group Image Handle</mat-label>
              <input
                matInput
                formControlName="groupImageHandle"
                type="text"
                [value]="currentImageHandle"
                readonly
              />
              <button mat-icon-button matSuffix (click)="onDeleteImageHandle()">
                <mat-icon>clear</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div *ngIf="!currentImageHandle">
            <input type="file" (change)="onFileSelected($event)" />
          </div>
        </div>
        <div class="dialog-actions">
          <button mat-raised-button color="primary" matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>

    <!-- Step 2: Review and Submit -->
    <mat-step>
      <ng-template matStepLabel>Review and Submit</ng-template>
      <p>Please review all details before submitting.</p>
      <ul>
        <li>
          <strong>Group Name:</strong>
          {{ groupAssociationForm.value?.groupName }}
        </li>
        <li>
          <strong>Group Image Handle:</strong>
          {{ groupAssociationForm.value?.groupImageHandle || "Default" }}
        </li>
      </ul>
      <div class="dialog-actions">
        <button mat-raised-button color="warn" matStepperPrevious>Back</button>
        <button mat-raised-button color="primary" (click)="onSubmit()">
          Submit
        </button>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</div>
