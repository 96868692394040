<div *ngIf="isFormInitialized" class="dialog-container">
  <!-- Loading Component -->
  <aims-loading></aims-loading>

  <button mat-icon-button class="close-button" (click)="onCancel()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-vertical-stepper [linear]="true" #stepper>
    <!-- Step 1: Airport Information -->
    <mat-step [stepControl]="workstationForm.controls['airportId']">
      <ng-template matStepLabel>Airport Information</ng-template>
      <form [formGroup]="workstationForm">
        <div class="input-group">
          <mat-form-field appearance="fill">
            <mat-label>Airport Name</mat-label>
            <mat-select formControlName="airportId">
              <ng-container *ngIf="airports; else noData">
                <mat-option
                  *ngFor="let airport of airports"
                  [value]="airport.airportId"
                >
                  {{ airport.airportName }}
                </mat-option>
              </ng-container>
              <ng-template #noData>No airports to display</ng-template>
            </mat-select>
          </mat-form-field>
        </div>
        <button mat-raised-button color="primary" matStepperNext>Next</button>
      </form>
    </mat-step>

    <!-- Step 2: Lounge Selection -->
    <mat-step [stepControl]="workstationForm.controls['loungeId']">
      <ng-template matStepLabel>Lounge Selection</ng-template>
      <form [formGroup]="workstationForm">
        <div class="input-group">
          <mat-form-field appearance="fill">
            <mat-label>Lounge Name</mat-label>
            <mat-select formControlName="loungeId">
              <ng-container *ngIf="filteredLounges.length > 0; else noData">
                <mat-option
                  *ngFor="let lounge of filteredLounges"
                  [value]="lounge.loungeId"
                >
                  {{ lounge.loungeName }}
                </mat-option>
              </ng-container>
              <ng-template #noData>No lounges to display</ng-template>
            </mat-select>
          </mat-form-field>
        </div>
        <button mat-raised-button color="warn" matStepperPrevious>Back</button>
        <button mat-raised-button color="primary" matStepperNext>Next</button>
      </form>
    </mat-step>

    <!-- Step 3: Workstation Details -->
    <mat-step [stepControl]="workstationForm.controls['workstationName']">
      <ng-template matStepLabel>Workstation Details</ng-template>
      <form [formGroup]="workstationForm">
        <div class="input-group">
          <mat-form-field appearance="fill">
            <mat-label>Workstation Name</mat-label>
            <input matInput formControlName="workstationName" type="text" />
          </mat-form-field>
        </div>
        <div class="input-group">
          <mat-form-field appearance="fill">
            <mat-label>Default Airline</mat-label>
            <mat-select formControlName="defaultAirlineId">
              <mat-option
                *ngFor="let airline of airlines"
                [value]="airline.airlineId"
              >
                {{ airline.airlineName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <button mat-raised-button color="warn" matStepperPrevious>Back</button>
        <button mat-raised-button color="primary" matStepperNext>Next</button>
      </form>
    </mat-step>

    <!-- Step 4: Review and Submit -->
    <mat-step>
      <ng-template matStepLabel>Review and Submit</ng-template>
      <div class="review-section">
        <p>
          <strong>Airport:</strong>
          {{ getAirportName(workstationForm.get("airportId")?.value) }}
        </p>
        <p>
          <strong>Lounge:</strong>
          {{ getLoungeName(workstationForm.get("loungeId")?.value) }}
        </p>
        <p>
          <strong>Workstation Name:</strong>
          {{ workstationForm.get("workstationName")?.value }}
        </p>
        <p>
          <strong>Default Airline:</strong>
          {{ getAirlineName(workstationForm.get("defaultAirlineId")?.value) }}
        </p>
      </div>
      <div class="dialog-actions">
        <button mat-raised-button color="warn" matStepperPrevious>Back</button>
        <button
          mat-raised-button
          color="primary"
          [disabled]="!workstationForm.valid || isSubmitting"
          (click)="onSubmit()"
        >
          Submit
        </button>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</div>
