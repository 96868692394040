<!-- header.component.html -->
<nav
  class="navbar navbar-expand-lg navbar-dark"
  style="background-color: #54b2d5"
>
  <div class="container-fluid">
    <button mat-icon-button (click)="onToggleSidebar()" class="nav-link">
      <mat-icon>menu</mat-icon>
    </button>
    <!-- Logo and company name -->
    <a class="navbar-brand" href="/">
      <img
        src="../../../assets/images/ieglogo-icon.png"
        alt="Logo"
        width="30"
        height="30"
        class="d-inline-block align-top logo-image"
      />
      <span class="brand-name"><b>IEG</b>AIMS ADMIN</span>
    </a>

    <!-- Toggler for mobile view -->
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav ms-auto">
        <!-- Notifications Icon -->
        <li class="nav-item">
          <a class="nav-link" href="#">
            <mat-icon>notifications</mat-icon>
          </a>
        </li>
        <!-- User Icon with dropdown -->
        <li class="nav-item" [matMenuTriggerFor]="userMenu">
          <a class="nav-link" href="javascript:void(0)">
            <mat-icon>person</mat-icon>
          </a>
        </li>
        <!-- Settings Icon -->
        <li class="nav-item">
          <a class="nav-link" href="#">
            <mat-icon>settings</mat-icon>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<!-- Dropdown menu for the user -->
<mat-menu #userMenu="matMenu">
  <button mat-menu-item disabled>
    {{ username }}
  </button>
  <button mat-menu-item (click)="onLogout()">
    <mat-icon>logout</mat-icon>
    Sign out
  </button>
</mat-menu>
