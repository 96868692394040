<h2 mat-dialog-title>Select Airport(s)</h2>
<mat-dialog-content>
  <!-- Search Input -->
  <mat-form-field appearance="fill">
    <mat-label>Search Airport</mat-label>
    <input
      type="text"
      matInput
      [formControl]="airportSearchControl"
      [matAutocomplete]="auto"
      placeholder="Type to search airports"
      autocomplete="off"
      (focus)="showAllAirports()"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayAirportName"
      (optionSelected)="onSelectAirport($event)"
    >
      <ng-container *ngIf="filteredAirports.length > 0; else noData">
        <mat-option *ngFor="let airport of filteredAirports" [value]="airport">
          {{ airport.airportName }}
        </mat-option>
      </ng-container>

      <ng-template #noData>
        <mat-option disabled>No airports available</mat-option>
      </ng-template>
    </mat-autocomplete>
  </mat-form-field>

  <!-- Selected Airports List -->
  <div class="selected-airports" *ngIf="selectedAirports.length > 0">
    <h3>Selected Airports</h3>
    <div class="airport-item" *ngFor="let airport of selectedAirports">
      <span>{{ airport.airportName }}</span>
      <button
        mat-icon-button
        (click)="removeAirport(airport)"
        aria-label="Remove Airport"
      >
        <mat-icon>cancel</mat-icon>
      </button>
    </div>
  </div>

  <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-raised-button color="primary" (click)="onSelect()">Select</button>
</mat-dialog-actions>
