<div class="airports-container">
  <div class="header-container">
    <div class="header-content">
      <h2 class="header-title">Airports</h2>
      <p class="description">
        List of airports with key information and actions.
      </p>
    </div>
  </div>

  <aims-layout-toolbar>
    <button mat-icon-button (click)="addNew()">
      <mat-icon>add</mat-icon>
    </button>
    <button mat-icon-button (click)="refresh()">
      <mat-icon>refresh</mat-icon>
    </button>
  </aims-layout-toolbar>

  <aims-query-layout>
    <aims-search-bar
      left
      componentName="airports"
      matTooltip="Enter at least 3 characters for search"
      matTooltipPosition="above"
      (termsChanged$)="onSearchResults($event)"
      (reset$)="onReset()"
      (clearTerms$)="fetchAllOnClear()"
    ></aims-search-bar>
  </aims-query-layout>

  <div class="table-responsive">
    <table mat-table class="mat-elevation-z8" [dataSource]="dataSource">
      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef>Code</th>
        <td mat-cell *matCellDef="let airport">{{ airport.airportCode }}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let airport">{{ airport.airportName }}</td>
      </ng-container>

      <ng-container matColumnDef="airportId">
        <th mat-header-cell *matHeaderCellDef>Airport ID</th>
        <td mat-cell *matCellDef="let airport">{{ airport.airportId }}</td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let airport">
          <button
            mat-icon-button
            (click)="openEditDialog(airport); $event.stopPropagation()"
            aria-label="Edit airport"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="deleteAirport(airport); $event.stopPropagation()"
            aria-label="Delete airport"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="['code', 'name', 'airportId', 'action']"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['code', 'name', 'airportId', 'action']"
        (click)="redirectToAirportDetails(row.airportId)"
      ></tr>
    </table>
  </div>

  <div class="paginator-container">
    <aims-query-page
      [paginationModel]="paginationModel"
      (pageChange)="onPageChange($event)"
    ></aims-query-page>
  </div>
</div>
