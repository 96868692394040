import { Component, Inject, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { GroupAssociationDTO } from '../../../models/dtos/groupAssociationDTO';
import { AirlineService } from '../../../services/airline.service';
import { FilesService } from '../../../services/files.service';
import { GroupAssociationService } from '../../../services/group-association.service';
import { ConfigService } from '../../../services/helpers/config.service';
import { LoadingService } from '../../../services/helpers/loading.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AirlineDTO } from '../../../models/dtos/airlineDTO';
import Swal from 'sweetalert2';

interface FieldConfig {
  key: string;
  label: string;
  value: number;
}

interface DialogData {
  airline: AirlineDTO | null; // If null, we are in create mode.
}

@Component({
  selector: 'aims-airline-partner-dialog',
  templateUrl: './airline-partner-dialog.component.html',
  styleUrls: ['./airline-partner-dialog.component.scss'],
  standalone: false,
})
export class AirlinePartnerDialogComponent implements OnInit {
  isEditMode: boolean = false;
  partnerForm: FormGroup;
  groupAssociations: GroupAssociationDTO[] = [];
  selectedGroupAssociations: GroupAssociationDTO[] = [];
  defaultImageUrl: string = 'TestAirCanada.png';
  currentImageHandle: string | null = null;
  maxImageSize: number;

  passengerFields: FieldConfig[] = [
    { key: 'name', label: 'Name', value: 1 },
    { key: 'ffNumber', label: 'FF Number', value: 0 },
    { key: 'flight', label: 'Flight', value: 1 },
    { key: 'pnr', label: 'PNR', value: 0 },
    { key: 'originDestination', label: 'Origin & Destination', value: 1 },
    { key: 'notes', label: 'Notes', value: 0 },
  ];

  guestFields: FieldConfig[] = [
    { key: 'name', label: 'Name', value: 1 },
    { key: 'ffNumber', label: 'FF Number', value: 0 },
    { key: 'flight', label: 'Flight', value: 1 },
    { key: 'pnr', label: 'PNR', value: 0 },
    { key: 'originDestination', label: 'Origin & Destination', value: 1 },
    { key: 'notes', label: 'Notes', value: 0 },
  ];

  passengerMandatoryFields: number = 0;
  passengerOptionalFields: number = 0;
  guestMandatoryFields: number = 0;
  guestOptionalFields: number = 0;

  constructor(
    private fb: FormBuilder,
    private airlineService: AirlineService,
    private filesService: FilesService,
    private groupAssociationService: GroupAssociationService,
    private configService: ConfigService,
    private loadingService: LoadingService,
    public dialogRef: MatDialogRef<AirlinePartnerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    this.partnerForm = this.fb.group({
      airlineName: ['', Validators.required],
      airlineCode: ['', [Validators.required, Validators.maxLength(3)]],
      airlineImageHandle: [''],
      passengerMandatoryFields: [0, Validators.required],
      passengerOptionalFields: [0, Validators.required],
      guestMandatoryFields: [0, Validators.required],
      guestOptionalFields: [0, Validators.required],
      groupAssociations: this.fb.array([]),
    });

    this.addFormControlsForFields();

    const maxImageSizeConfig = this.configService.getConfig(
      'maximumAirlineOrCardImageFileUploadSize',
    );
    this.maxImageSize = Math.ceil(maxImageSizeConfig / 1024);
  }

  ngOnInit(): void {
    this.isEditMode = !!(this.data && this.data.airline);
    this.fetchGroupAssociations();
    if (this.isEditMode && this.data.airline) {
      this.loadExistingData();
    }
  }

  addFormControlsForFields(): void {
    this.passengerFields.forEach((field) => {
      this.partnerForm.addControl(
        'passenger-' + field.key,
        new FormControl(field.value),
      );
    });
    this.guestFields.forEach((field) => {
      this.partnerForm.addControl(
        'guest-' + field.key,
        new FormControl(field.value),
      );
    });
  }

  get groupAssociationsArray(): FormArray {
    return this.partnerForm.get('groupAssociations') as FormArray;
  }

  getGroupControl(index: number): FormControl {
    const control = this.groupAssociationsArray.at(index) as FormControl;
    control.valueChanges.subscribe((isChecked: boolean) => {
      const group = this.groupAssociations[index];
      if (isChecked) {
        if (
          !this.selectedGroupAssociations.some(
            (g) => g.groupAssociationId === group.groupAssociationId,
          )
        ) {
          this.selectedGroupAssociations.push(group);
        }
      } else {
        this.selectedGroupAssociations = this.selectedGroupAssociations.filter(
          (g) => g.groupAssociationId !== group.groupAssociationId,
        );
      }
    });
    return control;
  }

  getSelectedGroupNames(): string {
    if (
      !this.selectedGroupAssociations ||
      this.selectedGroupAssociations.length === 0
    ) {
      return 'N/A';
    }

    return this.selectedGroupAssociations
      .map((group) => group.groupName)
      .join(', ');
  }

  fetchGroupAssociations(): void {
    this.groupAssociationService.getAllGroupAssociations().subscribe(
      (response) => {
        if (
          response &&
          response.success &&
          response.data &&
          response.data.pageData
        ) {
          this.groupAssociations = response.data.pageData;
          const groupArray = this.groupAssociationsArray;
          while (groupArray.length) {
            groupArray.removeAt(0);
          }
          this.groupAssociations.forEach(() =>
            groupArray.push(new FormControl(false)),
          );
          if (
            this.isEditMode &&
            this.data.airline &&
            this.data.airline.groupAssociations
          ) {
            this.setGroupAssociations(this.data.airline.groupAssociations);
          }
        } else {
          this.groupAssociations = [];
        }
      },
      (error) => {
        this.airlineService.showError(
          'Error fetching group associations:',
          error.message,
        );
        this.groupAssociations = [];
      },
    );
  }

  getMandatoryFields(type: 'passenger' | 'guest'): FieldConfig[] {
    const fields =
      type === 'passenger' ? this.passengerFields : this.guestFields;
    return fields.filter(
      (field) => this.partnerForm.get(`${type}-${field.key}`)?.value === 1,
    );
  }

  setGroupAssociations(associations: { groupAssociationId: number }[]): void {
    const groupArray = this.groupAssociationsArray;
    this.selectedGroupAssociations = [];
    this.groupAssociations.forEach((group, index) => {
      const isSelected = associations.some(
        (a) => a.groupAssociationId === group.groupAssociationId,
      );
      groupArray.at(index).setValue(isSelected);
      if (isSelected) {
        this.selectedGroupAssociations.push(group);
      }
    });
  }

  initializeFieldsFromInteger(
    value: number,
    type: 'passenger' | 'guest',
    fieldValue: number,
  ): void {
    const binaryString = value.toString(2).padStart(6, '0');
    const fields =
      type === 'passenger' ? this.passengerFields : this.guestFields;
    const binaryOrder = [5, 4, 3, 1, 0, 2]; // This corresponds to: Name -> FF Number -> Flight -> PNR  -> Origin & Destination  -> Notes
    const reorderedBinary = binaryOrder
      .map((index) => binaryString[index])
      .join('');
    reorderedBinary.split('').forEach((bit, index) => {
      if (bit === '1') {
        this.partnerForm
          .get(`${type}-${fields[index].key}`)
          ?.setValue(fieldValue);
      }
    });
  }

  loadExistingData(): void {
    if (this.data && this.data.airline) {
      const airline = this.data.airline;
      this.currentImageHandle = airline.airlineImageHandle;
      this.partnerForm.patchValue({
        airlineName: airline.airlineName,
        airlineCode: airline.airlineCode,
        airlineImageHandle: airline.airlineImageHandle,
        passengerMandatoryFields: airline.passengerMandatoryFields,
        passengerOptionalFields: airline.passengerOptionalFields,
        guestMandatoryFields: airline.guestMandatoryFields,
        guestOptionalFields: airline.guestOptionalFields,
      });
      if (airline.groupAssociations) {
        this.setGroupAssociations(airline.groupAssociations);
      }
      this.initializeFieldsFromInteger(
        airline.passengerMandatoryFields,
        'passenger',
        1,
      );
      this.initializeFieldsFromInteger(
        airline.passengerOptionalFields,
        'passenger',
        2,
      );
      this.initializeFieldsFromInteger(
        airline.guestMandatoryFields,
        'guest',
        1,
      );
      this.initializeFieldsFromInteger(airline.guestOptionalFields, 'guest', 2);
    }
  }

  computeFieldValues(): void {
    const binaryOrder = [4, 3, 5, 2, 1, 0];
    const getBinaryString = (
      fields: FieldConfig[],
      type: 'passenger' | 'guest',
      checkValue: number,
    ): string =>
      fields
        .map((field) =>
          this.partnerForm.get(`${type}-${field.key}`)?.value === checkValue
            ? '1'
            : '0',
        )
        .join('');
    const reorderBinary = (binary: string): string =>
      binaryOrder.map((index) => binary[index]).join('');
    const binaryToDecimal = (binary: string): number => parseInt(binary, 2);

    const passengerMandatoryBinary = getBinaryString(
      this.passengerFields,
      'passenger',
      1,
    );
    const passengerOptionalBinary = getBinaryString(
      this.passengerFields,
      'passenger',
      2,
    );
    const guestMandatoryBinary = getBinaryString(this.guestFields, 'guest', 1);
    const guestOptionalBinary = getBinaryString(this.guestFields, 'guest', 2);

    this.passengerMandatoryFields = binaryToDecimal(
      reorderBinary(passengerMandatoryBinary),
    );
    this.passengerOptionalFields = binaryToDecimal(
      reorderBinary(passengerOptionalBinary),
    );
    this.guestMandatoryFields = binaryToDecimal(
      reorderBinary(guestMandatoryBinary),
    );
    this.guestOptionalFields = binaryToDecimal(
      reorderBinary(guestOptionalBinary),
    );

    this.partnerForm.patchValue({
      passengerMandatoryFields: this.passengerMandatoryFields,
      passengerOptionalFields: this.passengerOptionalFields,
      guestMandatoryFields: this.guestMandatoryFields,
      guestOptionalFields: this.guestOptionalFields,
    });
  }

  async onFileSelected(event: Event): Promise<void> {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      const formData = new FormData();
      formData.append('file', file);

      this.loadingService.show();

      if (file.size > this.maxImageSize * 1024) {
        const result = await Swal.fire({
          title: 'Image Size Exceeded',
          text: `The selected image size exceeds the maximum limit of ${this.maxImageSize} KB. Do you want to resize it?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, resize it!',
          confirmButtonColor: '#2893cc',
          cancelButtonText: 'No, I will upload a smaller image.',
        });
        if (result.isConfirmed) {
          const resizedResponse =
            await this.filesService.compressAndUploadAirlineOrCardImage(
              formData,
            );
          const resizedFileName = resizedResponse.data['fileName'];
          this.partnerForm.patchValue({ airlineImageHandle: resizedFileName });
          this.currentImageHandle = resizedFileName;
        } else {
          this.loadingService.hide();
          return;
        }
      } else {
        try {
          const response =
            await this.filesService.uploadAirlineOrCardImageFile(formData);
          const fileName = response.data['fileName'];
          this.partnerForm.patchValue({ airlineImageHandle: fileName });
          this.currentImageHandle = fileName;
        } catch (error: any) {
          Swal.fire({
            title: 'Error!',
            text: `File upload failed: ${error.message}`,
            icon: 'error',
            confirmButtonText: 'OK',
            confirmButtonColor: '#2893cc',
          });
        }
      }
      this.loadingService.hide();
    }
  }

  onDeleteImageHandle(): void {
    this.partnerForm.patchValue({ airlineImageHandle: '' });
    this.currentImageHandle = null;
  }

  private getSelectedGroups(): { groupAssociationId: number }[] {
    return this.groupAssociations
      .filter(
        (_, i) => (this.groupAssociationsArray.at(i) as FormControl).value,
      )
      .map((g) => ({ groupAssociationId: g.groupAssociationId }));
  }

  private prepareFormData(): AirlineDTO {
    const formValues = this.partnerForm.value;
    return {
      airlineId:
        this.isEditMode && this.data && this.data.airline
          ? this.data.airline.airlineId
          : 0,
      airlineName: formValues.airlineName,
      airlineCode: formValues.airlineCode,
      airlineImageHandle: formValues.airlineImageHandle || this.defaultImageUrl,
      passengerMandatoryFields: formValues.passengerMandatoryFields,
      passengerOptionalFields: formValues.passengerOptionalFields,
      guestMandatoryFields: formValues.guestMandatoryFields,
      guestOptionalFields: formValues.guestOptionalFields,
      groupAssociations: this.getSelectedGroups(),
    };
  }

  async onSubmit(): Promise<void> {
    if (this.partnerForm.valid) {
      this.computeFieldValues();
      const formData = this.prepareFormData();
      if (this.isEditMode) {
        this.updateAirline(formData);
      } else {
        this.createAirline(formData);
      }
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Please correct the errors in the form before submitting.',
        icon: 'error',
        confirmButtonText: 'OK',
        confirmButtonColor: '#2893cc',
      });
    }
  }

  createAirline(newAirline: AirlineDTO): void {
    this.loadingService.show();
    this.airlineService.addAirline(newAirline).subscribe(
      (response) => {
        this.loadingService.hide();
        Swal.fire({
          title: 'Success!',
          text: 'Airline partner added successfully.',
          icon: 'success',
          confirmButtonText: 'OK',
          confirmButtonColor: '#2893cc',
        }).then(() => {
          this.dialogRef.close(response);
          window.location.reload();
        });
      },
      (error) => {
        this.loadingService.hide();
        Swal.fire({
          title: 'Error!',
          text: `Failed to add airline partner: ${error.message}`,
          icon: 'error',
          confirmButtonText: 'OK',
          confirmButtonColor: '#2893cc',
        });
      },
    );
  }

  updateAirline(updatedAirline: AirlineDTO): void {
    this.loadingService.show();
    this.airlineService.updateAirline(updatedAirline).subscribe(
      (response) => {
        this.loadingService.hide();
        Swal.fire({
          title: 'Success!',
          text: 'Airline partner updated successfully.',
          icon: 'success',
          confirmButtonText: 'OK',
          confirmButtonColor: '#2893cc',
        }).then(() => {
          this.dialogRef.close(response);
          window.location.reload();
        });
      },
      (error) => {
        this.loadingService.hide();
        Swal.fire({
          title: 'Error!',
          text: `Failed to update airline partner: ${error.message}`,
          icon: 'error',
          confirmButtonText: 'OK',
          confirmButtonColor: '#2893cc',
        });
      },
    );
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
