<div class="access-documents-container">
  <ng-container *ngIf="showFullContent">
    <div class="header-container">
      <div class="header-content">
        <h2 class="header-title">Access Documents</h2>
        <p class="description">
          List of access documents with key information and actions.
        </p>
      </div>
    </div>

    <aims-layout-toolbar>
      <button mat-icon-button (click)="addNew()">
        <mat-icon>add</mat-icon>
      </button>
      <button mat-icon-button (click)="refresh()">
        <mat-icon>refresh</mat-icon>
      </button>
    </aims-layout-toolbar>

    <aims-query-layout>
      <aims-search-bar
        left
        componentName="access-documents"
        matTooltip="Enter at least 3 characters for search"
        matTooltipPosition="above"
        (termsChanged$)="onSearchResults($event)"
        (reset$)="onReset()"
        (clearTerms$)="fetchAllOnClear()"
      ></aims-search-bar>
    </aims-query-layout>
  </ng-container>

  <div class="table-responsive">
    <table mat-table class="mat-elevation-z8" [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let document">
          <div class="name-and-image-container">
            <img
              [src]="getCardImageUri(document.cardId) || defaultImageUrl"
              alt="Document Image"
              class="document-image"
            />
            <span class="document-name">{{ document.cardName }}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="cardId">
        <th mat-header-cell *matHeaderCellDef>Card ID</th>
        <td mat-cell *matCellDef="let document">
          {{ document.cardId }}
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let document">
          <button
            mat-icon-button
            (click)="editCard(document); $event.stopPropagation()"
            aria-label="Edit document"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="deleteCard(document); $event.stopPropagation()"
            aria-label="Delete document"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="['name', 'cardId', 'action']"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['name', 'cardId', 'action']"
        (click)="redirectToCardDetails(row.cardId)"
      ></tr>
    </table>
  </div>

  <div class="paginator-container">
    <aims-query-page
      [paginationModel]="paginationModel"
      (pageChange)="onPageChange($event)"
    ></aims-query-page>
  </div>
</div>
