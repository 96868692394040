import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AirportDTO } from '../models/dtos/airportDTO';
import { LoungeDTO } from '../models/dtos/loungeDTO';
import { ResponseDTO } from '../models/dtos/responseDTO';
import { BaseService } from './helpers/base.service';
import { ConfigService } from './helpers/config.service';
import { LoadingService } from './helpers/loading.service';

@Injectable({
  providedIn: 'root',
})
export class AirportService extends BaseService {
  private apiUrl: string;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private loadingService: LoadingService,
  ) {
    super();
    this.apiUrl = this.configService.getApiUrl() + '/Airports';
  }

  getAirportByCode(code: string): Observable<AirportDTO> {
    return this.http
      .get<AirportDTO>(`${this.apiUrl}/GetByCode`, { params: { code } })
      .pipe(catchError(this.handleError.bind(this)));
  }

  getAirportByName(name: string): Observable<AirportDTO> {
    return this.http
      .get<AirportDTO>(`${this.apiUrl}/GetByName`, { params: { name } })
      .pipe(catchError(this.handleError.bind(this)));
  }

  getAirports(
    index: number = 1,
    size: number = 10,
  ): Observable<ResponseDTO<AirportDTO>> {
    const params = new HttpParams()
      .set('Index', index.toString())
      .set('Size', size.toString());
    this.loadingService.setLoading(true);

    return this.http
      .get<ResponseDTO<AirportDTO>>(`${this.apiUrl}/GetAll`, { params })
      .pipe(
        map((response) => response),
        catchError(this.handleError.bind(this)),
        tap(() => this.loadingService.setLoading(false)),
      );
  }

  searchAirportsByKeyword(keyword: string): Observable<AirportDTO[]> {
    return this.http
      .get<AirportDTO[]>(`${this.apiUrl}/SearchByKeyword`, {
        params: { keyword },
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  getAirportById(airportId: number): Observable<AirportDTO> {
    return this.http
      .get<AirportDTO>(`${this.apiUrl}/GetById/${airportId}`)
      .pipe(catchError(this.handleError.bind(this)));
  }

  getAirportLounges(airportId: number): Observable<LoungeDTO[]> {
    return this.http
      .get<LoungeDTO[]>(`${this.apiUrl}/GetLounges/${airportId}`)
      .pipe(catchError(this.handleError.bind(this)));
  }

  addAirport(airport: AirportDTO): Observable<AirportDTO> {
    return this.http
      .post<AirportDTO>(`${this.apiUrl}/Add`, airport)
      .pipe(catchError(this.handleError.bind(this)));
  }

  updateAirport(airport: AirportDTO): Observable<AirportDTO> {
    return this.http
      .patch<AirportDTO>(`${this.apiUrl}/Update`, airport)
      .pipe(catchError(this.handleError.bind(this)));
  }

  removeAirport(airportId: number): Observable<any> {
    return this.http
      .delete(`${this.apiUrl}/Delete/${airportId}`)
      .pipe(catchError(this.handleError.bind(this)));
  }
  //
  // getAirportRules(airportId: number): Observable<AirportRuleDTO[]> {
  //   return this.http.get<AirportRuleDTO[]>(`${this.apiUrl}/GetRules/${airportId}`)
  //     .pipe(
  //     catchError(this.handleError)
  //   );
  // }
  //
  // addAirportRules(rules: AirportRuleDTO[]): Observable<AirportRuleDTO[]> {
  //   return this.http.post<AirportRuleDTO[]>(`${this.apiUrl}/AddRules`, rules)
  //     .pipe(
  //     catchError(this.handleError)
  //   );
  // }
  //
  // updateAirportRules(rules: AirportRuleDTO[]): Observable<AirportRuleDTO[]> {
  //   return this.http.patch<AirportRuleDTO[]>(`${this.apiUrl}/UpdateRules`, rules)
  //     .pipe(
  //     catchError(this.handleError)
  //   );
  // }
  //
  // removeAirportRule(ruleId: number): Observable<any> {
  //   return this.http.delete(`${this.apiUrl}/DeleteRule/${ruleId}`)
  //     .pipe(
  //     catchError(this.handleError)
  //   );
  // }
  //
  // removeAirportRules(airportId: number): Observable<any> {
  //   return this.http.delete(`${this.apiUrl}/DeleteRules/${airportId}`)
  //     .pipe(
  //     catchError(this.handleError)
  //   );
  // }
}
