import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { WorkstationService } from '../../../services/workstation.service';
import { EditWorkstationComponent } from '../edit-workstation/edit-workstation.component';
import { WorkstationDTO } from '../../../models/dtos/workstationDTO';
import { LoungeService } from '../../../services/lounge.service';
import { AirportService } from '../../../services/airport.service';
import { LoungeHourDTO } from '../../../models/dtos/loungeHourDTO';
import { AirlineDTO } from '../../../models/dtos/airlineDTO';
import { AirlineService } from '../../../services/airline.service';

@Component({
  selector: 'aims-workstation-details',
  templateUrl: './workstation-details.component.html',
  styleUrls: ['./workstation-details.component.scss'],
  standalone: false,
})
export class WorkstationDetailsComponent implements OnInit {
  workstationData?: WorkstationDTO;
  loungeHours: LoungeHourDTO[] = [];
  airportCode: string = '';
  airportName: string = '';
  airportId: number = 0;
  loungeName: string = '';
  loungeId: number = 0;
  activeTab: 'overview' = 'overview';
  daysOfWeek: string[] = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  defaultAirline: AirlineDTO[] = [];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private workstationService: WorkstationService,
    private loungeService: LoungeService,
    private airportService: AirportService,
    private airlineService: AirlineService,
  ) {}

  ngOnInit(): void {
    this.getWorkstationDetails();
    this.fetchAirlines();
  }

  getWorkstationDetails(): void {
    const workstationId = +this.route.snapshot.params['id'];
    this.workstationService.getWorkstationById(workstationId).subscribe(
      (response: any) => {
        if (response.success) {
          this.workstationData = response.data;
          this.fetchAirportDetails(response.data.airportId);
          this.fetchLoungeDetails(response.data.loungeId);
        } else {
          this.workstationService.showError(
            'Could not load workstation details.',
            response.message,
          );
        }
      },
      (error) => {
        this.workstationService.showError(
          'Could not load workstation details.',
          error.message,
        );
      },
    );
  }

  fetchAirportDetails(airportId: number): void {
    this.airportService.getAirportById(airportId).subscribe(
      (response: any) => {
        if (response.success) {
          const airportDetails = response.data;
          this.airportCode = airportDetails.airportCode;
          this.airportName = airportDetails.airportName;
          this.airportId = airportDetails.airportId;
        } else {
          this.airportService.showError(
            'Airport details not found',
            response.message,
          );
        }
      },
      (error) => {
        this.airportService.showError(
          'Error fetching airport details',
          error.message,
        );
      },
    );
  }

  fetchLoungeDetails(loungeId: number): void {
    this.loungeService.getLoungeById(loungeId).subscribe(
      (response: any) => {
        if (response.success) {
          const loungeDetails = response.data;
          this.loungeName = loungeDetails.loungeName;
          this.loungeId = loungeDetails.loungeId;
          this.fetchLoungeHours(loungeDetails.loungeId);
        } else {
          this.loungeService.showError(
            'Failed to fetch lounge details:',
            response.message,
          );
        }
      },
      (error) => {
        this.loungeService.showError(
          'Error fetching lounge details',
          error.message,
        );
      },
    );
  }

  fetchLoungeHours(loungeId: number): void {
    this.loungeService.getLoungeHours(loungeId).subscribe(
      (response: any) => {
        if (response.success) {
          // Sort loungeHours by dayOfWeek
          this.loungeHours = response.data
            .map((hour: LoungeHourDTO) => ({
              ...hour,
              startTime: this.formatTime(hour.startTime),
              endTime: this.formatTime(hour.endTime),
            }))
            .sort(
              (a: LoungeHourDTO, b: LoungeHourDTO) => a.dayOfWeek - b.dayOfWeek,
            );
        } else {
          this.loungeService.showError(
            'Failed to fetch lounge hours:',
            response.message,
          );
        }
      },
      (error) => {
        this.loungeService.showError(
          'Error fetching lounge hours:',
          error.message,
        );
      },
    );
  }

  fetchAirlines(): void {
    this.airlineService.getAllAirlines().subscribe((response) => {
      if (response && response.success && response.data) {
        this.defaultAirline = response.data.pageData;
      } else {
        this.defaultAirline = [];
      }
    });
  }

  formatTime(time: string | undefined): string {
    if (!time) {
      return 'Closed';
    }
    const [hours, minutes] = time.split(':');
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
  }

  editWorkstation(): void {
    const dialogRef = this.dialog.open(EditWorkstationComponent, {
      width: '600px',
      data: this.workstationData,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.workstationData = result;
      }
    });
  }

  deleteWorkstation(): void {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2893cc',
      cancelButtonColor: '#939597',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        if (this.workstationData) {
          this.workstationService
            .removeWorkstation(this.workstationData.workstationId)
            .subscribe(
              () => {
                Swal.fire({
                  title: 'Deleted!',
                  text: 'Your workstation has been deleted.',
                  icon: 'success',
                  confirmButtonColor: '#2893cc',
                  confirmButtonText: 'OK',
                }).then(() => {
                  this.router.navigate(['/app/workstations']);
                });
              },
              (error) => {
                this.workstationService.showError(
                  'Error deleting the workstation. Please try again.',
                  error.message,
                );
              },
            );
        }
      }
    });
  }

  getAirlineName(airlineId: number | undefined): string {
    if (airlineId === undefined || airlineId === null) {
      return 'N/A';
    }
    const defaultAirline = this.defaultAirline.find(
      (a) => a.airlineId === airlineId,
    );
    return defaultAirline ? defaultAirline.airlineName : 'N/A';
  }

  refresh(): void {
    window.location.reload();
  }

  switchToOverview(): void {
    this.activeTab = 'overview';
  }

  navigateToWorkstation(): void {
    this.router.navigate(['/app/workstations']);
  }
}
