import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AirportDTO } from '../../../models/dtos/airportDTO';
import { AirportService } from '../../../services/airport.service';
import { MatDialogRef } from '@angular/material/dialog';
import { of, startWith } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Component({
  selector: 'aims-select-airport-dialog',
  standalone: false,

  templateUrl: './select-airport-dialog.component.html',
  styleUrl: './select-airport-dialog.component.scss',
})
export class SelectAirportDialogComponent implements OnInit {
  airportSearchControl = new FormControl('');
  airportOptions: AirportDTO[] = [];
  filteredAirports: AirportDTO[] = [];
  selectedAirports: AirportDTO[] = [];
  errorMessage: string = '';

  constructor(
    private airportService: AirportService,
    private dialogRef: MatDialogRef<SelectAirportDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.fetchAirports();

    this.airportSearchControl.valueChanges
      .pipe(
        startWith(''),
        map((value) => this.filterAirports(value || '')),
      )
      .subscribe((filtered) => (this.filteredAirports = filtered));
  }

  fetchAirports(): void {
    this.airportService
      .getAirports()
      .pipe(
        tap((response) => {
          if (response && response.success && response.data) {
            this.airportOptions = response.data.pageData || [];
            this.filteredAirports = [...this.airportOptions];
          } else {
            this.airportOptions = [];
            this.filteredAirports = [];
          }
        }),
        catchError((error) => {
          this.errorMessage = 'Error fetching airports';
          return of([]);
        }),
      )
      .subscribe();
  }

  filterAirports(searchText: string): AirportDTO[] {
    const lowercaseSearch = searchText.toLowerCase();
    return this.airportOptions.filter((airport) =>
      airport.airportName.toLowerCase().includes(lowercaseSearch),
    );
  }

  onSelectAirport(event: any): void {
    const selectedAirport = event.option.value;

    if (
      !this.selectedAirports.some(
        (a) => a.airportId === selectedAirport.airportId,
      )
    ) {
      this.selectedAirports.push(selectedAirport);
    }

    this.airportSearchControl.setValue('');
  }

  removeAirport(airport: AirportDTO): void {
    this.selectedAirports = this.selectedAirports.filter(
      (a) => a.airportId !== airport.airportId,
    );
  }

  displayAirportName(airport: AirportDTO): string {
    return airport ? airport.airportName : '';
  }

  showAllAirports(): void {
    this.filteredAirports = [...this.airportOptions];
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSelect(): void {
    this.dialogRef.close(this.selectedAirports);
  }
}
