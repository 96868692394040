import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { LoungeService } from '../../../services/lounge.service';
import { AirportService } from '../../../services/airport.service';
import { EditLoungeComponent } from '../edit-lounge/edit-lounge.component';
import { LoungeDTO } from '../../../models/dtos/loungeDTO';
import { LoungeHourDTO } from '../../../models/dtos/loungeHourDTO';
import { LoungeHoursComponent } from '../lounge-hours/lounge-hours.component';
import { WorkstationDTO } from '../../../models/dtos/workstationDTO';

@Component({
  selector: 'aims-lounge-details',
  templateUrl: './lounge-details.component.html',
  styleUrls: ['./lounge-details.component.scss'],
  standalone: false,
})
export class LoungeDetailsComponent implements OnInit {
  loungeData?: LoungeDTO;
  loungeHours: LoungeHourDTO[] = [];
  airportCode: string = '';
  airportName: string = '';
  airportId: number = 0;
  activeTab: 'overview' | 'workstations' = 'overview';
  daysOfWeek: string[] = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  workstations: WorkstationDTO[] = [];
  filteredWorkstations: WorkstationDTO[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private loungeService: LoungeService,
    private airportService: AirportService,
  ) {}

  ngOnInit(): void {
    this.getLoungeDetails();
  }

  getLoungeDetails(): void {
    const loungeId = +this.route.snapshot.params['id'];
    this.loungeService.getLoungeById(loungeId).subscribe(
      (response: any) => {
        if (response.success) {
          this.loungeData = response.data;
          this.fetchAirportDetails(response.data.airportId);
          this.fetchLoungeHours(response.data.loungeId);
          this.fetchWorkstations(response.data.loungeId);
        } else {
          this.loungeService.showError(
            'Could not load lounge details.',
            response.message,
          );
        }
      },
      (error) => {
        this.loungeService.showError(
          'Could not load lounge details.',
          error.error.message,
        );
      },
    );
  }

  fetchWorkstations(loungeId: number): void {
    this.loungeService.getLoungeWorkstations(loungeId).subscribe(
      (response: any) => {
        if (response.success && response.data) {
          this.workstations = response.data;
          this.filteredWorkstations = this.workstations.filter(
            (workstation: WorkstationDTO) => workstation.loungeId === loungeId,
          );
        } else {
          this.workstations = [];
          this.filteredWorkstations = [];
        }
      },
      (error) => {
        this.workstations = [];
        this.filteredWorkstations = [];
        Swal.fire({
          title: 'Error',
          text: `Failed to fetch workstations: ${error.message}`,
          icon: 'error',
          confirmButtonColor: '#2893cc',
          confirmButtonText: 'OK',
        });
      },
    );
  }

  fetchAirportDetails(airportId: number): void {
    this.airportService.getAirportById(airportId).subscribe(
      (response: any) => {
        if (response.success) {
          const airportDetails = response.data;
          this.airportCode = airportDetails.airportCode;
          this.airportName = airportDetails.airportName;
          this.airportId = airportDetails.airportId;
        } else {
          this.loungeService.showError(
            'Could not load airport details.',
            response.message,
          );
        }
      },
      (error) => {
        this.loungeService.showError(
          'Could not load airport details.',
          error.error.message,
        );
      },
    );
  }

  fetchLoungeHours(loungeId: number): void {
    this.loungeService.getLoungeHours(loungeId).subscribe(
      (response: any) => {
        if (response.success) {
          // Sort by dayOfWeek (0 = Sunday, 6 = Saturday)
          this.loungeHours = response.data
            .map((hour: LoungeHourDTO) => ({
              ...hour,
              startTime: this.formatTime(hour.startTime),
              endTime: this.formatTime(hour.endTime),
            }))
            .sort(
              (a: LoungeHourDTO, b: LoungeHourDTO) => a.dayOfWeek - b.dayOfWeek,
            );
        } else {
          this.loungeService.showError(
            'Could not load lounge hours.',
            response.message,
          );
        }
      },
      (error) => {
        this.loungeService.showError(
          'Could not load lounge hours.',
          error.error.message,
        );
      },
    );
  }

  formatTime(time: string | undefined): string {
    if (!time) return '';
    const [hours, minutes] = time.split(':');
    return `${hours}:${minutes}`;
  }

  editLounge(): void {
    const dialogRef = this.dialog.open(EditLoungeComponent, {
      width: '520px',
      data: this.loungeData,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loungeData = result;
      }
    });
  }

  deleteLounge(): void {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2893cc',
      cancelButtonColor: '#939597',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        if (this.loungeData) {
          this.loungeService.removeLounge(this.loungeData.loungeId).subscribe(
            () => {
              Swal.fire({
                title: 'Deleted!',
                text: 'Your lounge has been deleted.',
                icon: 'success',
                confirmButtonColor: '#2893cc',
                confirmButtonText: 'OK',
              }).then(() => {
                this.router.navigate(['/app/lounges']);
              });
            },
            (error) => {
              this.loungeService.showError(
                'Failed to delete lounge.',
                error.error.message,
              );
            },
          );
        }
      }
    });
  }

  refresh(): void {
    window.location.reload();
  }

  switchToOverview(): void {
    this.activeTab = 'overview';
  }

  switchToWorkstations(): void {
    this.activeTab = 'workstations';
  }

  createOrUpdateLoungeHours(): void {
    const defaultHours = this.daysOfWeek.map((day, index) => ({
      loungeHourId: 0,
      loungeId: this.loungeData?.loungeId,
      dayOfWeek: index,
      startTime: '09:00',
      endTime: '23:59',
      effectiveFromUTC: '',
      effectiveToUTC: '',
    }));

    const dialogRef = this.dialog.open(LoungeHoursComponent, {
      data: {
        loungeId: this.loungeData?.loungeId,
        loungeHours: this.loungeHours.length ? this.loungeHours : defaultHours,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.fetchLoungeHours(this.loungeData?.loungeId!);
      }
    });
  }

  navigateToLounges(): void {
    this.router.navigate(['/app/lounges']);
  }
}
