import { Injectable } from '@angular/core';
import { WorkstationService } from '../workstation.service';
import { CardService } from '../card.service';
import { AirlineService } from '../airline.service';
import { AirportService } from '../airport.service';
import { LoungeService } from '../lounge.service';
import { Observable } from 'rxjs';
import { GroupAssociationService } from '../group-association.service';

@Injectable({
  providedIn: 'root',
})
export class SearchProviderService {
  constructor(
    private cardService: CardService,
    private airlineService: AirlineService,
    private airportService: AirportService,
    private loungeService: LoungeService,
    private workstationService: WorkstationService,
    private groupAssociationService: GroupAssociationService,
  ) {}

  searchByKeyword(componentName: string, keyword: string): Observable<any> {
    switch (componentName) {
      case 'access-documents':
        return this.cardService.searchCardByKeyword(keyword);
      case 'partners':
        return this.airlineService.searchAirlinesByKeyword(keyword);
      case 'airports':
        return this.airportService.searchAirportsByKeyword(keyword);
      case 'lounges':
        return this.loungeService.searchLoungesByKeyword(keyword);
      case 'workstations':
        return this.workstationService.searchWorkstationsByKeyword(keyword);
      case 'group-associations':
        return this.groupAssociationService.searchGroupAssociationsByKeyword(
          keyword,
        );
      default:
        throw new Error('Unknown component: ' + componentName);
    }
  }
}
