import { IEnvironment } from './i-env.interface';

export const environment: IEnvironment = {
  production: false,
  apiUrl: 'https://ieg-admin-api-dev-linux-ca.azurewebsites.net/api/v1.0',
  otherConfig: {
    featureFlag: true,
    defaultLanguage: 'en',
    maximumFileUploadSize: undefined,
    maximumAirlineOrCardImageFileUploadSize: undefined,
  },
  azureAd: {
    clientId: 'c2721423-1476-40fc-b292-c936f930d318',
    authority:
      'https://login.microsoftonline.com/2f374131-f402-45bd-9982-c0e11de545fa',
    redirectUri: 'https://admin-dev.ieg-america.com',
    postLogoutRedirectUri: 'https://admin-dev.ieg-america.com/logout',
    scopes: ['api://ieg-admin-api-dev/Admin.Default'],
  },
};
