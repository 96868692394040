import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { WorkstationDTO } from '../models/dtos/workstationDTO';
import { ResponseDTO } from '../models/dtos/responseDTO';
import { BaseService } from './helpers/base.service';
import { ConfigService } from './helpers/config.service';
import { CardDTO } from '../models/dtos/cardDTO';
import { AirlineDTO } from '../models/dtos/airlineDTO';
import { LoadingService } from './helpers/loading.service';

@Injectable({
  providedIn: 'root',
})
export class WorkstationService extends BaseService {
  private apiUrl: string;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private loadingService: LoadingService,
  ) {
    super();
    this.apiUrl = this.configService.getApiUrl() + '/Workstations';
  }

  getWorkstations(
    index: number = 1,
    size: number = 10,
  ): Observable<ResponseDTO<WorkstationDTO>> {
    const params = new HttpParams()
      .set('Index', index.toString())
      .set('Size', size.toString());
    this.loadingService.setLoading(true);

    return this.http
      .get<ResponseDTO<WorkstationDTO>>(`${this.apiUrl}/GetAll`, { params })
      .pipe(
        map((response) => response),
        catchError(this.handleError.bind(this)),
        tap(() => this.loadingService.setLoading(false)),
      );
  }

  searchWorkstationsByKeyword(keyword: string): Observable<WorkstationDTO[]> {
    return this.http
      .get<WorkstationDTO[]>(`${this.apiUrl}/SearchByKeyword`, {
        params: { keyword },
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  getWorkstationById(workstationId: number): Observable<WorkstationDTO> {
    return this.http
      .get<WorkstationDTO>(`${this.apiUrl}/GetById/${workstationId}`)
      .pipe(catchError(this.handleError.bind(this)));
  }

  addWorkstation(workstation: WorkstationDTO): Observable<WorkstationDTO> {
    return this.http
      .post<WorkstationDTO>(`${this.apiUrl}/Add`, workstation)
      .pipe(catchError(this.handleError.bind(this)));
  }

  updateWorkstation(workstation: WorkstationDTO): Observable<WorkstationDTO> {
    return this.http
      .patch<WorkstationDTO>(`${this.apiUrl}/Update`, workstation)
      .pipe(catchError(this.handleError.bind(this)));
  }

  removeWorkstation(workstationId: number): Observable<any> {
    return this.http
      .delete(`${this.apiUrl}/Delete/${workstationId}`)
      .pipe(catchError(this.handleError.bind(this)));
  }

  // getWorkstationRules(workstationId: number): Observable<WorkstationRuleDTO[]> {
  //   return this.http.get<WorkstationRuleDTO[]>(`${this.apiUrl}/GetRules/${workstationId}`)
  //     .pipe(catchError(this.handleError));
  // }
  //
  // addWorkstationRules(rules: WorkstationRuleDTO[]): Observable<WorkstationRuleDTO[]> {
  //   return this.http.post<WorkstationRuleDTO[]>(`${this.apiUrl}/AddRules`, rules)
  //     .pipe(catchError(this.handleError));
  // }
  //
  // updateWorkstationRules(rules: WorkstationRuleDTO[]): Observable<WorkstationRuleDTO[]> {
  //   return this.http.patch<WorkstationRuleDTO[]>(`${this.apiUrl}/UpdateRules`, rules)
  //     .pipe(catchError(this.handleError));
  // }
  //
  // removeWorkstationRule(ruleId: number): Observable<any> {
  //   return this.http.delete(`${this.apiUrl}/DeleteRule/${ruleId}`)
  //     .pipe(catchError(this.handleError));
  // }
  //
  // removeWorkstationRules(workstationId: number): Observable<any> {
  //   return this.http.delete(`${this.apiUrl}/DeleteRules/${workstationId}`)
  //     .pipe(catchError(this.handleError));
  // }

  getExistingAirlines(workstationId: number): Observable<AirlineDTO[]> {
    return this.http
      .get<AirlineDTO[]>(`${this.apiUrl}/GetExistingAirlines/${workstationId}`)
      .pipe(catchError(this.handleError.bind(this)));
  }

  getExistingCards(workstationId: number): Observable<CardDTO[]> {
    return this.http
      .get<CardDTO[]>(`${this.apiUrl}/GetExistingCards/${workstationId}`)
      .pipe(catchError(this.handleError.bind(this)));
  }

  getExistingAirlineCards(
    workstationId: number,
    airlineId: number,
  ): Observable<CardDTO[]> {
    const params = new HttpParams()
      .set('workstationId', workstationId.toString())
      .set('airlineId', airlineId.toString());

    return this.http
      .get<CardDTO[]>(`${this.apiUrl}/GetExistingAirlineCards`, { params })
      .pipe(catchError(this.handleError.bind(this)));
  }
}
