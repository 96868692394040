<div class="workstation-details-container">
  <div class="global-card-header">
    <div class="header-with-back">
      <button
        mat-icon-button
        (click)="navigateToWorkstation()"
        class="back-button"
      >
        <mat-icon>arrow_back</mat-icon>
      </button>
      <div class="header-titles">
        <h1 class="global-card-title">
          {{ workstationData?.workstationName }}
        </h1>
        <h2 class="global-card-subtitle">
          {{ airportCode }} - {{ airportName }}
        </h2>
      </div>
    </div>
    <div class="tab-nav">
      <span
        class="tab-link"
        [class.active]="activeTab === 'overview'"
        (click)="switchToOverview()"
      >
        Overview
      </span>
      <div class="action-buttons">
        <button mat-icon-button (click)="editWorkstation()">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button (click)="deleteWorkstation()">
          <mat-icon>delete</mat-icon>
        </button>
        <button mat-icon-button (click)="refresh()">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div
    *ngIf="workstationData && activeTab === 'overview'"
    class="workstation-overview"
  >
    <div class="card-row">
      <div class="airport-info-card card">
        <div class="card-header">
          <h3 class="card-title">Airport Details</h3>
        </div>
        <div class="card-body">
          <div class="detail-item">
            <span class="detail-title">Airport ID:</span>
            {{ airportId }}
          </div>
          <div class="detail-item">
            <span class="detail-title">Airport Code:</span>
            {{ airportCode }}
          </div>
          <div class="detail-item">
            <span class="detail-title">Airport Name:</span>
            {{ airportName }}
          </div>
        </div>
      </div>

      <div class="lounge-info-card card">
        <div class="card-header">
          <h3 class="card-title">Lounge Information</h3>
        </div>
        <div class="card-body">
          <div class="detail-item">
            <span class="detail-title">Lounge ID:</span>
            {{ loungeId }}
          </div>
          <div class="detail-item">
            <span class="detail-title">Lounge Name:</span>
            {{ loungeName }}
          </div>
          <div class="opening-hours-title">
            <hr class="line" />
            <h4 class="card-subtitle">Lounge Opening Hours:</h4>
            <hr class="line" />
          </div>
          <div class="detail-item" *ngFor="let hour of loungeHours">
            <span class="detail-title">{{ daysOfWeek[hour.dayOfWeek] }}:</span>
            <span *ngIf="hour.startTime && hour.endTime">
              Open from {{ hour.startTime }} to {{ hour.endTime }}
            </span>
            <span
              *ngIf="!hour.startTime || !hour.endTime"
              style="color: #dc3545; font-weight: bold"
            >
              Closed
            </span>
          </div>
        </div>
      </div>

      <div class="workstation-info-card card">
        <div class="card-header">
          <h3 class="card-title">Workstation Information</h3>
        </div>
        <div class="card-body">
          <div class="detail-item">
            <span class="detail-title">Workstation ID:</span>
            {{ workstationData.workstationId }}
          </div>
          <div class="detail-item">
            <span class="detail-title">Workstation Name:</span>
            {{ workstationData.workstationName }}
          </div>
          <div class="detail-item">
            <span class="detail-title">Workstation Default Airline:</span>
            {{ getAirlineName(workstationData.defaultAirlineId) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
