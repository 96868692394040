import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';

import { forkJoin, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AirlineService } from '../../../services/airline.service';
import { FilesService } from '../../../services/files.service';
import { AirlineDTO } from '../../../models/dtos/airlineDTO';
import { CardDTO } from '../../../models/dtos/cardDTO';
import { GroupAssociationService } from '../../../services/group-association.service';

@Component({
  selector: 'aims-airline-access-documents-sidebar',
  templateUrl: './airline-access-documents-sidebar.component.html',
  styleUrls: ['./airline-access-documents-sidebar.component.scss'],
  standalone: false,
})
export class AirlineAccessDocumentsSidebarComponent implements OnInit {
  @Input() editMode: boolean = true;
  @Input() selectedAirlineId: number | null = null;

  airlines: {
    id: number;
    airlineCode: string;
    imageUrl: string;
    allianceImageHandles: string[];
  }[] = [];
  accessDocuments: { id: number; name: string; imageUrl: string }[] = [];
  loadingAirlines: boolean = true;
  loadingAccessDocuments: boolean = true;
  defaultImageUrl: string = '../../../../assets/images/image-icon.png';
  selectedAirlineName: string | null = null;

  connectedDropLists: string[] = [];
  parentDropListId: string = 'airlinesGrid';

  constructor(
    private cdr: ChangeDetectorRef,
    private airlineService: AirlineService,
    private fileService: FilesService,
    private groupAssociationService: GroupAssociationService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['editMode'] || changes['selectedAirlineId']) {
      if (!this.editMode) {
        this.connectedDropLists = ['accessDocumentGrid'];
      } else {
        this.connectedDropLists = [this.parentDropListId];
      }
      if (
        changes['selectedAirlineId'] &&
        changes['selectedAirlineId'].currentValue
      ) {
        this.onAirlineSelected(changes['selectedAirlineId'].currentValue, 0);
      }
      this.cdr.detectChanges();
    }
  }

  ngOnInit(): void {
    this.fetchAirlines();
  }

  fetchAirlines(): void {
    this.airlineService.getAllAirlines(1, 100).subscribe((response: any) => {
      if (response?.data?.pageData) {
        const airlines: AirlineDTO[] = response.data.pageData;

        const groupAssociationIds = [
          ...new Set(
            airlines.flatMap((airline) =>
              airline.groupAssociations.map((ga) => ga.groupAssociationId),
            ),
          ),
        ];

        const groupRequests = groupAssociationIds.map((id) =>
          this.groupAssociationService
            .getGroupAssociationById(id)
            .pipe(catchError(() => of(null))),
        );

        forkJoin(groupRequests).subscribe((groupResponses) => {
          const groupImageMap = new Map<number, string>();

          groupResponses.forEach((response) => {
            if (response?.success && response.data?.groupImageHandle) {
              groupImageMap.set(
                response.data.groupAssociationId,
                response.data.groupImageHandle,
              );
            }
          });

          const requests = airlines.map((airline) => {
            const airlineImage$ = this.fileService
              .getFileUri(airline.airlineImageHandle)
              .pipe(catchError(() => of(this.defaultImageUrl)));

            const allianceImageHandles = airline.groupAssociations
              .map((ga) => groupImageMap.get(ga.groupAssociationId))
              .filter((handle): handle is string => !!handle);

            return forkJoin([airlineImage$]).pipe(
              map(([airlineUrl]) => ({
                id: airline.airlineId,
                airlineCode: airline.airlineCode,
                imageUrl: airlineUrl,
                allianceImageHandles,
              })),
              catchError(() =>
                of({
                  id: airline.airlineId,
                  airlineCode: airline.airlineCode,
                  imageUrl: this.defaultImageUrl,
                  allianceImageHandles: [],
                }),
              ),
            );
          });

          forkJoin(requests).subscribe((results) => {
            this.airlines = results;
            this.loadingAirlines = false;
            this.connectedDropLists = [this.parentDropListId];
          });
        });
      } else {
        this.airlines = [];
        this.loadingAirlines = false;
      }
    });
  }
  onAirlineSelected(airlineId: number | null, index: number): void {
    if (!this.editMode && airlineId !== null) {
      this.selectedAirlineId = airlineId;

      const selectedAirline = this.airlines.find((a) => a.id === airlineId);
      this.selectedAirlineName = selectedAirline
        ? selectedAirline.airlineCode
        : null;

      if (this.selectedAirlineId !== null) {
        this.fetchAccessDocumentsForAirline(this.selectedAirlineId);
      }
    }
  }

  get accessDocumentsTabLabel(): string {
    return this.selectedAirlineId
      ? `${this.selectedAirlineName} Access Documents`
      : 'Access Documents';
  }

  fetchAccessDocumentsForAirline(airlineId: number): void {
    this.loadingAccessDocuments = true;
    this.accessDocuments = [];

    this.airlineService.getAirlineCards(airlineId).subscribe(
      (response: any) => {
        const cards: CardDTO[] = response?.data || [];

        if (cards.length > 0) {
          const requests = cards.map((card) =>
            this.fileService.getFileUri(card.cardImageHandle).pipe(
              map((url) => ({
                id: card.cardId,
                name: card.cardName,
                imageUrl: url,
              })),
              catchError(() =>
                of({
                  id: card.cardId,
                  name: card.cardName,
                  imageUrl: this.defaultImageUrl,
                }),
              ),
            ),
          );

          forkJoin(requests).subscribe((results) => {
            this.accessDocuments = results;
            this.loadingAccessDocuments = false;
          });
        } else {
          this.accessDocuments = [];
          this.loadingAccessDocuments = false;
        }
      },
      (error) => {
        this.loadingAccessDocuments = false;
      },
    );
  }

  onDragStarted(event: any): void {}

  onDragEnded(event: any): void {}
}
