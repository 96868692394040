import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private config: any = environment.otherConfig;

  constructor(private http: HttpClient) {
    this.loadConfigFromApi().subscribe(
      (config) => {
        this.config = { ...this.config, ...config };
      },
      (error) => {
        console.error('Error loading configuration from API', error);
      },
    );
  }

  private loadConfigFromApi(): Observable<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/Configs/GetAdminConfigs`)
      .pipe(
        map((response) => {
          if (response && response.success && response.data) {
            return response.data;
          } else {
            throw new Error('Failed to load configuration from API');
          }
        }),
        catchError((error) => {
          console.error('Error loading configuration from API', error);
          throw error;
        }),
      );
  }

  getConfig(key: string): any {
    return this.config ? this.config[key] : null;
  }

  getApiUrl(): string {
    return environment.apiUrl;
  }
}
