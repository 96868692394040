import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  Input,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2';

import { LoungeDTO } from '../../models/dtos/loungeDTO';
import { LoungeService } from '../../services/lounge.service';
import { AirportService } from '../../services/airport.service';
import { CreateLoungeComponent } from './create-lounge/create-lounge.component';
import { EditLoungeComponent } from './edit-lounge/edit-lounge.component';
import { PaginationModel } from '../../models/pagination-model';
import { AirportDTO } from '../../models/dtos/airportDTO';
import { SearchBarComponent } from '../../layout/search-bar/search-bar.component';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  tap,
} from 'rxjs/operators';
import { CardDTO } from '../../models/dtos/cardDTO';

@Component({
  selector: 'aims-lounges',
  templateUrl: './lounges.component.html',
  styleUrls: ['./lounges.component.scss'],
  standalone: false,
})
export class LoungesComponent implements OnInit, AfterViewInit {
  @Input() airportId!: number;
  @Input() showFullContent: boolean = true;
  @Input() showActions: boolean = true;
  @Input() showSearchBar: boolean = true;
  @Input() filteredLounges: LoungeDTO[] = [];

  dataSource = new MatTableDataSource<LoungeDTO>([]);
  paginationModel: PaginationModel = new PaginationModel();
  airportMap: Map<number, string> = new Map();
  searchTerm: string = '';
  noResultsMessage: string = '';
  popupShown: boolean = false;

  @ViewChild(SearchBarComponent) searchBarComponent!: SearchBarComponent;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private loungeService: LoungeService,
    private airportService: AirportService,
  ) {}

  ngOnInit(): void {
    if (this.airportId && this.filteredLounges.length > 0) {
      this.dataSource.data = this.filteredLounges;
    } else {
      this.fetchAirports();
      this.fetchLounges();
      this.setupDynamicSearch();
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  setupDynamicSearch(): void {
    this.route.queryParams
      .pipe(
        debounceTime(300),
        distinctUntilChanged(
          (prev, curr) => prev['search.terms'] === curr['search.terms'],
        ),
      )
      .subscribe((params) => {
        const term = params['search.terms'] || '';
        if (term.length >= 3) {
          this.searchLounges(term);
        } else {
          this.fetchAllOnClear();
        }
      });
  }

  fetchAllOnClear(): void {
    this.searchTerm = '';
    this.router
      .navigate([], {
        queryParams: { 'search.terms': null, 'page.index': 0 },
        replaceUrl: true,
      })
      .then(() => {
        this.fetchLounges();
      });
  }

  searchLounges(term: string): void {
    this.noResultsMessage = '';
    this.popupShown = false;

    this.loungeService
      .searchLoungesByKeyword(term)
      .pipe(
        tap((response: any) => {
          this.dataSource.data = response?.data || [];
          this.paginationModel.totalCount = response?.data?.length || 0;

          if (this.dataSource.data.length === 0 && !this.popupShown) {
            this.noResultsMessage = 'No lounge found for this search term.';
            this.popupShown = true;

            Swal.fire({
              title: 'No Results',
              text: this.noResultsMessage,
              icon: 'info',
              confirmButtonText: 'OK',
              confirmButtonColor: '#2893cc',
            }).then(() => {
              this.refresh();
            });
          }
        }),
      )
      .subscribe(
        () => this.dataSource._updateChangeSubscription(),
        (error) => {
          this.handleFetchError('searching lounges', error);
        },
      );
  }

  fetchAirports(): void {
    this.airportService.getAirports().subscribe(
      (response) => {
        if (response.data?.pageData && response.data.pageData.length > 0) {
          response.data.pageData.forEach((airport: AirportDTO) => {
            this.airportMap.set(airport.airportId, airport.airportCode);
          });
        } else if (
          response.data?.pageData &&
          response.data.pageData.length === 0
        ) {
          this.airportService.showError(
            'No Airports Found',
            'There are no airports available.',
          );
        } else {
          this.airportService.showError(
            'Error Fetching Airports.',
            response.message || 'There was an error fetching airports.',
          );
        }
      },
      (error) => {
        this.airportService.showError(
          'Error Fetching Airports.',
          error.error.message || 'There was an error fetching airports.',
        );
      },
    );
  }

  fetchLounges(): void {
    const pageIndex = this.paginationModel.pageIndex + 1;
    const pageSize = this.paginationModel.pageSize;

    this.noResultsMessage = '';
    this.popupShown = false;

    this.loungeService.getLounges(pageIndex, pageSize).subscribe(
      (response) => {
        if (response.data) {
          this.dataSource.data = response.data.pageData || [];
          this.paginationModel.totalCount = response.data.totalCount || 0;
          if (this.airportId) {
            this.dataSource.data = this.dataSource.data.filter(
              (lounge) => lounge.airportId === this.airportId,
            );
          }
          if (this.dataSource.data.length === 0) {
            this.noResultsMessage = 'No lounges are available.';
            Swal.fire({
              title: 'No Lounges Found',
              text: 'There are no lounges available for this airport.',
              icon: 'info',
              confirmButtonColor: '#2893cc',
              confirmButtonText: 'OK',
            });
          }
        } else {
          this.loungeService.showError(
            'Error Fetching Lounges.',
            response.message || 'Failed to load lounges.',
          );
        }
      },
      (error) => {
        this.loungeService.showError(
          'Error fetching Lounges.',
          error.error.message || 'Failed to load lounges.',
        );
      },
    );
  }

  handleFetchError(action: string, error: any): void {
    Swal.fire({
      title: `Error ${action}`,
      text: `There was an error: ${error.message}`,
      icon: 'error',
      confirmButtonText: 'OK',
      confirmButtonColor: '#2893cc',
    });
  }

  onSearchResults(data: { results: LoungeDTO[]; searchTerm: string }): void {
    this.searchTerm = data.searchTerm;
    this.dataSource.data = data.results;
    this.paginationModel.totalCount = this.dataSource.data.length;
    this.noResultsMessage =
      this.dataSource.data.length === 0
        ? 'No lounges found for this term.'
        : '';

    this.updateQueryParams();

    if (this.dataSource.data.length === 0) {
      Swal.fire({
        title: 'No Results',
        text: this.noResultsMessage,
        icon: 'info',
        confirmButtonText: 'OK',
        confirmButtonColor: '#2893cc',
      }).then(() => {
        this.refresh();
      });
    }
  }

  updateQueryParams(): void {
    this.router.navigate([], {
      queryParams: { 'search.terms': this.searchTerm || null, 'page.index': 0 },
      replaceUrl: true,
    });
  }

  onPageChange(event: { pageIndex: number; pageSize: number }): void {
    this.paginationModel.pageIndex = event.pageIndex;
    this.paginationModel.pageSize = event.pageSize;
    this.fetchLounges();
  }

  onReset(): void {
    this.searchTerm = '';
    this.refresh();
  }

  refresh(): void {
    this.searchTerm = '';
    this.noResultsMessage = '';
    this.paginationModel.pageIndex = 0;

    this.router
      .navigate([], {
        queryParams: { 'search.terms': null, 'page.index': 0 },
        replaceUrl: true,
      })
      .then(() => {
        this.fetchLounges();
        this.searchBarComponent.clearSearch();
      });
  }

  addNew(): void {
    const dialogRef = this.dialog.open(CreateLoungeComponent, {
      width: '520px',
      maxWidth: '100%',
    });

    dialogRef.afterClosed().subscribe((result: LoungeDTO) => {
      if (result) {
        this.loungeService.addLounge(result).subscribe((newLounge) => {
          const data = this.dataSource.data;
          data.push(newLounge);
          this.dataSource.data = data;
        });
      }
    });
  }

  openEditDialog(lounge: LoungeDTO): void {
    const dialogRef = this.dialog.open(EditLoungeComponent, {
      width: '520px',
      maxWidth: '100%',
      data: lounge,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loungeService.updateLounge(result).subscribe((updatedLounge) => {
          const index = this.dataSource.data.findIndex(
            (p) => p.loungeId === updatedLounge.loungeId,
          );
          if (index !== -1) {
            this.dataSource.data[index] = updatedLounge;
            this.dataSource._updateChangeSubscription();
          }
        });
      }
    });
  }

  deleteLounge(lounge: LoungeDTO): void {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2893cc',
      cancelButtonColor: '#939597',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.loungeService.removeLounge(lounge.loungeId).subscribe(
          () => {
            Swal.fire({
              title: 'Deleted!',
              text: 'The lounge has been deleted.',
              icon: 'success',
              confirmButtonColor: '#2893cc',
              confirmButtonText: 'OK',
            }).then(() => {
              this.fetchLounges();
            });
          },
          (error) => {
            this.loungeService.showError(
              'Error deleting the lounge',
              error.error.message || 'There was an error deleting the lounge.',
            );
          },
        );
      }
    });
  }

  getAirportCode(airportId: number): string {
    return this.airportMap.get(airportId) || 'Unknown';
  }

  getDisplayedColumns(): string[] {
    const displayedColumns = [
      'loungeName',
      'loungeId',
      'airportCode',
      'airportId',
    ];

    if (this.showActions) {
      displayedColumns.push('action');
    }

    return displayedColumns;
  }

  redirectToLoungeDetails(loungeId: number): void {
    this.router.navigate(['/app/lounges', loungeId]);
  }
}
