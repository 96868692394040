import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  Input,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2';

import { CardDTO } from '../../models/dtos/cardDTO';
import { CardService } from '../../services/card.service';
import { FilesService } from '../../services/files.service';
import { PaginationModel } from '../../models/pagination-model';
import { forkJoin, of } from 'rxjs';
import {
  map,
  catchError,
  tap,
  finalize,
  distinctUntilChanged,
  debounceTime,
} from 'rxjs/operators';
import { SearchBarComponent } from '../../layout/search-bar/search-bar.component';
import { CardDetailsDialogComponent } from './card-details-dialog/card-details-dialog.component';

@Component({
  selector: 'aims-access-documents',
  templateUrl: './access-documents.component.html',
  styleUrls: ['./access-documents.component.scss'],
  standalone: false,
})
export class AccessDocumentsComponent implements OnInit, AfterViewInit {
  @Input() showFullContent: boolean = true;
  @Input() showActions: boolean = true;
  @Input() airlineId?: number;
  @Input() showSearchBar: boolean = true;
  @Input() filteredCards: CardDTO[] = [];

  dataSource = new MatTableDataSource<CardDTO>([]);
  paginationModel: PaginationModel = new PaginationModel();
  imageUrls: Map<number, string> = new Map();
  loadingImages: boolean = true;
  searchTerm: string = '';
  noResultsMessage: string = '';
  popupShown: boolean = false;
  defaultImageUrl: string =
    '../../../../assets/images/default-access-doc-image.png';

  @ViewChild(SearchBarComponent) searchBarComponent!: SearchBarComponent;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private cardService: CardService,
    private fileService: FilesService,
  ) {}

  ngOnInit(): void {
    if (this.airlineId && this.filteredCards.length > 0) {
      this.dataSource.data = this.filteredCards;
      this.paginationModel.totalCount = this.filteredCards.length;
      this.loadCardImages();
    } else {
      this.fetchCards();
      this.setupDynamicSearch();
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  setupDynamicSearch(): void {
    this.route.queryParams
      .pipe(
        debounceTime(300),
        distinctUntilChanged(
          (prev, curr) => prev['search.terms'] === curr['search.terms'],
        ),
      )
      .subscribe((params) => {
        const term = params['search.terms'] || '';
        if (term.length >= 3) {
          this.searchCards(term);
        } else {
          this.fetchAllOnClear();
        }
      });
  }

  fetchAllOnClear(): void {
    this.searchTerm = '';
    this.router
      .navigate([], {
        queryParams: { 'search.terms': null, 'page.index': 0 },
        replaceUrl: true,
      })
      .then(() => {
        this.fetchCards();
      });
  }

  searchCards(term: string): void {
    this.loadingImages = true;
    this.noResultsMessage = '';
    this.popupShown = false;

    this.cardService
      .searchCardByKeyword(term)
      .pipe(
        tap((response: any) => {
          const cards: CardDTO[] = response?.data || [];
          this.dataSource.data = cards;
          this.paginationModel.totalCount = cards.length;

          if (this.dataSource.data.length === 0 && !this.popupShown) {
            this.noResultsMessage =
              'No access documents found for this search term.';
            this.popupShown = true;

            Swal.fire({
              title: 'No Results',
              text: this.noResultsMessage,
              icon: 'info',
              confirmButtonText: 'OK',
              confirmButtonColor: '#2893cc',
            }).then(() => {
              this.refresh();
            });
          }
        }),
        finalize(() => (this.loadingImages = false)),
      )
      .subscribe(
        () => this.dataSource._updateChangeSubscription(),
        (error) => {
          this.loadingImages = false;
          this.handleFetchError('searching access documents', error);
        },
      );
  }

  fetchCards(): void {
    const pageIndex = this.paginationModel.pageIndex + 1;
    const pageSize = this.paginationModel.pageSize;

    this.loadingImages = true;
    this.noResultsMessage = '';
    this.popupShown = false;

    this.cardService
      .getAllCards(pageIndex, pageSize)
      .pipe(
        tap((cards: CardDTO[]) => {
          this.dataSource.data = cards;
          this.paginationModel.totalCount = cards.length;
          if (this.dataSource.data.length === 0) {
            this.noResultsMessage = 'No access documents are available.';
          }
        }),
        finalize(() => (this.loadingImages = false)),
      )
      .subscribe(
        () => {
          const requests = this.dataSource.data
            .filter((card) => card.cardImageHandle)
            .map((card) =>
              this.fileService.getFileUri(card.cardImageHandle).pipe(
                map((url) => ({ cardId: card.cardId, url })),
                catchError(() =>
                  of({ cardId: card.cardId, url: this.defaultImageUrl }),
                ),
              ),
            );

          if (requests.length > 0) {
            forkJoin(requests).subscribe((results) => {
              results.forEach((result) => {
                this.imageUrls.set(result.cardId, result.url);
              });
            });
          }
        },
        (error) => {
          this.handleFetchError('fetching access documents', error);
        },
      );
  }

  handleFetchError(action: string, error: any): void {
    this.loadingImages = false;
    Swal.fire({
      title: `Error ${action}`,
      text: `There was an error: ${error.message}`,
      icon: 'error',
      confirmButtonText: 'OK',
      confirmButtonColor: '#2893cc',
    });
  }

  onSearchResults(data: { results: CardDTO[]; searchTerm: string }): void {
    this.searchTerm = data.searchTerm;
    this.dataSource.data = data.results;
    this.paginationModel.totalCount = this.dataSource.data.length;
    this.noResultsMessage =
      this.dataSource.data.length === 0
        ? 'No access documents found for this term.'
        : '';

    this.updateQueryParams();

    if (this.dataSource.data.length === 0) {
      Swal.fire({
        title: 'No Results',
        text: this.noResultsMessage,
        icon: 'info',
        confirmButtonText: 'OK',
        confirmButtonColor: '#2893cc',
      }).then(() => {
        this.refresh();
      });
    }
  }

  updateQueryParams(): void {
    this.router.navigate([], {
      queryParams: { 'search.terms': this.searchTerm || null, 'page.index': 0 },
      replaceUrl: true,
    });
  }

  onPageChange(event: { pageIndex: number; pageSize: number }): void {
    this.paginationModel.pageIndex = event.pageIndex;
    this.paginationModel.pageSize = event.pageSize;
    this.fetchCards();
  }

  loadCardImages(): void {
    const requests = this.dataSource.data
      .filter((card) => card.cardImageHandle)
      .map((card) =>
        this.fileService.getFileUri(card.cardImageHandle).pipe(
          map((url) => ({ cardId: card.cardId, url })),
          catchError(() =>
            of({ cardId: card.cardId, url: this.defaultImageUrl }),
          ),
        ),
      );

    if (requests.length > 0) {
      forkJoin(requests).subscribe((results) => {
        results.forEach((result) => {
          this.imageUrls.set(result.cardId, result.url);
        });
      });
    }
  }

  getCardImageUri(cardId: number): string | undefined {
    return this.imageUrls.get(cardId);
  }

  onReset(): void {
    this.searchTerm = '';
    this.refresh();
  }

  refresh(): void {
    this.searchTerm = '';
    this.noResultsMessage = '';
    this.paginationModel.pageIndex = 0;

    this.router
      .navigate([], {
        queryParams: { 'search.terms': null, 'page.index': 0 },
        replaceUrl: true,
      })
      .then(() => {
        this.fetchCards();
        this.searchBarComponent.clearSearch();
      });
  }

  addNew(): void {
    const dialogRef = this.dialog.open(CardDetailsDialogComponent, {
      data: { card: null },
    });

    dialogRef.afterClosed().subscribe((result: CardDTO) => {
      if (result) {
        this.cardService
          .addCard(result)
          .pipe(
            catchError((error) => {
              this.cardService.showError('Error adding card', error.message);
              return of(null);
            }),
          )
          .subscribe((newCard) => {
            if (newCard) {
              const data = this.dataSource.data;
              data.push(newCard);
              this.dataSource.data = data;
            }
          });
      }
    });
  }

  editCard(card: CardDTO): void {
    const dialogRef = this.dialog.open(CardDetailsDialogComponent, {
      data: { card },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.cardService.updateCard(result).subscribe((updatedCard) => {
          const index = this.dataSource.data.findIndex(
            (c) => c.cardId === updatedCard.cardId,
          );
          if (index !== -1) {
            this.dataSource.data[index] = updatedCard;
            this.dataSource._updateChangeSubscription();
          }
        });
      }
    });
  }

  deleteCard(card: CardDTO): void {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2893cc',
      cancelButtonColor: '#939597',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.cardService
          .deleteCard(card.cardId)
          .pipe(
            catchError((error) => {
              this.cardService.showError('Error deleting card', error.message);
              return of(null);
            }),
          )
          .subscribe(() => {
            this.dataSource.data = this.dataSource.data.filter(
              (c) => c.cardId !== card.cardId,
            );
            Swal.fire({
              title: 'Deleted!',
              text: 'The card has been deleted.',
              icon: 'success',
              confirmButtonColor: '#2893cc',
              confirmButtonText: 'OK',
            });
            this.refresh();
          });
      }
    });
  }

  redirectToCardDetails(cardId: number): void {
    this.router.navigate(['/app/access-documents', cardId]);
  }
}
