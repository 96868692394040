import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { ResponseDTO } from '../../models/dtos/responseDTO';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  constructor() {}

  protected handleError(error: any) {
    const responseDTO: ResponseDTO<any> = error.error;

    const errorTitle =
      error.error?.title || responseDTO.message || 'Unknown error occurred';

    return throwError({ message: errorTitle });
  }

  public showError(title: string, message: string): void {
    Swal.fire({
      title: title,
      text: message,
      icon: 'error',
      confirmButtonColor: '#2893cc',
      confirmButtonText: 'OK',
    });
  }
}
