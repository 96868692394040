import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { LoungeService } from '../../../services/lounge.service';
import { AirportService } from '../../../services/airport.service';
import { LoungeDTO } from '../../../models/dtos/loungeDTO';
import { AirportDTO } from '../../../models/dtos/airportDTO';
import { LoadingService } from '../../../services/helpers/loading.service';
import { startWith } from 'rxjs';
import { map } from 'rxjs/operators';
import { AirlineDTO } from '../../../models/dtos/airlineDTO';

@Component({
  selector: 'aims-create-lounge',
  templateUrl: './create-lounge.component.html',
  styleUrls: ['./create-lounge.component.scss'],
  standalone: false,
})
export class CreateLoungeComponent implements OnInit {
  loungeForm!: FormGroup;
  airportSearchControl = new FormControl('');
  airports: AirportDTO[] = [];
  filteredAirports: AirportDTO[] = [];
  isSubmitting = false;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CreateLoungeComponent>,
    private loungeService: LoungeService,
    private airportService: AirportService,
    private loadingService: LoadingService,
  ) {}

  ngOnInit(): void {
    this.fetchAllAirports();
    this.initializeForm();

    this.airportSearchControl.valueChanges
      .pipe(
        startWith(''),
        map((value) => this.filterAirports(value || '')),
      )
      .subscribe((filtered) => (this.filteredAirports = filtered));
  }

  fetchAllAirports(): void {
    this.loadingService.show();
    this.airportService.getAirports().subscribe(
      (response) => {
        if (
          response &&
          response.success &&
          response.data &&
          response.data.pageData
        ) {
          this.airports = response.data.pageData;
        } else {
          this.airports = [];
        }
        this.loadingService.hide();
      },
      (error) => {
        this.airports = [];
        this.loadingService.hide();
        this.airportService.showError(
          'Error',
          `Could not fetch airports: ${error.message}`,
        );
      },
    );
  }

  filterAirports(searchText: string): AirportDTO[] {
    const lowercaseSearch = searchText.toLowerCase();
    return this.airports.filter((airport) =>
      airport.airportName.toLowerCase().includes(lowercaseSearch),
    );
  }

  onSelectAirport(event: any): void {
    const selectedAirport = event.option.value;
    this.loungeForm.get('airportId')?.setValue(selectedAirport.airportId);
  }

  displayAirportName(airport: AirportDTO): string {
    return airport ? airport.airportName : '';
  }

  getAirportName(airportId: number): string | undefined {
    const airport = this.airports.find((a) => a.airportId === airportId);
    return airport?.airportName;
  }

  initializeForm(): void {
    this.loungeForm = this.fb.group({
      loungeId: [0],
      airportId: ['', Validators.required],
      loungeName: ['', Validators.required],
      maxOccupancy: [0, [Validators.required, Validators.min(0)]],
    });
  }

  showAllAirports(): void {
    this.filteredAirports = [...this.airports];
  }

  onSubmit(): void {
    if (this.loungeForm.valid) {
      this.isSubmitting = true;
      const lounge: LoungeDTO = {
        loungeId: 0,
        airportId: this.loungeForm.get('airportId')?.value,
        loungeName: this.loungeForm.get('loungeName')?.value,
        maxOccupancy: this.loungeForm.get('maxOccupancy')?.value,
      };
      this.loadingService.show();

      this.loungeService
        .addLounge(lounge)
        .subscribe(
          (response) => {
            this.loadingService.hide();

            Swal.fire({
              title: 'Success!',
              text: 'Lounge created successfully.',
              icon: 'success',
              confirmButtonColor: '#2893cc',
              confirmButtonText: 'OK',
            }).then(() => {
              this.dialogRef.close(response);
              window.location.reload();
            });
          },
          (error) => {
            this.loadingService.hide();
            this.loungeService.showError(
              'Failed to create lounge. Please try again.',
              error.error.message,
            );
          },
        )
        .add(() => {
          this.isSubmitting = false;
        });
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
