import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainSectionComponent } from './components/main-section/main-section.component';
import { AirportsComponent } from './components/airports/airports.component';
import { LoungesComponent } from './components/lounges/lounges.component';
import { WorkstationsComponent } from './components/workstations/workstations.component';
import { PartnersComponent } from './components/partners/partners.component';
import { AccessDocumentsComponent } from './components/access-documents/access-documents.component';
import { AirlineDetailsComponent } from './components/partners/airline-details/airline-details.component';
import { CardDetailsComponent } from './components/access-documents/card-details/card-details.component';
import { AirportDetailsComponent } from './components/airports/airport-details/airport-details.component';
import { LoungeDetailsComponent } from './components/lounges/lounge-details/lounge-details.component';
import { WorkstationDetailsComponent } from './components/workstations/workstation-details/workstation-details.component';
import { CardValidationComponent } from './components/card-validation/card-validation.component';
import { CardValidationProgramComponent } from './components/card-validation/card-validation-program/card-validation-program.component';
import { GroupAssociationsComponent } from './components/partners/group-associations/group-associations.component';

import { MsalGuard } from '@azure/msal-angular';
import { LogoutComponent } from './auth/logout/logout.component';
import { LoginComponent } from './auth/login/login.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'app',
    canActivate: [MsalGuard],
    children: [
      { path: '', component: MainSectionComponent },
      { path: 'default-content', component: MainSectionComponent },
      { path: 'airports', component: AirportsComponent },
      { path: 'airports/:id', component: AirportDetailsComponent },
      { path: 'lounges', component: LoungesComponent },
      { path: 'lounges/:id', component: LoungeDetailsComponent },
      {
        path: 'lounges/:id/workstations',
        component: WorkstationDetailsComponent,
      },
      { path: 'workstations', component: WorkstationsComponent },
      { path: 'workstations/:id', component: WorkstationDetailsComponent },
      { path: 'partners', component: PartnersComponent },
      { path: 'partners/:id', component: AirlineDetailsComponent },
      {
        path: 'partners/:id/access-documents',
        component: AccessDocumentsComponent,
      },
      { path: 'access-documents', component: AccessDocumentsComponent },
      { path: 'access-documents/:id', component: CardDetailsComponent },
      { path: 'card-validation', component: CardValidationComponent },
      {
        path: 'card-validation-programs',
        component: CardValidationProgramComponent,
      },
      { path: 'group-associations', component: GroupAssociationsComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
