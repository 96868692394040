import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2';

import { AirlineDTO } from '../../models/dtos/airlineDTO';
import { AirlineService } from '../../services/airline.service';
import { PaginationModel } from '../../models/pagination-model';
import { FilesService } from '../../services/files.service';
import { forkJoin, of } from 'rxjs';
import {
  tap,
  finalize,
  map,
  catchError,
  distinctUntilChanged,
  debounceTime,
} from 'rxjs/operators';
import { SearchBarComponent } from '../../layout/search-bar/search-bar.component';
import { AirlinePartnerDialogComponent } from './airline-partner-dialog/airline-partner-dialog.component';

@Component({
  selector: 'aims-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss'],
  standalone: false,
})
export class PartnersComponent implements OnInit, AfterViewInit {
  dataSource = new MatTableDataSource<AirlineDTO>([]);
  paginationModel: PaginationModel = new PaginationModel();
  imageUrls: Map<number, string> = new Map();
  loadingImages: boolean = true;
  defaultImageUrl: string = '../../../../assets/images/image-icon.png';
  searchTerm: string = '';
  noResultsMessage: string = '';
  popupShown: boolean = false;

  @ViewChild(SearchBarComponent) searchBarComponent!: SearchBarComponent;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private airlineService: AirlineService,
    private fileService: FilesService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.searchTerm = params['search.terms'] || '';
      this.paginationModel.pageIndex = params['page.index'] || 0;
      this.fetchAirlines();
      this.setupDynamicSearch();
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  setupDynamicSearch(): void {
    this.route.queryParams
      .pipe(
        debounceTime(300),
        distinctUntilChanged(
          (prev, curr) => prev['search.terms'] === curr['search.terms'],
        ),
      )
      .subscribe((params) => {
        const term = params['search.terms'] || '';
        if (term.length >= 2) {
          this.searchAirlines(term);
        } else {
          this.fetchAllOnClear();
        }
      });
  }

  fetchAllOnClear(): void {
    this.searchTerm = '';
    this.router
      .navigate([], {
        queryParams: { 'search.terms': null, 'page.index': 0 },
        replaceUrl: true,
      })
      .then(() => {
        this.fetchAirlines();
      });
  }

  searchAirlines(term: string): void {
    this.loadingImages = true;
    this.noResultsMessage = '';
    this.popupShown = false;

    this.airlineService
      .searchAirlinesByKeyword(term)
      .pipe(
        tap((response: any) => {
          this.dataSource.data = response?.data || [];
          this.paginationModel.totalCount = response?.data?.length || 0;

          if (this.dataSource.data.length === 0 && !this.popupShown) {
            this.noResultsMessage =
              'No airlines are available for this search term.';
            this.popupShown = true;
            Swal.fire({
              title: 'No Results',
              text: this.noResultsMessage,
              icon: 'info',
              confirmButtonText: 'OK',
              confirmButtonColor: '#2893cc',
            }).then(() => {
              this.refresh();
            });
          }
        }),
        finalize(() => (this.loadingImages = false)),
      )
      .subscribe(
        () => this.dataSource._updateChangeSubscription(),
        (error) => {
          this.loadingImages = false;
          this.handleFetchError('searching airlines', error);
        },
      );
  }

  fetchAirlines(): void {
    const pageIndex = this.paginationModel.pageIndex + 1;
    const pageSize = this.paginationModel.pageSize;

    this.loadingImages = true;
    this.noResultsMessage = '';
    this.popupShown = false;

    this.airlineService
      .getAllAirlines(pageIndex, pageSize)
      .pipe(
        tap((response: any) => {
          this.dataSource.data = response?.data?.pageData || [];
          this.paginationModel.totalCount = response?.data?.totalCount || 0;
          if (this.dataSource.data.length === 0) {
            this.noResultsMessage = 'No airlines are available.';
          }
        }),
        finalize(() => (this.loadingImages = false)),
      )
      .subscribe(
        (response: any) => {
          const requests = this.dataSource.data
            .filter((partner) => partner.airlineImageHandle)
            .map((partner) =>
              this.fileService.getFileUri(partner.airlineImageHandle).pipe(
                map((url) => ({ airlineId: partner.airlineId, url })),
                catchError(() =>
                  of({
                    airlineId: partner.airlineId,
                    url: this.defaultImageUrl,
                  }),
                ),
              ),
            );
          if (requests.length > 0) {
            forkJoin(requests).subscribe((results) => {
              results.forEach((result) => {
                this.imageUrls.set(result.airlineId, result.url);
              });
            });
          }
        },
        (error) => {
          this.handleFetchError('fetching airlines', error);
        },
      );
  }

  handleFetchError(action: string, error: any): void {
    this.loadingImages = false;
    Swal.fire({
      title: `Error ${action}`,
      text: `There was an error: ${error.message}`,
      icon: 'error',
      confirmButtonText: 'OK',
      confirmButtonColor: '#2893cc',
    });
  }

  onSearchResults(data: { results: AirlineDTO[]; searchTerm: string }): void {
    this.searchTerm = data.searchTerm;
    this.dataSource.data = data.results;
    this.paginationModel.totalCount = data.results.length;
    this.noResultsMessage =
      data.results.length === 0
        ? 'No airlines are available for this search term.'
        : '';
    this.updateQueryParams();
  }

  updateQueryParams(): void {
    this.router.navigate([], {
      queryParams: { 'search.terms': this.searchTerm || null, 'page.index': 0 },
      replaceUrl: true,
    });
  }

  onPageChange(event: { pageIndex: number; pageSize: number }): void {
    this.paginationModel.pageIndex = event.pageIndex;
    this.paginationModel.pageSize = event.pageSize;
    this.fetchAirlines();
  }

  getImageUri(airlineId: number): string | undefined {
    return this.imageUrls.get(airlineId);
  }

  onReset() {
    this.searchTerm = '';
    this.refresh();
  }

  refresh(): void {
    this.searchTerm = '';
    this.noResultsMessage = '';
    this.paginationModel.pageIndex = 0;

    this.router
      .navigate([], {
        queryParams: { 'search.terms': null, 'page.index': 0 },
        replaceUrl: true,
      })
      .then(() => {
        this.fetchAirlines();
        this.searchBarComponent.clearSearch();
      });
  }

  getPartnerById(airlineId: number): AirlineDTO | undefined {
    return this.dataSource.data.find(
      (partner) => partner.airlineId === airlineId,
    );
  }

  addNew(): void {
    const dialogRef = this.dialog.open(AirlinePartnerDialogComponent, {
      data: { airline: null },
    });

    dialogRef.afterClosed().subscribe((result: AirlineDTO) => {
      if (result) {
        this.airlineService.addAirline(result).subscribe(
          (newAirline) => {
            const data = this.dataSource.data;
            data.push(newAirline);
          },
          (error) => {
            Swal.fire({
              title: 'Error adding airline',
              text: `Error adding an airline partner: ${error.message}`,
              icon: 'error',
              confirmButtonColor: '#2893cc',
              confirmButtonText: 'OK',
            });
          },
        );
      }
    });
  }

  deletePartner(partner: AirlineDTO): void {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2893cc',
      cancelButtonColor: '#939597',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.airlineService.deleteAirline(partner.airlineId).subscribe(
          () => {
            Swal.fire({
              title: 'Deleted!',
              text: 'The partner has been deleted.',
              icon: 'success',
              confirmButtonColor: '#2893cc',
              confirmButtonText: 'OK',
            }).then(() => {
              this.fetchAirlines();
            });
          },
          (error) => {
            Swal.fire({
              title: 'Error deleting airline',
              text: `Error deleting airline partner: ${error.message}`,
              icon: 'error',
              confirmButtonColor: '#2893cc',
              confirmButtonText: 'OK',
            });
          },
        );
      }
    });
  }

  openEditDialog(partner: AirlineDTO): void {
    const dialogRef = this.dialog.open(AirlinePartnerDialogComponent, {
      data: { airline: partner },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.airlineService
          .updateAirline(result)
          .subscribe((updatedAirline) => {
            const index = this.dataSource.data.findIndex(
              (p) => p.airlineId === updatedAirline.airlineId,
            );
            if (index !== -1) {
              this.dataSource.data[index] = updatedAirline;
              this.dataSource._updateChangeSubscription();
            }
          });
      }
    });
  }

  redirectToPartnerDetails(airlineId: number): void {
    this.router.navigate(['/app/partners', airlineId]);
  }
}
