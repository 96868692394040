import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';

import { CardDTO } from '../../../models/dtos/cardDTO';
import { CardService } from '../../../services/card.service';
import { AirlineService } from '../../../services/airline.service';
import { FilesService } from '../../../services/files.service';
import { CardDetailsDialogComponent } from '../card-details-dialog/card-details-dialog.component';

enum CardSwipeType {
  Card = 1,
  BoardingPass = 2,
}

@Component({
  selector: 'aims-card-details',
  templateUrl: './card-details.component.html',
  styleUrls: ['./card-details.component.scss'],
  standalone: false,
})
export class CardDetailsComponent implements OnInit {
  cardData?: CardDTO;
  airlineCode: string = '';
  imageUrl?: string;

  passengerFields: string[] = [
    'Name',
    'FF Number',
    'Flight',
    'PNR',
    'Origin & Destination',
    'Notes',
  ];

  guestFields: string[] = [
    'Name',
    'FF Number',
    'Flight',
    'PNR',
    'Origin & Destination',
    'Notes',
  ];

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private cardService: CardService,
    private airlineService: AirlineService,
    private fileService: FilesService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.getCardDetails();
  }

  getCardDetails(): void {
    const cardId = +this.route.snapshot.params['id'];
    this.cardService.getCardById(cardId).subscribe(
      (card: CardDTO) => {
        this.cardData = card;
        this.fetchAirlineDetails(card.airlineId);

        if (card.cardImageHandle) {
          this.fileService.getFileUri(card.cardImageHandle).subscribe(
            (url) => {
              this.imageUrl = url;
            },
            (error) => {
              this.fileService.showError(
                'Error',
                `Could not fetch card image URL: ${error.message}`,
              );
            },
          );
        }
      },
      (error) => {
        this.cardService.showError(
          'Error',
          `Could not load card details: ${error.message}`,
        );
      },
    );
  }

  fetchAirlineDetails(airlineId: number): void {
    this.airlineService.getAirlineById(airlineId).subscribe(
      (airlineDetails) => {
        this.airlineCode = airlineDetails.airlineCode;
      },
      (error) => {
        this.airlineService.showError(
          'Error',
          `Could not load airline details: ${error.message}`,
        );
      },
    );
  }

  editCard(): void {
    const dialogRef = this.dialog.open(CardDetailsDialogComponent, {
      data: { card: this.cardData },
    });

    dialogRef.afterClosed().subscribe((result: CardDTO) => {
      if (result) {
        this.cardData = result;
        this.refresh();
      }
    });
  }

  deleteCard(): void {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2893cc',
      cancelButtonColor: '#939597',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        if (this.cardData?.cardId) {
          this.cardService.deleteCard(this.cardData.cardId).subscribe({
            next: () => {
              Swal.fire({
                title: 'Deleted!',
                text: 'Your card has been deleted.',
                icon: 'success',
                confirmButtonColor: '#2893cc',
                confirmButtonText: 'OK',
              }).then(() => {
                this.router.navigate(['/app/access-documents']);
              });
            },
            error: (error) => {
              this.cardService.showError(
                'Error',
                `Failed to delete card: ${error.message}`,
              );
            },
          });
        }
      }
    });
  }

  getSwipeTypeName(swipeType: number): string {
    return CardSwipeType[swipeType] || 'Unknown';
  }

  getGuestTypesDescription(guestTypes: number): string {
    let descriptions: string[] = [];
    if (guestTypes & 1) descriptions.push('Pay');
    if (guestTypes & 2) descriptions.push('Family');
    if (guestTypes & 4) descriptions.push('Complimentary');
    return descriptions.join(', ') || 'None';
  }

  getFieldNamesFromInteger(number: number, fields: string[]): string[] {
    const binaryOrder = [5, 4, 3, 1, 0, 2]; // This corresponds to: Name -> FF Number -> Flight -> PNR  -> Origin & Destination  -> Notes
    const binaryString = number.toString(2).padStart(6, '0');
    const reorderedBinaryString = binaryOrder
      .map((index) => binaryString[index])
      .join('');
    const userOrder = [
      'name',
      'ffNumber',
      'flight',
      'pnr',
      'originDestination',
      'notes',
    ];
    return userOrder
      .map((key, index) => {
        return reorderedBinaryString[index] === '1' ? fields[index] : null;
      })
      .filter((label) => label !== null) as string[];
  }

  refresh() {
    window.location.reload();
  }

  navigateToAccessDocuments(): void {
    this.router.navigate(['/app/access-documents']);
  }
}
