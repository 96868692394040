<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #sidenav mode="side" opened="true" class="sidenav">
    <div class="sidebar-carousel">
      <img [src]="currentImage" alt="Carousel Image" class="d-block w-100" />
    </div>

    <mat-nav-list>
      <a mat-list-item routerLink="/app/default-content">
        <mat-icon>home</mat-icon>
        <span>Home</span>
      </a>

      <!-- Spacer -->
      <mat-divider class="my-spacer"></mat-divider>
      <!-- Group Associations -->
      <a mat-list-item routerLink="/app/group-associations">
        <mat-icon>group</mat-icon>
        <span>Group Associations</span>
      </a>

      <!-- Partners -->
      <a mat-list-item routerLink="/app/partners">
        <mat-icon>airlines</mat-icon>
        <span>Partners</span>
      </a>

      <!-- Access Documents -->
      <a mat-list-item routerLink="/app/access-documents">
        <mat-icon>folder_open</mat-icon>
        <span>Access Documents</span>
      </a>

      <!-- Spacer -->
      <mat-divider class="my-spacer"></mat-divider>

      <!-- Airports -->
      <a mat-list-item routerLink="/app/airports">
        <mat-icon>local_airport</mat-icon>
        <span>Airports</span>
      </a>

      <!-- Lounges -->
      <a mat-list-item routerLink="/app/lounges">
        <mat-icon>event_seat</mat-icon>
        <span>Lounges</span>
      </a>

      <!-- WorkStations -->
      <a mat-list-item routerLink="/app/workstations">
        <mat-icon>desktop_windows</mat-icon>
        <span>WorkStations</span>
      </a>

      <!-- Spacer -->
      <mat-divider class="my-spacer"></mat-divider>

      <!-- Card Validation -->
      <a mat-list-item routerLink="/app/card-validation">
        <mat-icon>check_circle_outline</mat-icon>
        <span>Card Validation</span>
      </a>
    </mat-nav-list>
  </mat-sidenav>
</mat-sidenav-container>
