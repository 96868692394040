<div class="main-section">
  <!-- Global Loading Component -->
  <aims-loading></aims-loading>

  <!-- Sidebar -->
  <div class="sidebar-container" *ngIf="isDefaultContentVisible">
    <aims-airline-access-documents-sidebar
      [editMode]="isEditMode"
      [selectedAirlineId]="selectedAirlineId"
    ></aims-airline-access-documents-sidebar>
  </div>

  <!-- Filters Section -->
  <div class="filter-container" *ngIf="isDefaultContentVisible">
    <form [formGroup]="filterForm" class="filter-form">
      <aims-searchable-select
        [label]="'Airport'"
        [placeholder]="'Search or Select Airport'"
        [options]="airports"
        [displayFn]="displayAirportName"
        [control]="airportSearchControl"
        (optionSelected)="onSelectAirport($event)"
      ></aims-searchable-select>

      <aims-searchable-select
        [label]="'Lounge'"
        [placeholder]="'Search Lounge'"
        [options]="fetchedLounges"
        [displayFn]="displayLoungeName"
        [control]="loungeSearchControl"
        (optionSelected)="onSelectLounge($event)"
      ></aims-searchable-select>

      <aims-searchable-select
        [label]="'Workstation'"
        [placeholder]="'Search Workstation'"
        [options]="fetchedWorkstations"
        [displayFn]="displayWorkstationName"
        [control]="workstationSearchControl"
        (optionSelected)="onSelectWorkstation($event)"
      ></aims-searchable-select>
    </form>
    <button mat-icon-button (click)="clearFilters()" class="clear-filters">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <!-- Airline Grid Section -->
  <div class="grid-section" *ngIf="isDefaultContentVisible">
    <div class="grid-header">
      <h1>
        Partners
        <mat-slide-toggle
          [(ngModel)]="isEditMode"
          color="primary"
          (change)="onEditModeToggle()"
        >
          Edit Mode
        </mat-slide-toggle>
      </h1>
    </div>
    <div class="airlines-grid-container-wrapper">
      <div *ngIf="!isWorkstationSelected()" class="blur-message">
        Please select a workstation to enable editing.
      </div>
      <div class="airlines-grid-container" [ngClass]="getAirlineGridClass()">
        <ng-container *ngIf="airlinesGrid.length; else loadingGrid">
          <div
            class="airlines-grid"
            id="airlinesGrid"
            cdkDropList
            cdkDropListAutoScroll
            [cdkDropListConnectedTo]="connectedAirlineDropLists"
            [cdkDropListData]="airlinesGrid"
            (cdkDropListDropped)="onDropAirlines($event)"
            [cdkDropListSortingDisabled]="true"
          >
            <div
              class="grid-item"
              *ngFor="
                let cell of airlinesGrid;
                let i = index;
                trackBy: trackByFn
              "
              cdkDrag
              [cdkDragDisabled]="!cell"
              [cdkDragData]="{ item: cell, index: i }"
              (cdkDragMoved)="onDragMoved($event)"
              (cdkDragStarted)="onDragStarted($event)"
              (cdkDragEnded)="onDragEnded()"
              [attr.data-index]="i"
              #gridItem
              (click)="selectAirline(cell?.airlineId ?? null, i)"
              [class.selectable]="!isEditMode && cell?.airlineId"
              [class.selected]="i === selectedAirlineIndex"
            >
              <ng-container *ngIf="cell; else emptyCell">
                <div class="card">
                  <aims-alliance-logos
                    [allianceImageHandles]="cell?.allianceImageHandles || []"
                  >
                  </aims-alliance-logos>
                  <div
                    class="airline-image"
                    [style.backgroundImage]="'url(' + cell.imageUrl + ')'"
                  ></div>
                  <span class="airline-code">{{ cell.airlineCode }}</span>
                  <button
                    mat-icon-button
                    class="delete-icon"
                    *ngIf="isEditMode"
                    (click)="confirmDeleteAirline(i)"
                  >
                    <mat-icon>remove_circle</mat-icon>
                  </button>
                </div>
              </ng-container>
              <ng-template #emptyCell>
                <div class="empty-card">
                  <span>{{ i }}</span>
                </div>
              </ng-template>
            </div>
          </div>
        </ng-container>
        <ng-template #loadingGrid>
          <div class="loading-message">Loading Airlines Grid...</div>
        </ng-template>
      </div>
      <!-- Save and Cancel Buttons -->
      <div class="grid-actions" *ngIf="isEditMode">
        <button
          mat-raised-button
          color="primary"
          [disabled]="!selectedWorkstationId || !airlinesChangesMade"
          (click)="saveAirlineChanges()"
        >
          Save
        </button>
        <button
          mat-raised-button
          color="warn"
          [disabled]="!selectedWorkstationId"
          (click)="cancelAirlineChanges()"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>

  <!-- Access Document Grid Section -->
  <div class="grid-section" *ngIf="isDefaultContentVisible && !isEditMode">
    <div class="grid-header">
      <h1>
        Access Document
        <mat-slide-toggle
          [(ngModel)]="isAccessDocumentEditMode"
          color="primary"
          (change)="onAccessDocumentEditModeToggle()"
        >
          Edit Mode
        </mat-slide-toggle>
      </h1>
    </div>
    <div class="access-document-grid-container-wrapper">
      <div *ngIf="!isWorkstationSelected()" class="blur-message">
        Please select a workstation to enable editing.
      </div>
      <div
        class="access-document-grid-container"
        [ngClass]="{ blurred: !selectedWorkstationId }"
      >
        <ng-container *ngIf="accessDocumentsGrid.length; else loadingGrid">
          <div
            class="access-document-grid"
            id="accessDocumentGrid"
            cdkDropList
            cdkDropListAutoScroll
            [cdkDropListData]="accessDocumentsGrid"
            (cdkDropListDropped)="onDropAccessDocuments($event)"
            [cdkDropListConnectedTo]="connectedAccessDocumentDropLists"
            [cdkDropListSortingDisabled]="true"
          >
            <div
              class="grid-item"
              *ngFor="
                let cell of accessDocumentsGrid;
                let i = index;
                trackBy: trackByFn
              "
              cdkDrag
              [cdkDragDisabled]="!cell"
              [cdkDragData]="{ item: cell, index: i }"
              (cdkDragMoved)="onDragMoved($event)"
              (cdkDragStarted)="onDragStarted($event)"
              (cdkDragEnded)="onDragEnded()"
              [attr.data-index]="i"
              #gridItem
            >
              <ng-container *ngIf="cell; else emptyCell">
                <div class="card">
                  <div
                    class="access-document-image"
                    [style.backgroundImage]="'url(' + cell.imageUrl + ')'"
                  ></div>
                  <span class="card-name">{{ cell.cardName }}</span>
                  <button
                    mat-icon-button
                    class="delete-icon"
                    *ngIf="isAccessDocumentEditMode"
                    (click)="confirmDeleteAccessDocument(i)"
                  >
                    <mat-icon>remove_circle</mat-icon>
                  </button>
                </div>
              </ng-container>
              <ng-template #emptyCell>
                <div class="empty-card">
                  <span>{{ i }}</span>
                </div>
              </ng-template>
            </div>
          </div>
        </ng-container>
        <ng-template #loadingGrid>
          <div class="loading-message">Loading Access Document Grid...</div>
        </ng-template>
      </div>

      <!-- Save and Cancel Buttons -->
      <div class="grid-actions" *ngIf="isAccessDocumentEditMode">
        <button
          mat-raised-button
          color="primary"
          [disabled]="!selectedWorkstationId || !documentsChangesMade"
          (click)="saveAccessDocumentChanges()"
        >
          Save
        </button>
        <button
          mat-raised-button
          color="warn"
          [disabled]="!selectedWorkstationId"
          (click)="cancelAccessDocumentChanges()"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>

  <!-- Route to the appropriate page -->
  <router-outlet (activate)="onActivateComponent($event)"></router-outlet>
</div>
