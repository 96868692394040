<div class="dialog-container">
  <aims-loading></aims-loading>

  <button mat-icon-button class="close-button" (click)="onCancel()">
    <mat-icon>close</mat-icon>
  </button>

  <mat-vertical-stepper [linear]="true" #stepper>
    <!-- Step 1: Workstation Selection -->
    <mat-step [stepControl]="cardValidationForm.controls['workstationId']">
      <ng-template matStepLabel>Workstation Selection</ng-template>
      <form [formGroup]="cardValidationForm">
        <div class="input-group">
          <mat-form-field appearance="fill">
            <mat-label>Workstation Name</mat-label>
            <input
              type="text"
              matInput
              [value]="getSelectedWorkstationName()"
              [formControl]="workstationSearchControl"
              [matAutocomplete]="workstationAuto"
              placeholder="Search Workstation"
              autocomplete="off"
              (focus)="showAllWorkstations()"
            />
            <mat-autocomplete
              #workstationAuto="matAutocomplete"
              [displayWith]="displayWorkstationName"
              (optionSelected)="onSelectWorkstation($event)"
            >
              <ng-container
                *ngIf="filteredWorkstations.length > 0; else noData"
              >
                <mat-option
                  *ngFor="let workstation of filteredWorkstations"
                  [value]="workstation"
                >
                  {{ workstation.workstationName }}
                </mat-option>
              </ng-container>
              <ng-template #noData>
                <mat-option disabled>No workstations to display</mat-option>
              </ng-template>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <button mat-raised-button color="warn" matStepperPrevious>Back</button>
        <button mat-raised-button color="primary" matStepperNext>Next</button>
      </form>
    </mat-step>

    <!-- Step 2: Airline Selection -->
    <mat-step [stepControl]="cardValidationForm.controls['airlineId']">
      <ng-template matStepLabel>Airline Selection</ng-template>
      <form [formGroup]="cardValidationForm">
        <div class="input-group">
          <mat-form-field appearance="fill">
            <mat-label>Airline Name</mat-label>
            <mat-select formControlName="airlineId">
              <mat-option
                *ngFor="let airline of airlines"
                [value]="airline.airlineId"
              >
                {{ airline.airlineName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <button mat-raised-button color="warn" matStepperPrevious>Back</button>
        <button mat-raised-button color="primary" matStepperNext>Next</button>
      </form>
    </mat-step>

    <!-- Step 3: Access Document Selection -->
    <mat-step [stepControl]="cardValidationForm.controls['cardId']">
      <ng-template matStepLabel>Access Document Selection</ng-template>
      <form [formGroup]="cardValidationForm">
        <div class="input-group">
          <mat-form-field appearance="fill">
            <mat-label>Access Document</mat-label>
            <mat-select formControlName="cardId">
              <ng-container *ngIf="filteredCards.length > 0; else noData">
                <mat-option
                  *ngFor="let card of filteredCards"
                  [value]="card.cardId"
                >
                  {{ card.cardName }}
                </mat-option>
              </ng-container>
              <ng-template #noData>No Access Documents available</ng-template>
            </mat-select>
          </mat-form-field>
        </div>
        <button mat-raised-button color="warn" matStepperPrevious>Back</button>
        <button mat-raised-button color="primary" matStepperNext>Next</button>
      </form>
    </mat-step>

    <!-- Step 4: Validation Program Selection -->
    <mat-step
      [stepControl]="cardValidationForm.controls['validationProgramId']"
    >
      <ng-template matStepLabel>Validation Program Selection</ng-template>
      <form [formGroup]="cardValidationForm">
        <div class="input-group">
          <mat-form-field appearance="fill">
            <mat-label>Validation Program</mat-label>
            <mat-select formControlName="validationProgramId">
              <mat-option
                *ngFor="let program of validationPrograms"
                [value]="program.cardValidationProgramId"
              >
                {{ program.validationProgram }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <button mat-raised-button color="warn" matStepperPrevious>Back</button>
        <button mat-raised-button color="primary" matStepperNext>Next</button>
      </form>
    </mat-step>

    <!-- Step 5: Review and Submit -->
    <mat-step>
      <ng-template matStepLabel>Review and Submit</ng-template>
      <div class="review-section">
        <h3>Review Card Validation Details</h3>
        <p>
          <strong>Workstation:</strong>
          {{
            getWorkstationName(cardValidationForm.get("workstationId")?.value)
          }}
        </p>
        <p>
          <strong>Airline:</strong>
          {{ getAirlineName(cardValidationForm.get("airlineId")?.value) }}
        </p>
        <p>
          <strong>Access Document:</strong>
          {{ getCardName(cardValidationForm.get("cardId")?.value) }}
        </p>
        <p>
          <strong>Validation Program:</strong>
          {{
            getValidationProgramName(
              cardValidationForm.get("validationProgramId")?.value
            )
          }}
        </p>
      </div>
      <button mat-raised-button color="warn" matStepperPrevious>Back</button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="!cardValidationForm.valid || isSubmitting"
        (click)="onSubmit()"
      >
        Submit
      </button>
    </mat-step>
  </mat-vertical-stepper>
</div>
