export class PaginationModel {
  pageSizeOptions: number[];
  pageSize: number;
  pageIndex: number;
  totalCount: number;

  constructor() {
    this.pageSizeOptions = [5, 10, 25, 100];
    this.pageSize = this.pageSizeOptions[1]; // default page size of 10
    this.pageIndex = 0;
    this.totalCount = 0;
  }
}
