import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  Input,
} from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2';

import { CardValidationDTO } from '../../models/dtos/cardValidationDTO';
import { PaginationModel } from '../../models/pagination-model';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AirportDTO } from '../../models/dtos/airportDTO';
import { LoungeDTO } from '../../models/dtos/loungeDTO';
import { WorkstationDTO } from '../../models/dtos/workstationDTO';
import { AirlineDTO } from '../../models/dtos/airlineDTO';
import { CardDTO } from '../../models/dtos/cardDTO';
import { AirportService } from '../../services/airport.service';
import { LoungeService } from '../../services/lounge.service';
import { WorkstationService } from '../../services/workstation.service';
import { AirlineService } from '../../services/airline.service';
import { CardService } from '../../services/card.service';
import { CardValidationService } from '../../services/card-validation.service';
import { of, startWith } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { PaginationHelperService } from '../../services/helpers/pagination-helper.service';
import { EditCardValidationComponent } from './edit-card-validation/edit-card-validation.component';
import { CreateCardValidationComponent } from './create-card-validation/create-card-validation.component';
import { LoadingService } from '../../services/helpers/loading.service';

@Component({
  selector: 'aims-card-validation',
  templateUrl: './card-validation.component.html',
  styleUrls: ['./card-validation.component.scss'],
  standalone: false,
})
export class CardValidationComponent implements OnInit, AfterViewInit {
  @Input() showFullContent: boolean = true;
  @Input() showActions: boolean = true;
  @Input() airlineId?: number;
  @Input() showSearchBar: boolean = true;
  @Input() filteredCards: CardValidationDTO[] = [];

  dataSource = new MatTableDataSource<CardValidationDTO>([]);
  paginationModel: PaginationModel = new PaginationModel();
  loadingImages: boolean = true;

  filterForm!: FormGroup;
  airports: AirportDTO[] = [];
  fetchedLounges: LoungeDTO[] = [];
  fetchedWorkstations: WorkstationDTO[] = [];
  airlines: AirlineDTO[] = [];
  cards: CardDTO[] = [];

  filteredAirports: AirportDTO[] = [];
  filteredLounges: LoungeDTO[] = [];
  filteredWorkstations: WorkstationDTO[] = [];
  filteredAirlines: AirlineDTO[] = [];
  filteredCardsList: CardDTO[] = [];

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  airportSearchControl = new FormControl('');
  loungeSearchControl = new FormControl('');
  workstationSearchControl = new FormControl('');
  airlineSearchControl = new FormControl('');
  cardSearchControl = new FormControl('');

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private airportService: AirportService,
    private loungeService: LoungeService,
    private airlineService: AirlineService,
    private cardValidationService: CardValidationService,
    private paginationHelper: PaginationHelperService,
    private loadingService: LoadingService,
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    this.fetchAirports();
    this.fetchAirlines();
    if (this.airlineId && this.filteredCards.length > 0) {
      this.dataSource.data = this.filteredCards;
    } else {
      this.fetchCardValidations();
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  initializeForm(): void {
    this.filterForm = this.fb.group({
      airportId: [null],
      loungeId: [null],
      workstationId: [null],
      airlineId: [null],
      cardId: [null],
    });

    this.filterForm.get('airportId')?.valueChanges.subscribe((airportId) => {
      this.filterLounges(airportId);
    });

    this.filterForm.get('loungeId')?.valueChanges.subscribe((loungeId) => {
      this.filterWorkstations(loungeId);
    });

    this.filterForm.get('workstationId')?.valueChanges.subscribe(() => {
      this.filterCardValidations();
    });

    this.filterForm.get('airlineId')?.valueChanges.subscribe((airlineId) => {
      this.filterCards(airlineId);
      this.filterCardValidations();
    });

    this.filterForm.get('cardId')?.valueChanges.subscribe(() => {
      this.filterCardValidations();
    });
  }

  onSelectAirport(selectedAirport: AirportDTO): void {
    this.filterForm.get('airportId')?.setValue(selectedAirport.airportId);
  }

  onSelectLounge(selectedLounge: LoungeDTO): void {
    this.filterForm.get('loungeId')?.setValue(selectedLounge.loungeId);
    this.filterWorkstations(selectedLounge.loungeId);
  }

  onSelectWorkstation(selectedWorkstation: WorkstationDTO): void {
    this.filterForm
      .get('workstationId')
      ?.setValue(selectedWorkstation.workstationId);
  }

  onSelectAirline(selectedAirline: AirlineDTO): void {
    this.filterForm.get('airlineId')?.setValue(selectedAirline.airlineId);
    this.fetchCardsForAirline(selectedAirline.airlineId);
  }

  onSelectCard(selectedCard: CardDTO): void {
    this.filterForm.get('cardId')?.setValue(selectedCard.cardId);
  }

  displayAirportName(airport: AirportDTO): string {
    return airport ? airport.airportName : '';
  }

  displayLoungeName(lounge: LoungeDTO): string {
    return lounge ? lounge.loungeName : '';
  }

  displayWorkstationName(workstation: WorkstationDTO): string {
    return workstation ? workstation.workstationName : '';
  }

  displayAirlineName(airline: AirlineDTO): string {
    return airline ? airline.airlineName : '';
  }

  displayCardName(card: CardDTO): string {
    return card ? card.cardName : '';
  }

  fetchAirports(): void {
    this.airportService.getAirports().subscribe((response) => {
      this.airports = response?.data?.pageData || [];
      this.filteredAirports = [...this.airports];
    });
  }

  filterLounges(airportId: number): void {
    if (airportId) {
      this.loadingService.show();

      this.airportService.getAirportLounges(airportId).subscribe(
        (response: any) => {
          if (response.success && response.data) {
            this.fetchedLounges = response.data.filter(
              (lounge: LoungeDTO) => lounge.airportId === airportId,
            );
            this.filteredLounges = [...this.fetchedLounges];
          } else {
            this.filteredLounges = [];
          }
          this.filterForm.controls['loungeId'].setValue('');
          this.filterForm.controls['workstationId'].setValue('');
          this.filteredWorkstations = [];
        },
        (error) => {
          this.filteredLounges = [];
          this.filteredWorkstations = [];
          Swal.fire({
            title: 'Error Fetching Lounges',
            text: `Failed to fetch lounges for the selected airport: ${error.message}`,
            icon: 'error',
            confirmButtonColor: '#2893cc',
            confirmButtonText: 'OK',
          });
        },
        () => {
          this.loadingService.hide();
        },
      );
    } else {
      this.filteredLounges = [];
      this.filteredWorkstations = [];
    }
  }

  filterWorkstations(loungeId: number): void {
    if (loungeId) {
      this.loadingService.show();

      this.loungeService.getLoungeWorkstations(loungeId).subscribe(
        (response: any) => {
          if (response.success && response.data) {
            this.fetchedWorkstations = response.data.filter(
              (workstation: WorkstationDTO) =>
                workstation.loungeId === loungeId,
            );
            this.filteredWorkstations = [...this.fetchedWorkstations];
          } else {
            this.filteredWorkstations = [];
          }
          this.filterForm.controls['workstationId'].setValue('');
        },
        (error) => {
          this.filteredWorkstations = [];
          Swal.fire({
            title: 'Error Fetching Workstations',
            text: `Failed to fetch workstations for the selected lounge: ${error.message}`,
            icon: 'error',
            confirmButtonColor: '#2893cc',
            confirmButtonText: 'OK',
          });
        },
        () => {
          this.loadingService.hide();
        },
      );
    } else {
      this.filteredWorkstations = [];
    }
  }

  fetchAirlines(): void {
    this.airlineService.getAllAirlines().subscribe(
      (response) => {
        if (response && response.success && response.data) {
          this.airlines = response.data.pageData || [];
        } else {
          this.airlines = [];
        }
      },
      (error) => {
        this.airlineService.showError(
          'Error fetching airlines:',
          error.message,
        );
        this.airlines = [];
      },
    );
  }

  fetchCardsForAirline(airlineId: number): void {
    if (!airlineId) {
      this.filteredCardsList = [];
      return;
    }

    this.airlineService.getAirlineCards(airlineId).subscribe(
      (response: any) => {
        if (response && response.success && response.data) {
          this.cards = response.data;
          this.filteredCardsList = [...this.cards];
        } else {
          this.cards = [];
          this.filteredCardsList = [];
        }
      },
      (error) => {
        this.cards = [];
        this.filteredCardsList = [];
        Swal.fire({
          title: 'Error',
          text: `Failed to fetch cards: ${error.message}`,
          icon: 'error',
          confirmButtonColor: '#2893cc',
          confirmButtonText: 'OK',
        });
      },
    );
  }

  filterCards(airlineId: number): void {
    this.filteredCardsList = airlineId
      ? this.cards.filter((card) => card.airlineId === airlineId)
      : [];
    this.filterForm.controls['cardId'].setValue('');
  }

  filterCardValidations(): void {
    const workstationId = this.filterForm.get('workstationId')?.value;
    const cardId = this.filterForm.get('cardId')?.value;
    const airlineId = this.filterForm.get('airlineId')?.value;

    this.dataSource.data = this.filteredCards.filter(
      (cardValidation) =>
        (!workstationId || cardValidation.workstationId === workstationId) &&
        (!cardId || cardValidation.cardId === cardId) &&
        (!airlineId || cardValidation.airlineId === airlineId),
    );
  }

  fetchCardValidations(): void {
    const pageIndex = this.paginationHelper.pageIndex + 1;
    const pageSize = this.paginationHelper.pageSize;
    this.loadingImages = true;
    this.cardValidationService
      .getCardValidationsByPagination(pageIndex, pageSize)
      .pipe(
        tap((response: any) => {
          if (response && response.data) {
            this.filteredCards = response.data.pageData || [];
            this.dataSource.data = this.filteredCards;
            this.paginationModel.totalCount = response.data.totalCount;
          } else {
            this.filteredCards = [];
            this.dataSource.data = [];
          }
        }),
        finalize(() => (this.loadingImages = false)),
        catchError((error) => {
          this.cardValidationService.showError(
            'Error fetching card validations',
            error.message,
          );
          return of([]);
        }),
      )
      .subscribe();
  }

  clearLeftFilters(): void {
    this.filterForm.patchValue({
      airportId: null,
      loungeId: null,
      workstationId: null,
    });

    this.airportSearchControl.setValue('');
    this.loungeSearchControl.setValue('');
    this.workstationSearchControl.setValue('');

    this.filteredAirports = [];
    this.filteredLounges = [];
    this.filteredWorkstations = [];
  }

  clearMiddleFilters(): void {
    this.filterForm.patchValue({
      airlineId: null,
      cardId: null,
    });

    this.airlineSearchControl.setValue('');
    this.cardSearchControl.setValue('');

    this.filteredAirlines = [];
    this.filteredCardsList = [];
  }

  onPageChange(event: { pageIndex: number; pageSize: number }): void {
    this.paginationHelper.pageIndex = event.pageIndex;
    this.paginationHelper.pageSize = event.pageSize;
    this.fetchCardValidations();
  }

  addNewCardValidation(): void {
    const dialogRef = this.dialog.open(CreateCardValidationComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe((result: CardValidationDTO) => {
      if (result) {
        this.cardValidationService
          .addCardValidations([result])
          .pipe(
            catchError((error) => {
              this.cardValidationService.showError(
                'Error adding card validation',
                error.message,
              );
              return of(null);
            }),
          )
          .subscribe((newCardValidations) => {
            if (newCardValidations) {
              const data = this.dataSource.data;
              data.push(newCardValidations[0]);
              this.dataSource.data = data;
            }
          });
      }
    });
  }

  editCardValidation(cardValidation: CardValidationDTO): void {
    const dialogRef = this.dialog.open(EditCardValidationComponent, {
      data: cardValidation,
      width: '600px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.cardValidationService
          .updateCardValidations([result])
          .subscribe((updatedCardValidations) => {
            const index = this.dataSource.data.findIndex(
              (c) =>
                c.cardValidationId ===
                updatedCardValidations[0].cardValidationId,
            );
            if (index !== -1) {
              this.dataSource.data[index] = updatedCardValidations[0];
              this.dataSource._updateChangeSubscription();
            }
          });
      }
    });
  }

  deleteCardValidation(cardValidation: CardValidationDTO): void {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2893cc',
      cancelButtonColor: '#939597',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.cardValidationService
          .deleteCardValidation(cardValidation.cardValidationId)
          .pipe(
            catchError((error) => {
              this.cardValidationService.showError(
                'Error deleting card validation',
                error.message,
              );
              return of(null);
            }),
          )
          .subscribe(() => {
            this.dataSource.data = this.dataSource.data.filter(
              (c) => c.cardValidationId !== cardValidation.cardValidationId,
            );
            Swal.fire({
              title: 'Deleted!',
              text: 'The card validation has been deleted.',
              icon: 'success',
              confirmButtonColor: '#2893cc',
              confirmButtonText: 'OK',
            });
            this.refresh();
          });
      }
    });
  }

  redirectToCardValidationPrograms(): void {
    this.router.navigate(['/app/card-validation-programs']);
  }

  refresh() {
    this.fetchCardValidations();
  }

  redirectToCardValidationDetails(cardValidationId: number): void {
    this.router.navigate(['/app/card-validation', cardValidationId]);
  }
}
