import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { WorkstationService } from '../../../services/workstation.service';
import { CardValidationService } from '../../../services/card-validation.service';
import { WorkstationDTO } from '../../../models/dtos/workstationDTO';
import { CardDTO } from '../../../models/dtos/cardDTO';
import { AirlineDTO } from '../../../models/dtos/airlineDTO';
import { CardValidationProgramDTO } from '../../../models/dtos/cardValidationProgramDTO';
import { LoadingService } from '../../../services/helpers/loading.service';
import { CardValidationDTO } from '../../../models/dtos/cardValidationDTO';
import { catchError, map, tap } from 'rxjs/operators';
import { of, startWith } from 'rxjs';
import { PaginationModel } from '../../../models/pagination-model';
import { AirportDTO } from '../../../models/dtos/airportDTO';

@Component({
  selector: 'aims-edit-card-validation',
  templateUrl: './edit-card-validation.component.html',
  styleUrls: ['./edit-card-validation.component.scss'],
  standalone: false,
})
export class EditCardValidationComponent implements OnInit {
  paginationModel: PaginationModel = new PaginationModel();
  workstationSearchControl = new FormControl('');

  cardValidationForm!: FormGroup;
  workstations: WorkstationDTO[] = [];
  filteredWorkstations: WorkstationDTO[] = [];
  airlines: AirlineDTO[] = [];
  filteredCards: CardDTO[] = [];
  validationPrograms: CardValidationProgramDTO[] = [];
  isSubmitting = false;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditCardValidationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CardValidationDTO,
    private workstationService: WorkstationService,
    private cardValidationService: CardValidationService,
    private loadingService: LoadingService,
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    this.fetchWorkstations();
    this.setupWorkstationSearch();
    this.fetchAirlines(this.data.workstationId);
    this.fetchCards(this.data.workstationId, this.data.airlineId);
    this.fetchValidationPrograms();
  }

  initializeForm(): void {
    this.cardValidationForm = this.fb.group({
      workstationId: [this.data.workstationId, Validators.required],
      airlineId: [this.data.airlineId, Validators.required],
      cardId: [this.data.cardId, Validators.required],
      validationProgramId: [
        this.data.cardValidationProgram.cardValidationProgramId,
        Validators.required,
      ],
    });

    this.setupValueChangeHandlers();
  }

  setupValueChangeHandlers(): void {
    this.cardValidationForm
      .get('workstationId')
      ?.valueChanges.subscribe((workstationId) => {
        if (workstationId) {
          this.fetchAirlines(workstationId);
          this.cardValidationForm.controls['airlineId'].setValue('');
          this.filteredCards = [];
        }
      });

    this.cardValidationForm
      .get('airlineId')
      ?.valueChanges.subscribe((airlineId) => {
        const workstationId =
          this.cardValidationForm.get('workstationId')?.value;
        if (workstationId && airlineId) {
          this.fetchCards(workstationId, airlineId);
        }
      });
  }

  fetchWorkstations(): void {
    this.workstationService
      .getWorkstations()
      .pipe(
        tap((response) => {
          if (response && response.success && response.data) {
            this.workstations = response.data.pageData || [];
            this.filteredWorkstations = this.workstations;
          } else {
            this.workstations = [];
            this.filteredWorkstations = [];
          }
        }),
        catchError((error) => {
          this.workstationService.showError(
            'Error fetching workstations:',
            error.message,
          );
          return of([]);
        }),
      )
      .subscribe();
  }

  setupWorkstationSearch(): void {
    this.workstationSearchControl.valueChanges
      .pipe(
        startWith(''),
        map((value) => this.filterWorkstation(value || '')),
      )
      .subscribe((filtered) => (this.filteredWorkstations = filtered));
  }

  filterWorkstation(searchText: string): WorkstationDTO[] {
    const lowercaseSearch = searchText.toLowerCase();
    return this.workstations.filter((workstation) =>
      workstation.workstationName.toLowerCase().includes(lowercaseSearch),
    );
  }

  // onSelectWorkstation(event: any): void {
  //   const selectedWorkstation: WorkstationDTO = event.option.value;
  //   this.cardValidationForm.patchValue({
  //     workstationId : selectedWorkstation.workstationId,
  //   });
  //   this.workstationSearchControl.setValue(selectedWorkstation.workstationName, {
  //     emitEvent: false,
  //   });
  // }
  //
  // displayWorkstationName(workstation: WorkstationDTO): string {
  //   return workstation && workstation.workstationName ? workstation.workstationName : '';
  // }
  //
  getSelectedWorkstationName(): string {
    const selectedWorkstationId =
      this.cardValidationForm.get('workstationId')?.value;
    const selectedWorkstation = this.workstations.find(
      (workstation) => workstation.workstationId === selectedWorkstationId,
    );
    return selectedWorkstation ? selectedWorkstation.workstationName : '';
  }

  fetchAirlines(workstationId: number): void {
    this.workstationService
      .getExistingAirlines(workstationId)
      .pipe(
        tap((response: any) => {
          if (response && Array.isArray(response)) {
            this.airlines = response;
          } else if (
            response &&
            response.data &&
            Array.isArray(response.data)
          ) {
            this.airlines = response.data;
          } else {
            this.airlines = [];
          }
          if (this.airlines.length === 0) {
            // Show pop-up when no airlines are available
            Swal.fire({
              title: 'No Airlines Found',
              text: 'No airlines are available for the selected workstation. Please choose another workstation.',
              icon: 'warning',
              confirmButtonColor: '#2893cc',
              confirmButtonText: 'OK',
            }).then(() => {
              this.cardValidationForm.controls['workstationId'].setValue(''); // Clear the workstation selection
              this.airlines = []; // Clear airlines array
            });
          }
        }),
        catchError((error) => {
          this.workstationService.showError(
            'Error fetching airlines:',
            error.message,
          );
          return of([]);
        }),
      )
      .subscribe();
  }

  fetchCards(workstationId: number, airlineId: number): void {
    this.workstationService
      .getExistingAirlineCards(workstationId, airlineId)
      .pipe(
        tap((response: any) => {
          if (response && Array.isArray(response)) {
            this.filteredCards = response;
          } else if (
            response &&
            response.data &&
            Array.isArray(response.data)
          ) {
            this.filteredCards = response.data;
          } else {
            this.filteredCards = [];
          }
          if (this.filteredCards.length === 0) {
            // Show pop-up when no cards are available
            Swal.fire({
              title: 'No Cards Found',
              text: 'No access documents are available for the selected airline. Please choose another airline.',
              icon: 'warning',
              confirmButtonColor: '#2893cc',
              confirmButtonText: 'OK',
            }).then(() => {
              this.cardValidationForm.controls['airlineId'].setValue(''); // Clear the airline selection
              this.filteredCards = []; // Clear cards array
            });
          }
        }),
        catchError((error) => {
          this.workstationService.showError(
            'Error fetching cards:',
            error.message,
          );
          return of([]);
        }),
      )
      .subscribe();
  }

  fetchValidationPrograms(): void {
    this.cardValidationService
      .getCardValidationProgramsByPagination(1, 100)
      .pipe(
        tap((response) => {
          if (response && response.success && response.data) {
            this.validationPrograms = response.data.pageData.flat() || [];
          } else {
            this.validationPrograms = [];
          }
        }),
        catchError((error) => {
          this.cardValidationService.showError(
            'Error fetching validation programs:',
            error.message,
          );
          return of([]);
        }),
      )
      .subscribe();
  }

  onSubmit(): void {
    if (this.cardValidationForm.valid) {
      const formValues = this.cardValidationForm.value;

      const updatedCardValidation: CardValidationDTO = {
        ...this.data,
        cardId: formValues.cardId,
        workstationId: formValues.workstationId,
        airlineId: formValues.airlineId,
        cardValidationProgram: {
          cardValidationProgramId: formValues.validationProgramId,
          validationProgram: '',
        },
      };

      this.loadingService.show();

      this.cardValidationService
        .updateCardValidations([updatedCardValidation])
        .subscribe(
          (response) => {
            this.loadingService.hide();
            Swal.fire({
              title: 'Success!',
              text: 'Card validation updated successfully.',
              icon: 'success',
              confirmButtonColor: '#2893cc',
              confirmButtonText: 'OK',
            }).then(() => {
              this.dialogRef.close(response);
              window.location.reload();
            });
          },
          (error) => {
            this.loadingService.hide();
            Swal.fire({
              title: 'Error!',
              text: `Error updating card validation: ${error.message}`,
              icon: 'error',
              confirmButtonColor: '#2893cc',
              confirmButtonText: 'OK',
            });
          },
        );
    } else {
      this.cardValidationService.showError(
        'Error!',
        'Please correct the errors in the form before submitting.',
      );
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  getWorkstationName(workstationId: number): string {
    const workstation = this.workstations.find(
      (w) => w.workstationId === workstationId,
    );
    return workstation ? workstation.workstationName : '';
  }

  getAirlineName(airlineId: number): string {
    const airline = this.airlines.find((a) => a.airlineId === airlineId);
    return airline ? airline.airlineName : '';
  }

  getCardName(cardId: number): string {
    const card = this.filteredCards.find((c) => c.cardId === cardId);
    return card ? card.cardName : '';
  }

  getValidationProgramName(validationProgramId: number): string {
    const program = this.validationPrograms.find(
      (p) => p.cardValidationProgramId === validationProgramId,
    );
    return program ? program.validationProgram : 'None';
  }

  onSelectWorkstation(event: any): void {
    const selectedWorkstation = event.option.value;
    this.cardValidationForm
      .get('workstationId')
      ?.setValue(selectedWorkstation.workstationId);
  }

  displayWorkstationName(workstation: WorkstationDTO): string {
    return workstation ? workstation.workstationName : '';
  }

  showAllWorkstations(): void {
    this.filteredWorkstations = [...this.workstations];
  }
}
