import { Injectable } from '@angular/core';
import { PaginationModel } from '../../models/pagination-model';

@Injectable({
  providedIn: 'root',
})
export class PaginationHelperService {
  private paginationModel: PaginationModel;

  constructor() {
    this.paginationModel = new PaginationModel();
  }

  get pageIndex(): number {
    return this.paginationModel.pageIndex;
  }

  get pageSizeOptions(): number[] {
    return this.paginationModel.pageSizeOptions;
  }

  get pageSize(): number {
    return this.paginationModel.pageSize;
  }

  set pageIndex(index: number) {
    this.paginationModel.pageIndex = index;
  }

  set pageSize(size: number) {
    this.paginationModel.pageSize = size;
  }

  change(pageEvent: PaginationModel): void {
    this.pageIndex = pageEvent.pageIndex;
    this.pageSize = pageEvent.pageSize;
  }
}
