<form
  [formGroup]="form"
  class="search-bar-component-form"
  [style.margin]="noMargin ? '0 0' : '0 30px'"
>
  <ng-container formGroupName="search">
    <mat-form-field class="search-form-field">
      <mat-label>Search...</mat-label>
      <button mat-icon-button matPrefix>
        <mat-icon>search</mat-icon>&nbsp;&nbsp;
      </button>
      <input matInput formControlName="terms" />
      <button mat-icon-button matSuffix *ngIf="hasValue" (click)="reset()">
        &nbsp; &nbsp; <mat-icon>clear</mat-icon>
      </button>
    </mat-form-field>
  </ng-container>
</form>
