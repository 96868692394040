import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  Input,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2';

import { WorkstationDTO } from '../../models/dtos/workstationDTO';
import { WorkstationService } from '../../services/workstation.service';
import { LoungeService } from '../../services/lounge.service';
import { AirportService } from '../../services/airport.service';
import { CreateWorkstationComponent } from './create-workstation/create-workstation.component';
import { EditWorkstationComponent } from './edit-workstation/edit-workstation.component';
import { PaginationModel } from '../../models/pagination-model';
import { LoungeDTO } from '../../models/dtos/loungeDTO';
import { SearchBarComponent } from '../../layout/search-bar/search-bar.component';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: 'aims-workstations',
  templateUrl: './workstations.component.html',
  styleUrls: ['./workstations.component.scss'],
  standalone: false,
})
export class WorkstationsComponent implements OnInit, AfterViewInit {
  @Input() loungeId?: number;
  @Input() showFullContent: boolean = true;
  @Input() showActions: boolean = true;
  @Input() showSearchBar: boolean = true;
  @Input() filteredWorkstations: WorkstationDTO[] = [];

  dataSource = new MatTableDataSource<WorkstationDTO>([]);
  paginationModel: PaginationModel = new PaginationModel();
  loungeMap: Map<number, string> = new Map();
  airportMap: Map<number, string> = new Map();

  searchTerm: string = '';
  noResultsMessage: string = '';
  popupShown: boolean = false;

  @ViewChild(SearchBarComponent) searchBarComponent!: SearchBarComponent;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private workstationService: WorkstationService,
    private loungeService: LoungeService,
    private airportService: AirportService,
  ) {}

  ngOnInit(): void {
    if (this.loungeId && this.filteredWorkstations.length > 0) {
      this.dataSource.data = this.filteredWorkstations;
    } else {
      this.fetchLounges();
      this.fetchAirports();
      this.fetchWorkstations();
      this.setupDynamicSearch();
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  setupDynamicSearch(): void {
    this.route.queryParams
      .pipe(
        debounceTime(300),
        distinctUntilChanged(
          (prev, curr) => prev['search.terms'] === curr['search.terms'],
        ),
      )
      .subscribe((params) => {
        const term = params['search.terms'] || '';
        if (term.length >= 3) {
          this.searchWorkstations(term);
        } else {
          this.fetchAllOnClear();
        }
      });
  }

  fetchAllOnClear(): void {
    this.searchTerm = '';
    this.router
      .navigate([], {
        queryParams: { 'search.terms': null, 'page.index': 0 },
        replaceUrl: true,
      })
      .then(() => {
        this.fetchWorkstations();
      });
  }

  searchWorkstations(term: string): void {
    this.noResultsMessage = '';
    this.popupShown = false;

    this.workstationService
      .searchWorkstationsByKeyword(term)
      .pipe(
        tap((response: any) => {
          this.dataSource.data = response?.data || [];
          this.paginationModel.totalCount = response?.data?.length || 0;

          if (this.dataSource.data.length === 0 && !this.popupShown) {
            this.noResultsMessage =
              'No workstation found for this search term.';
            this.popupShown = true;

            Swal.fire({
              title: 'No Results',
              text: this.noResultsMessage,
              icon: 'info',
              confirmButtonText: 'OK',
              confirmButtonColor: '#2893cc',
            }).then(() => {
              this.refresh();
            });
          }
        }),
      )
      .subscribe(
        () => this.dataSource._updateChangeSubscription(),
        (error) => {
          this.handleFetchError('searching lounges', error);
        },
      );
  }

  fetchLounges(): void {
    this.loungeService.getLounges().subscribe(
      (response) => {
        if (response.data?.pageData) {
          response.data.pageData.forEach((lounge: LoungeDTO) => {
            this.loungeMap.set(lounge.loungeId, lounge.loungeName);
          });
        }
      },
      (error) => {
        this.loungeService.showError(
          'Could not fetch lounges.',
          error.error.message,
        );
      },
    );
  }

  fetchAirports(): void {
    this.airportService.getAirports().subscribe(
      (response) => {
        if (response.data?.pageData) {
          response.data.pageData.forEach((airport) => {
            this.airportMap.set(airport.airportId, airport.airportCode);
          });
        }
      },
      (error) => {
        this.airportService.showError(
          'Could not fetch Airports.',
          error.error.message,
        );
      },
    );
  }

  fetchWorkstations(): void {
    const pageIndex = this.paginationModel.pageIndex + 1;
    const pageSize = this.paginationModel.pageSize;

    this.noResultsMessage = '';
    this.popupShown = false;

    this.workstationService.getWorkstations(pageIndex, pageSize).subscribe(
      (response) => {
        if (response.data) {
          this.dataSource.data = response.data.pageData || [];
          this.paginationModel.totalCount = response.data.totalCount || 0;
          if (this.loungeId) {
            this.dataSource.data = this.dataSource.data.filter(
              (workstation) => workstation.loungeId === this.loungeId,
            );
          }
          if (this.dataSource.data.length === 0) {
            this.noResultsMessage = 'No workstations are available.';
            Swal.fire({
              title: 'No Workstations Found',
              text: 'There are no workstations available for this lounge.',
              icon: 'info',
              confirmButtonColor: '#2893cc',
              confirmButtonText: 'OK',
            });
          }
        }
      },
      (error) => {
        this.workstationService.showError(
          'Failed to load workstations.',
          error.error.message,
        );
      },
    );
  }

  handleFetchError(action: string, error: any): void {
    Swal.fire({
      title: `Error ${action}`,
      text: `There was an error: ${error.message}`,
      icon: 'error',
      confirmButtonText: 'OK',
      confirmButtonColor: '#2893cc',
    });
  }

  onSearchResults(data: {
    results: WorkstationDTO[];
    searchTerm: string;
  }): void {
    this.searchTerm = data.searchTerm;
    this.dataSource.data = data.results;
    this.paginationModel.totalCount = this.dataSource.data.length;
    this.noResultsMessage =
      this.dataSource.data.length === 0
        ? 'No workstations found for this term.'
        : '';

    this.updateQueryParams();

    if (this.dataSource.data.length === 0) {
      Swal.fire({
        title: 'No Results',
        text: this.noResultsMessage,
        icon: 'info',
        confirmButtonText: 'OK',
        confirmButtonColor: '#2893cc',
      }).then(() => {
        this.refresh();
      });
    }
  }

  updateQueryParams(): void {
    this.router.navigate([], {
      queryParams: { 'search.terms': this.searchTerm || null, 'page.index': 0 },
      replaceUrl: true,
    });
  }

  onPageChange(event: { pageIndex: number; pageSize: number }): void {
    this.paginationModel.pageIndex = event.pageIndex;
    this.paginationModel.pageSize = event.pageSize;
    this.fetchWorkstations();
  }

  onReset(): void {
    this.searchTerm = '';
    this.refresh();
  }

  refresh(): void {
    this.searchTerm = '';
    this.noResultsMessage = '';
    this.paginationModel.pageIndex = 0;

    this.router
      .navigate([], {
        queryParams: { 'search.terms': null, 'page.index': 0 },
        replaceUrl: true,
      })
      .then(() => {
        this.fetchWorkstations();
        this.searchBarComponent.clearSearch();
      });
  }

  addNew(): void {
    const dialogRef = this.dialog.open(CreateWorkstationComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe((result: WorkstationDTO) => {
      if (result) {
        this.workstationService
          .addWorkstation(result)
          .subscribe((newWorkstation) => {
            const data = this.dataSource.data;
            data.push(newWorkstation);
            this.dataSource.data = data;
          });
      }
    });
  }

  openEditDialog(workstation: WorkstationDTO): void {
    const dialogRef = this.dialog.open(EditWorkstationComponent, {
      data: workstation,
      width: '600px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.workstationService
          .updateWorkstation(result)
          .subscribe((updatedWorkstation) => {
            const index = this.dataSource.data.findIndex(
              (p) => p.workstationId === updatedWorkstation.workstationId,
            );
            if (index !== -1) {
              this.dataSource.data[index] = updatedWorkstation;
              this.dataSource._updateChangeSubscription();
            }
          });
      }
    });
  }

  deleteWorkstation(workstation: WorkstationDTO): void {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2893cc',
      cancelButtonColor: '#939597',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.workstationService
          .removeWorkstation(workstation.workstationId)
          .subscribe(
            () => {
              Swal.fire({
                title: 'Deleted!',
                text: 'The workstation has been deleted.',
                icon: 'success',
                confirmButtonColor: '#2893cc',
                confirmButtonText: 'OK',
              }).then(() => {
                this.fetchWorkstations();
              });
            },
            (error) => {
              this.workstationService.showError(
                'Failed to delete workstation. Please try again.',
                error.error.message,
              );
            },
          );
      }
    });
  }

  getLoungeName(loungeId: number): string {
    return this.loungeMap.get(loungeId) || 'Unknown';
  }

  getAirportCode(airportId: number): string {
    return this.airportMap.get(airportId) || 'Unknown';
  }

  getDisplayedColumns(): string[] {
    const displayedColumns = [
      'workstationName',
      'workstationId',
      'loungeName',
      'loungeId',
      'airportCode',
      'airportId',
    ];

    if (this.showActions) {
      displayedColumns.push('action');
    }

    return displayedColumns;
  }

  redirectToWorkstationDetails(workstationId: number): void {
    this.router.navigate(['/app/workstations', workstationId]);
  }
}
