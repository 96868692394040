import { Component, OnInit } from '@angular/core';
import { LoungeDTO } from '../../../models/dtos/loungeDTO';
import { LoungeService } from '../../../services/lounge.service';
import { MatDialogRef } from '@angular/material/dialog';
import { catchError, map, tap } from 'rxjs/operators';
import { of, startWith } from 'rxjs';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'aims-select-lounge-dialog',
  standalone: false,

  templateUrl: './select-lounge-dialog.component.html',
  styleUrl: './select-lounge-dialog.component.scss',
})
export class SelectLoungeDialogComponent implements OnInit {
  loungeSearchControl = new FormControl('');
  loungeOptions: LoungeDTO[] = [];
  filteredLounges: LoungeDTO[] = [];
  selectedLounges: LoungeDTO[] = [];
  errorMessage: string = '';

  constructor(
    private loungeService: LoungeService,
    private dialogRef: MatDialogRef<SelectLoungeDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.fetchLounges();

    this.loungeSearchControl.valueChanges
      .pipe(
        startWith(''),
        map((value) => this.filterLounges(value || '')),
      )
      .subscribe((filtered) => (this.filteredLounges = filtered));
  }

  fetchLounges(): void {
    this.loungeService
      .getLounges()
      .pipe(
        tap((response) => {
          if (response && response.success && response.data) {
            this.loungeOptions = response.data.pageData || [];
            this.filteredLounges = [...this.loungeOptions];
          } else {
            this.loungeOptions = [];
            this.filteredLounges = [];
          }
        }),
        catchError((error) => {
          this.errorMessage = 'Error fetching lounges';
          return of([]);
        }),
      )
      .subscribe();
  }

  filterLounges(searchText: string): LoungeDTO[] {
    const lowercaseSearch = searchText.toLowerCase();
    return this.loungeOptions.filter((lounge) =>
      lounge.loungeName.toLowerCase().includes(lowercaseSearch),
    );
  }

  onSelectLounge(event: any): void {
    const selectedLounge = event.option.value;

    if (
      !this.selectedLounges.some((l) => l.loungeId === selectedLounge.loungeId)
    ) {
      this.selectedLounges.push(selectedLounge);
    }

    this.loungeSearchControl.setValue('');
  }

  removeLounge(lounge: LoungeDTO): void {
    this.selectedLounges = this.selectedLounges.filter(
      (l) => l.loungeId !== lounge.loungeId,
    );
  }

  displayLoungeName(lounge: LoungeDTO): string {
    return lounge ? lounge.loungeName : '';
  }

  showAllLounges(): void {
    this.filteredLounges = [...this.loungeOptions];
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSelect(): void {
    this.dialogRef.close(this.selectedLounges);
  }
}
