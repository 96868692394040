<div class="program-container">
  <div class="header-container">
    <button
      mat-icon-button
      class="back-button"
      (click)="navigateToCardValidation()"
    >
      <mat-icon>arrow_back</mat-icon>
    </button>
    <div class="header-content">
      <h2 class="header-title">Card Validation Programs</h2>
      <p class="description">
        List of card validation programs with key information and actions.
      </p>
    </div>
  </div>

  <aims-layout-toolbar>
    <button mat-icon-button (click)="addNewCardValidationProgram()">
      <mat-icon>add</mat-icon>
    </button>
    <button mat-icon-button (click)="refresh()">
      <mat-icon>refresh</mat-icon>
    </button>
  </aims-layout-toolbar>

  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <!-- Program Name Column -->
      <ng-container matColumnDef="validationProgram">
        <th mat-header-cell *matHeaderCellDef>Program Name</th>
        <td mat-cell *matCellDef="let program">
          {{ program.validationProgram }}
        </td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let program">
          <button
            mat-icon-button
            (click)="
              editCardValidationProgram(program); $event.stopPropagation()
            "
            aria-label="Edit card validation"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="deleteProgram(program)"
            aria-label="Delete Program"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Header and Rows -->
      <tr
        mat-header-row
        *matHeaderRowDef="['validationProgram', 'action']"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['validationProgram', 'action']"
      ></tr>
    </table>
  </div>

  <!-- Paginator -->
  <mat-paginator
    [length]="paginationModel.totalCount"
    [pageSize]="paginationModel.pageSize"
    [pageSizeOptions]="[5, 10, 25, 100]"
    (page)="onPageChange($event)"
  >
  </mat-paginator>
</div>
