<div class="layout-toolbar">
  <div class="left">
    <ng-content select="[side='left']"></ng-content>
  </div>
  <div class="center">
    <ng-content></ng-content>
  </div>
  <div class="right">
    <ng-content select="[side='right']"></ng-content>
  </div>
</div>
