<div class="workstations-container">
  <ng-container *ngIf="showFullContent">
    <div class="header-container">
      <div class="header-content">
        <h2 class="header-title">Workstations</h2>
        <p class="description">
          List of workstations and their corresponding lounge and airport
          details.
        </p>
      </div>
    </div>

    <aims-layout-toolbar>
      <button mat-icon-button (click)="addNew()">
        <mat-icon>add</mat-icon>
      </button>
      <button mat-icon-button (click)="refresh()">
        <mat-icon>refresh</mat-icon>
      </button>
    </aims-layout-toolbar>

    <aims-query-layout>
      <aims-search-bar
        left
        componentName="workstations"
        matTooltip="Enter at least 3 characters for search"
        matTooltipPosition="above"
        (termsChanged$)="onSearchResults($event)"
        (reset$)="onReset()"
        (clearTerms$)="fetchAllOnClear()"
      ></aims-search-bar>
    </aims-query-layout>
  </ng-container>

  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="workstationName">
        <th mat-header-cell *matHeaderCellDef>Workstation Name</th>
        <td mat-cell *matCellDef="let workstation">
          {{ workstation.workstationName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="workstationId">
        <th mat-header-cell *matHeaderCellDef>Workstation ID</th>
        <td mat-cell *matCellDef="let workstation">
          {{ workstation.workstationId }}
        </td>
      </ng-container>

      <ng-container matColumnDef="loungeName">
        <th mat-header-cell *matHeaderCellDef>Lounge Name</th>
        <td mat-cell *matCellDef="let workstation">
          {{ getLoungeName(workstation.loungeId) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="loungeId">
        <th mat-header-cell *matHeaderCellDef>Lounge ID</th>
        <td mat-cell *matCellDef="let workstation">
          {{ workstation.loungeId }}
        </td>
      </ng-container>

      <ng-container matColumnDef="airportCode">
        <th mat-header-cell *matHeaderCellDef>Airport Code</th>
        <td mat-cell *matCellDef="let workstation">
          {{ getAirportCode(workstation.airportId) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="airportId">
        <th mat-header-cell *matHeaderCellDef>Airport ID</th>
        <td mat-cell *matCellDef="let workstation">
          {{ workstation.airportId }}
        </td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let workstation">
          <button
            mat-icon-button
            (click)="openEditDialog(workstation); $event.stopPropagation()"
            aria-label="Edit workstation"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="deleteWorkstation(workstation); $event.stopPropagation()"
            aria-label="Delete workstation"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Row Definitions -->
      <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: getDisplayedColumns()"
        (click)="redirectToWorkstationDetails(row.workstationId)"
      ></tr>
    </table>
  </div>

  <div class="paginator-container">
    <aims-query-page
      [paginationModel]="paginationModel"
      (pageChange)="onPageChange($event)"
    ></aims-query-page>
  </div>
</div>
