import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { BaseService } from './helpers/base.service';
import { ConfigService } from './helpers/config.service';

@Injectable({
  providedIn: 'root',
})
export class FilesService extends BaseService {
  private apiUrl: string;
  private cache = new Map<string, Observable<string>>();

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
  ) {
    super();
    this.apiUrl = this.configService.getApiUrl() + '/Files';
  }

  getFileUri(filename: string): Observable<string> {
    const cachedObservable = this.cache.get(filename);
    if (cachedObservable) {
      return cachedObservable;
    }

    const observable = this.http
      .get<{
        data: string;
        message: string;
        success: boolean;
      }>(`${this.apiUrl}/GetUri?filename=${filename}`)
      .pipe(
        map((response) => {
          if (response.success && response.data) {
            return response.data;
          } else {
            throw new Error('File not found or access denied');
          }
        }),
        catchError(this.handleError.bind(this)),
        shareReplay(1),
      );

    this.cache.set(filename, observable);
    return observable;
  }

  async uploadFile(file: FormData): Promise<any> {
    try {
      await file.append('filename', 'name');
      const response = await this.http
        .post<any>(`${this.apiUrl}/Upload`, file)
        .toPromise();
      return response;
    } catch (error) {
      return this.handleError(error);
    }
  }

  async uploadAirlineOrCardImageFile(file: FormData): Promise<any> {
    try {
      const response = await this.http
        .post<any>(`${this.apiUrl}/UploadAirlineOrCardImage`, file)
        .toPromise();
      return response;
    } catch (error) {
      return this.handleError(error);
    }
  }

  async compressAndUploadAirlineOrCardImage(file: FormData): Promise<any> {
    try {
      const response = await this.http
        .post<any>(`${this.apiUrl}/CompressAndUploadAirlineOrCardImage`, file)
        .toPromise();
      return response;
    } catch (error) {
      return this.handleError(error);
    }
  }

  deleteFile(filename: string): Observable<any> {
    return this.http
      .delete<any>(`${this.apiUrl}/Delete?filename=${filename}`)
      .pipe(catchError(this.handleError.bind(this)));
  }
}
