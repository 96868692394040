<h2 mat-dialog-title>Select Lounge(s)</h2>
<mat-dialog-content>
  <!-- Search Input -->
  <mat-form-field appearance="fill">
    <mat-label>Search Lounge</mat-label>
    <input
      type="text"
      matInput
      [formControl]="loungeSearchControl"
      [matAutocomplete]="auto"
      placeholder="Type to search lounges"
      autocomplete="off"
      (focus)="showAllLounges()"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayLoungeName"
      (optionSelected)="onSelectLounge($event)"
    >
      <ng-container *ngIf="filteredLounges.length > 0; else noData">
        <mat-option *ngFor="let lounge of filteredLounges" [value]="lounge">
          {{ lounge.loungeName }}
        </mat-option>
      </ng-container>

      <ng-template #noData>
        <mat-option disabled>No lounges available</mat-option>
      </ng-template>
    </mat-autocomplete>
  </mat-form-field>

  <!-- Selected Lounges List -->
  <div class="selected-lounges" *ngIf="selectedLounges.length > 0">
    <h3>Selected Lounges</h3>
    <div class="lounge-item" *ngFor="let lounge of selectedLounges">
      <span>{{ lounge.loungeName }}</span>
      <button
        mat-icon-button
        (click)="removeLounge(lounge)"
        aria-label="Remove Lounge"
      >
        <mat-icon>cancel</mat-icon>
      </button>
    </div>
  </div>

  <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-raised-button color="primary" (click)="onSelect()">Select</button>
</mat-dialog-actions>
