<div class="dialog-container">
  <!-- Loading Component -->
  <aims-loading></aims-loading>

  <button mat-icon-button class="close-button" (click)="onCancel()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-vertical-stepper [linear]="true" #stepper>
    <!-- Step 1: Airport Information -->
    <mat-step [stepControl]="loungeForm.controls['airportId']">
      <ng-template matStepLabel>Airport Information</ng-template>
      <form [formGroup]="loungeForm">
        <div class="input-group">
          <mat-form-field appearance="fill">
            <mat-label>Search or Select Airport</mat-label>
            <input
              type="text"
              matInput
              [value]="getSelectedAirportName()"
              [formControl]="airportSearchControl"
              [matAutocomplete]="auto"
              placeholder="Search Airport"
              autocomplete="off"
            />
            <mat-autocomplete
              #auto="matAutocomplete"
              [displayWith]="displayAirportName"
              (optionSelected)="onSelectAirport($event)"
              s
            >
              <ng-container *ngIf="filteredAirports.length > 0; else noData">
                <mat-option
                  *ngFor="let airport of filteredAirports"
                  [value]="airport"
                >
                  {{ airport.airportName }}
                </mat-option>
              </ng-container>

              <ng-template #noData>
                <mat-option disabled>No airport found</mat-option>
              </ng-template>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <p><strong>Selected Airport Code:</strong> {{ airportCode }}</p>

        <button mat-raised-button color="primary" matStepperNext>Next</button>
      </form>
    </mat-step>

    <!-- Step 2: Lounge Details -->
    <mat-step [stepControl]="loungeForm.controls['loungeName']">
      <ng-template matStepLabel>Lounge Details</ng-template>
      <form [formGroup]="loungeForm">
        <div class="input-group">
          <mat-form-field appearance="fill">
            <mat-label>Lounge Name</mat-label>
            <input matInput formControlName="loungeName" type="text" />
          </mat-form-field>
        </div>
        <div class="input-group">
          <mat-form-field appearance="fill">
            <mat-label>Max Occupancy</mat-label>
            <input
              matInput
              formControlName="maxOccupancy"
              type="number"
              min="0"
            />
          </mat-form-field>
        </div>
        <button mat-raised-button color="warn" matStepperPrevious>Back</button>
        <button mat-raised-button color="primary" matStepperNext>Next</button>
      </form>
    </mat-step>

    <!-- Step 3: Review and Submit -->
    <mat-step>
      <ng-template matStepLabel>Review and Submit</ng-template>
      <div class="review-section">
        <h3>Review Lounge Details</h3>
        <p>
          <strong>Airport:</strong>
          {{ getSelectedAirportName() }}
        </p>
        <p>
          <strong>Lounge Name:</strong>
          {{ loungeForm.get("loungeName")?.value }}
        </p>
        <p>
          <strong>Max Occupancy:</strong>
          {{ loungeForm.get("maxOccupancy")?.value }}
        </p>
      </div>
      <div class="dialog-actions">
        <button mat-raised-button color="warn" matStepperPrevious>Back</button>
        <button
          mat-raised-button
          color="primary"
          [disabled]="isSubmitting"
          (click)="onSubmit()"
        >
          Submit
        </button>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</div>
