import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { BaseService } from './helpers/base.service';
import { ConfigService } from './helpers/config.service';
import { ResponseDTO } from '../models/dtos/responseDTO';
import { CardValidationDTO } from '../models/dtos/cardValidationDTO';
import { CardValidationProgramDTO } from '../models/dtos/cardValidationProgramDTO';
import { LoadingService } from './helpers/loading.service';

@Injectable({
  providedIn: 'root',
})
export class CardValidationService extends BaseService {
  private apiUrl: string;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private loadingService: LoadingService,
  ) {
    super();
    this.apiUrl = this.configService.getApiUrl() + '/CardValidations';
  }

  getCardValidationsByPagination(
    index: number = 1,
    size: number = 10,
  ): Observable<ResponseDTO<CardValidationDTO[]>> {
    const params = new HttpParams()
      .set('Index', index.toString())
      .set('Size', size.toString());
    this.loadingService.setLoading(true);

    return this.http
      .get<ResponseDTO<CardValidationDTO[]>>(`${this.apiUrl}/GetAll`, {
        params,
      })
      .pipe(
        map((response) => response),
        catchError(this.handleError.bind(this)),
        tap(() => this.loadingService.setLoading(false)),
      );
  }

  getCardValidationById(
    cardValidationId: number,
  ): Observable<CardValidationDTO> {
    this.loadingService.setLoading(true);

    return this.http
      .get<any>(`${this.apiUrl}/GetById/${cardValidationId}`)
      .pipe(
        map((response: any) => response.data as CardValidationDTO),
        catchError(this.handleError.bind(this)),
        tap(() => this.loadingService.setLoading(false)),
      );
  }

  getCardValidationsByWorkstationId(
    workstationId: number,
  ): Observable<CardValidationDTO[]> {
    this.loadingService.setLoading(true);

    return this.http
      .get<any>(`${this.apiUrl}/GetByWorkstation/${workstationId}`)
      .pipe(
        map((response: any) => response.data as CardValidationDTO[]),
        catchError(this.handleError.bind(this)),
        tap(() => this.loadingService.setLoading(false)),
      );
  }

  getCardValidationsByWorkstationAndCardIds(
    workstationId: number,
    cardId: number,
  ): Observable<CardValidationDTO[]> {
    const params = new HttpParams()
      .set('workstationId', workstationId.toString())
      .set('cardId', cardId.toString());

    this.loadingService.setLoading(true);

    return this.http
      .get<any>(`${this.apiUrl}/GetByWorkstationAndCard`, { params })
      .pipe(
        map((response: any) => response.data as CardValidationDTO[]),
        catchError(this.handleError.bind(this)),
        tap(() => this.loadingService.setLoading(false)),
      );
  }

  addCardValidations(
    cardValidations: CardValidationDTO[],
  ): Observable<CardValidationDTO[]> {
    return this.http.post<any>(`${this.apiUrl}/Add`, cardValidations).pipe(
      map((response: any) => response.data as CardValidationDTO[]),
      catchError(this.handleError.bind(this)),
    );
  }

  updateCardValidations(
    cardValidations: CardValidationDTO[],
  ): Observable<CardValidationDTO[]> {
    return this.http.patch<any>(`${this.apiUrl}/Update`, cardValidations).pipe(
      map((response: any) => response.data as CardValidationDTO[]),
      catchError(this.handleError.bind(this)),
    );
  }

  deleteCardValidation(cardValidationId: number): Observable<any> {
    return this.http
      .delete(`${this.apiUrl}/Delete/${cardValidationId}`)
      .pipe(catchError(this.handleError.bind(this)));
  }

  deleteCardValidations(cardValidationIds: number[]): Observable<any> {
    return this.http
      .delete(`${this.apiUrl}/DeleteAll`, { body: cardValidationIds })
      .pipe(catchError(this.handleError.bind(this)));
  }

  getCardValidationProgramsByPagination(
    index: number = 1,
    size: number = 10,
  ): Observable<ResponseDTO<CardValidationProgramDTO[]>> {
    const params = new HttpParams()
      .set('Index', index.toString())
      .set('Size', size.toString());
    this.loadingService.setLoading(true);

    return this.http
      .get<
        ResponseDTO<CardValidationProgramDTO[]>
      >(`${this.apiUrl}/GetAllPrograms`, { params })
      .pipe(
        map((response: any) => {
          const programs: CardValidationProgramDTO[] =
            response.data?.pageData || [];
          return {
            ...response,
            data: { ...response.data, pageData: programs },
          };
        }),
        catchError(this.handleError.bind(this)),
        tap(() => this.loadingService.setLoading(false)),
      );
  }

  getCardValidationProgramById(
    cardValidationProgramId: number,
  ): Observable<CardValidationProgramDTO> {
    return this.http
      .get<any>(`${this.apiUrl}/GetProgramById/${cardValidationProgramId}`)
      .pipe(
        map((response: any) => response.data as CardValidationProgramDTO),
        catchError(this.handleError.bind(this)),
      );
  }

  addCardValidationProgram(
    cardValidationProgram: CardValidationProgramDTO,
  ): Observable<CardValidationProgramDTO> {
    return this.http
      .post<any>(`${this.apiUrl}/AddProgram`, cardValidationProgram)
      .pipe(
        map((response: any) => response.data as CardValidationProgramDTO),
        catchError(this.handleError.bind(this)),
      );
  }

  updateCardValidationProgram(
    cardValidationProgram: CardValidationProgramDTO,
  ): Observable<CardValidationProgramDTO> {
    return this.http
      .patch<any>(`${this.apiUrl}/UpdateProgram`, cardValidationProgram)
      .pipe(
        map((response: any) => response.data as CardValidationProgramDTO),
        catchError(this.handleError.bind(this)),
      );
  }

  deleteCardValidationProgram(
    cardValidationProgramId: number,
  ): Observable<any> {
    return this.http
      .delete(`${this.apiUrl}/DeleteProgram/${cardValidationProgramId}`)
      .pipe(catchError(this.handleError.bind(this)));
  }
}
