import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/helpers/auth.service';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'aims-login',
  standalone: false,

  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  isAuthenticated = false;

  constructor(
    public authService: AuthService,
    private router: Router,
    private msalService: MsalService,
  ) {}

  ngOnInit(): void {
    this.checkAuthState();
  }

  private async checkAuthState() {
    await this.msalService.handleRedirectObservable().toPromise();

    if (this.authService.getAccount()) {
      this.router.navigate(['/app']);
    }
  }

  login(): void {
    this.authService.login();
  }
}
