<aims-header *ngIf="showHeader" (toggleSidebar)="toggleSidebar()"></aims-header>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    *ngIf="showSidebar"
    #sidenav
    mode="side"
    opened="true"
    class="sidenav"
  >
    <aims-side-bar></aims-side-bar>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="content-wrapper">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
