<div class="sidebar">
  <mat-tab-group>
    <!-- Airlines Tab -->
    <mat-tab label="Airlines" *ngIf="editMode">
      <div
        class="tab-content"
        cdkDropList
        id="airlineSidebar"
        [cdkDropListData]="airlines"
        [cdkDropListConnectedTo]="connectedDropLists"
        [cdkDropListSortingDisabled]="true"
      >
        <ng-container *ngIf="!loadingAirlines; else loadingAirlinesTemplate">
          <div
            class="airline-card"
            *ngFor="let airline of airlines"
            cdkDrag
            [cdkDragData]="airline"
            (cdkDragStarted)="onDragStarted($event)"
            (cdkDragEnded)="onDragEnded($event)"
          >
            <div class="card-content">
              <aims-alliance-logos
                [allianceImageHandles]="airline.allianceImageHandles"
              >
              </aims-alliance-logos>
              <div
                class="airline-image"
                [style.backgroundImage]="'url(' + airline.imageUrl + ')'"
              ></div>
              <span class="airline-code">{{ airline.airlineCode }}</span>
            </div>
          </div>
        </ng-container>
        <ng-template #loadingAirlinesTemplate>
          <div class="loading-message">Loading Airlines...</div>
        </ng-template>
      </div>
    </mat-tab>

    <!-- Access Documents Tab -->
    <mat-tab [label]="accessDocumentsTabLabel" *ngIf="!editMode">
      <div
        class="tab-content-access-documents"
        cdkDropList
        id="accessDocumentSidebar"
        [cdkDropListData]="accessDocuments"
        [cdkDropListConnectedTo]="connectedDropLists"
        [cdkDropListSortingDisabled]="true"
      >
        <ng-container *ngIf="!selectedAirlineId">
          <div class="message-box">
            <mat-icon class="info-icon">info</mat-icon>
            <p class="message-text">
              Select an airline on the grid to see the available access
              documents.
            </p>
          </div>
        </ng-container>
        <ng-container *ngIf="selectedAirlineId && !loadingAccessDocuments">
          <div
            *ngIf="accessDocuments.length > 0; else noCardsTemplate"
            class="access-documents"
          >
            <div
              class="access-document-card"
              *ngFor="let accessDocument of accessDocuments"
              cdkDrag
              [cdkDragData]="accessDocument"
              (cdkDragStarted)="onDragStarted($event)"
              (cdkDragEnded)="onDragEnded($event)"
            >
              <div
                class="access-document-image"
                [style.backgroundImage]="'url(' + accessDocument.imageUrl + ')'"
              ></div>
              <span class="access-document-name">{{
                accessDocument.name
              }}</span>
            </div>
          </div>
        </ng-container>
        <ng-template #noCardsTemplate>
          <div class="message-box">
            <mat-icon class="info-icon">info</mat-icon>
            <p class="message-text">No cards available for this airline.</p>
          </div>
        </ng-template>
        <ng-template #loadingAccessDocumentsTemplate>
          <div class="loading-message">Loading Access Documents...</div>
        </ng-template>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
