import { Component } from '@angular/core';
import { CardValidationService } from '../../../services/card-validation.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingService } from '../../../services/helpers/loading.service';
import { MatDialogRef } from '@angular/material/dialog';
import { CardValidationProgramDTO } from '../../../models/dtos/cardValidationProgramDTO';
import Swal from 'sweetalert2';

@Component({
  selector: 'aims-create-card-validation-program',
  templateUrl: './create-card-validation-program.component.html',
  styleUrl: './create-card-validation-program.component.scss',
  standalone: false,
})
export class CreateCardValidationProgramComponent {
  cardValidationProgramForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private cardValidationService: CardValidationService,
    private loadingService: LoadingService,
    public dialogRef: MatDialogRef<CreateCardValidationProgramComponent>,
  ) {
    this.cardValidationProgramForm = this.fb.group({
      validationProgram: ['', Validators.required],
    });
  }

  onSubmit(): void {
    if (this.cardValidationProgramForm.valid) {
      const newProgram: CardValidationProgramDTO = {
        cardValidationProgramId: 0,
        validationProgram:
          this.cardValidationProgramForm.value.validationProgram,
      };
      this.loadingService.show();

      this.cardValidationService.addCardValidationProgram(newProgram).subscribe(
        (response) => {
          this.loadingService.hide();
          Swal.fire({
            title: 'Success!',
            text: 'Card validation program added successfully.',
            icon: 'success',
            confirmButtonColor: '#2893cc',
            confirmButtonText: 'OK',
          }).then(() => {
            this.dialogRef.close(response);
            window.location.reload(); // Optional: Reload to see the new program in the list
          });
        },
        (error) => {
          this.loadingService.hide();
          this.cardValidationService.showError(
            'Failed to add card validation program.',
            error.message,
          );
        },
      );
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
