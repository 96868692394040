import { Component, Inject, Input, OnInit } from '@angular/core';
import { CardDTO, Currency } from '../../../models/dtos/cardDTO';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AirlineDTO } from '../../../models/dtos/airlineDTO';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CardService } from '../../../services/card.service';
import { AirlineService } from '../../../services/airline.service';
import { FilesService } from '../../../services/files.service';
import { ConfigService } from '../../../services/helpers/config.service';
import { LoadingService } from '../../../services/helpers/loading.service';
import Swal from 'sweetalert2';
import moment from 'moment';

interface FieldConfig {
  key: string;
  label: string;
  value: number;
}

@Component({
  selector: 'aims-card-details-dialog',
  standalone: false,
  templateUrl: './card-details-dialog.component.html',
  styleUrls: ['./card-details-dialog.component.scss'],
})
export class CardDetailsDialogComponent implements OnInit {
  card!: CardDTO | null;
  isEditMode = false;
  cardForm!: FormGroup;
  defaultImageUrl: string = 'TestAmexCard.png';
  currentImageHandle: string | null = null;
  maxImageSize: number;

  validCurrencies: Currency[] = [
    '$(USD)',
    '$(CAD)',
    '$(AUD)',
    '€(EUR)',
    '£(GBP)',
    '¥(CNY)',
    '¥(JPY)',
    'CHF(CHF)',
    '₹(INR)',
    'R$(BRL)',
    '₽(RUB)',
    'R(ZAR)',
    'kr(SEK)',
    'kr(NOK)',
    'kr(DKK)',
    'RM(MYR)',
    'Rp(IDR)',
    '₩(KRW)',
    '₺(TRY)',
    'د.إ(AED)',
    'ر.س(SAR)',
    'د.ك(KWD)',
    'ر.ق(QAR)',
    '฿(THB)',
    '₫(VND)',
    '$(MXN)',
    'د.ب(BHD)',
    '$(SGD)',
    '$(NZD)',
    '$(HKD)',
    '₱(PHP)',
    '₨(PKR)',
    '৳(BDT)',
    '£(EGP)',
    '₪(ILS)',
    'zł(PLN)',
    'Kč(CZK)',
    'Ft(HUF)',
    '$(CLP)',
    '$(ARS)',
    '$(COP)',
    '₦(NGN)',
  ];

  passengerFields: FieldConfig[] = [
    { key: 'name', label: 'Name', value: 1 },
    { key: 'ffNumber', label: 'FF Number', value: 0 },
    { key: 'flight', label: 'Flight', value: 1 },
    { key: 'pnr', label: 'PNR', value: 0 },
    { key: 'originDestination', label: 'Origin & Destination', value: 1 },
    { key: 'notes', label: 'Notes', value: 0 },
  ];

  guestFields: FieldConfig[] = [
    { key: 'name', label: 'Name', value: 1 },
    { key: 'ffNumber', label: 'FF Number', value: 0 },
    { key: 'flight', label: 'Flight', value: 1 },
    { key: 'pnr', label: 'PNR', value: 0 },
    { key: 'originDestination', label: 'Origin & Destination', value: 1 },
    { key: 'notes', label: 'Notes', value: 0 },
  ];

  airlineControl = new FormControl<AirlineDTO | string | null>(null);
  airlineOptions: any[] = [];

  constructor(
    private fb: FormBuilder,
    private cardService: CardService,
    private airlineService: AirlineService,
    private filesService: FilesService,
    private configService: ConfigService,
    private loadingService: LoadingService,
    public dialogRef: MatDialogRef<CardDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { card: CardDTO },
  ) {
    const maxImageSizeConfig = this.configService.getConfig(
      'maximumAirlineOrCardImageFileUploadSize',
    );
    this.maxImageSize = Math.ceil(maxImageSizeConfig / 1024);
  }

  ngOnInit(): void {
    this.isEditMode = !!this.data?.card;
    this.initializeForm();
    this.setupAirlineSelection();

    if (this.isEditMode) {
      this.populateForm(this.data.card);
    }

    this.setupGuestTypeChange();
    this.addFormControlsForFields();
  }

  initializeForm(): void {
    const defaultValidTo = moment('2099-12-31').toDate();
    this.cardForm = this.fb.group({
      cardId: [this.card ? this.card.cardId : 0],
      airlineId: [this.card ? this.card.airlineId : '', Validators.required],
      cardName: [this.card ? this.card.cardName : '', Validators.required],
      cardSwipeType: [
        this.card ? this.card.cardSwipeType : 1,
        Validators.required,
      ],
      validTo: [
        this.card ? moment(this.card.validTo).toDate() : defaultValidTo,
        Validators.required,
      ],
      cardImageHandle: [this.card ? this.card.cardImageHandle : ''],
      passengerMandatoryFields: [
        this.card ? this.card.passengerMandatoryFields : 0,
        Validators.required,
      ],
      passengerOptionalFields: [
        this.card ? this.card.passengerOptionalFields : 0,
        Validators.required,
      ],
      guestMandatoryFields: [
        this.card ? this.card.guestMandatoryFields : 0,
        Validators.required,
      ],
      guestOptionalFields: [
        this.card ? this.card.guestOptionalFields : 0,
        Validators.required,
      ],
      guestTypes: [this.card ? this.card.guestTypes : 0],
      maxFamilyGuests: [this.card ? this.card.maxFamilyGuests : 0],
      maxComplimentaryGuests: [
        this.card ? this.card.maxComplimentaryGuests : 0,
      ],
      maxPayGuests: [this.card ? this.card.maxPayGuests : 0],
      guestPayAmount: [this.card ? this.card.guestPayAmount : 0],
      guestPayCurrency: [
        this.card ? this.card.guestPayCurrency : this.validCurrencies[0],
        Validators.required,
      ],
      hide: [this.card ? this.card.hide : false],
    });
  }

  getAirlineName(airlineId: number): string {
    const airline = this.airlineOptions.find((a) => a.airlineId === airlineId);
    return airline ? airline.airlineName : 'Unknown Airline';
  }

  populateForm(card: CardDTO): void {
    this.cardForm.patchValue({
      cardId: card.cardId,
      airlineId: card.airlineId,
      cardName: card.cardName,
      cardSwipeType: card.cardSwipeType,
      validTo: moment(card.validTo).toDate(),
      cardImageHandle: card.cardImageHandle,
      passengerMandatoryFields: card.passengerMandatoryFields,
      passengerOptionalFields: card.passengerOptionalFields,
      guestMandatoryFields: card.guestMandatoryFields,
      guestOptionalFields: card.guestOptionalFields,
      guestTypes: card.guestTypes,
      maxFamilyGuests: card.maxFamilyGuests,
      maxComplimentaryGuests: card.maxComplimentaryGuests,
      maxPayGuests: card.maxPayGuests,
      guestPayAmount: card.guestPayAmount,
      guestPayCurrency: card.guestPayCurrency,
      hide: card.hide,
    });

    this.currentImageHandle = card.cardImageHandle;

    const selectedAirline = this.airlineOptions.find(
      (a) => a.airlineId === card.airlineId,
    );
    if (selectedAirline) {
      this.airlineControl.setValue(selectedAirline);
    } else {
      console.warn('Associated airline not found in options');
    }

    this.initializeFieldsFromInteger(
      card.passengerMandatoryFields,
      'passenger',
      1,
    );
    this.initializeFieldsFromInteger(
      card.passengerOptionalFields,
      'passenger',
      2,
    );
    this.initializeFieldsFromInteger(card.guestMandatoryFields, 'guest', 1);
    this.initializeFieldsFromInteger(card.guestOptionalFields, 'guest', 2);

    this.cardForm.patchValue({
      payGuests: !!(card.guestTypes & 1),
      familyGuests: !!(card.guestTypes & 2),
      complimentaryGuests: !!(card.guestTypes & 4),
    });
  }

  private initializeFieldsFromInteger(
    value: number,
    type: 'passenger' | 'guest',
    fieldValue: number,
  ): void {
    const binaryString = value.toString(2).padStart(6, '0');
    const fields =
      type === 'passenger' ? this.passengerFields : this.guestFields;
    const binaryOrder = [5, 4, 3, 1, 0, 2];

    binaryOrder.forEach((bitPosition, index) => {
      if (binaryString[bitPosition] === '1') {
        const fieldKey = fields[index].key;
        this.cardForm.get(`${type}-${fieldKey}`)?.setValue(fieldValue);
      }
    });
  }

  setupAirlineSelection(): void {
    this.airlineService.getAllAirlines().subscribe((response) => {
      this.airlineOptions = response?.data?.pageData || [];

      if (this.isEditMode) {
        const selectedAirline = this.airlineOptions.find(
          (a) => a.airlineId === this.data.card?.airlineId,
        );
        if (selectedAirline) {
          this.airlineControl.setValue(selectedAirline);
        }
      }
    });
  }
  onAirlineSelected(selectedAirline: any): void {
    if (selectedAirline && selectedAirline.airlineId) {
      this.cardForm.get('airlineId')?.setValue(selectedAirline.airlineId);
    }
  }

  displayAirlineName(value: any): string {
    return value ? value.airlineName : '';
  }

  addFormControlsForFields(): void {
    this.passengerFields.forEach((field) => {
      this.cardForm.addControl(
        'passenger-' + field.key,
        new FormControl(field.value),
      );
    });
    this.guestFields.forEach((field) => {
      this.cardForm.addControl(
        'guest-' + field.key,
        new FormControl(field.value),
      );
    });
  }

  setupGuestTypeChange(): void {
    const guestTypes = this.data?.card?.guestTypes || 0;
    this.cardForm.addControl('payGuests', new FormControl(!!(guestTypes & 1)));
    this.cardForm.addControl(
      'familyGuests',
      new FormControl(!!(guestTypes & 2)),
    );
    this.cardForm.addControl(
      'complimentaryGuests',
      new FormControl(!!(guestTypes & 4)),
    );

    this.cardForm
      .get('payGuests')
      ?.valueChanges.subscribe(() => this.onGuestTypeChange());
    this.cardForm
      .get('familyGuests')
      ?.valueChanges.subscribe(() => this.onGuestTypeChange());
    this.cardForm
      .get('complimentaryGuests')
      ?.valueChanges.subscribe(() => this.onGuestTypeChange());
  }

  onGuestTypeChange(): void {
    let guestTypesValue = 0;
    if (this.cardForm.get('payGuests')?.value) {
      guestTypesValue += 1;
      this.cardForm
        .get('maxPayGuests')
        ?.setValidators([Validators.required, Validators.min(1)]);
      this.cardForm
        .get('guestPayAmount')
        ?.setValidators([Validators.required, Validators.min(0)]);
      this.cardForm
        .get('guestPayCurrency')
        ?.setValidators([Validators.required]);
    } else {
      this.cardForm.get('maxPayGuests')?.clearValidators();
      this.cardForm.get('guestPayAmount')?.clearValidators();
      this.cardForm.get('guestPayCurrency')?.clearValidators();
      this.cardForm.get('maxPayGuests')?.setValue(0);
      this.cardForm.get('guestPayAmount')?.setValue(0);
    }
    if (this.cardForm.get('familyGuests')?.value) {
      guestTypesValue += 2;
      this.cardForm
        .get('maxFamilyGuests')
        ?.setValidators([Validators.required, Validators.min(1)]);
    } else {
      this.cardForm.get('maxFamilyGuests')?.clearValidators();
      this.cardForm.get('maxFamilyGuests')?.setValue(0);
    }
    if (this.cardForm.get('complimentaryGuests')?.value) {
      guestTypesValue += 4;
      this.cardForm
        .get('maxComplimentaryGuests')
        ?.setValidators([Validators.required, Validators.min(1)]);
    } else {
      this.cardForm.get('maxComplimentaryGuests')?.clearValidators();
      this.cardForm.get('maxComplimentaryGuests')?.setValue(0);
    }
    this.cardForm.get('guestTypes')?.setValue(guestTypesValue);
    this.cardForm.get('maxFamilyGuests')?.updateValueAndValidity();
    this.cardForm.get('maxComplimentaryGuests')?.updateValueAndValidity();
    this.cardForm.get('maxPayGuests')?.updateValueAndValidity();
    this.cardForm.get('guestPayAmount')?.updateValueAndValidity();
    this.cardForm.get('guestPayCurrency')?.updateValueAndValidity();
  }

  computeFieldValues(): void {
    const binaryOrder = [4, 3, 5, 2, 1, 0];
    const getBinaryString = (
      fields: FieldConfig[],
      type: 'passenger' | 'guest',
      checkValue: number,
    ): string =>
      fields
        .map((field) =>
          this.cardForm.get(`${type}-${field.key}`)?.value === checkValue
            ? '1'
            : '0',
        )
        .join('');
    const reorderBinary = (binary: string): string =>
      binaryOrder.map((index) => binary[index]).join('');
    const binaryToDecimal = (binary: string): number => parseInt(binary, 2);

    const pMandatory = binaryToDecimal(
      reorderBinary(getBinaryString(this.passengerFields, 'passenger', 1)),
    );
    const pOptional = binaryToDecimal(
      reorderBinary(getBinaryString(this.passengerFields, 'passenger', 2)),
    );
    const gMandatory = binaryToDecimal(
      reorderBinary(getBinaryString(this.guestFields, 'guest', 1)),
    );
    const gOptional = binaryToDecimal(
      reorderBinary(getBinaryString(this.guestFields, 'guest', 2)),
    );

    this.cardForm.patchValue({
      passengerMandatoryFields: pMandatory,
      passengerOptionalFields: pOptional,
      guestMandatoryFields: gMandatory,
      guestOptionalFields: gOptional,
    });
  }

  async onFileSelected(event: Event): Promise<void> {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      const formData = new FormData();
      formData.append('file', file);

      this.loadingService.show();

      if (file.size > this.maxImageSize * 1024) {
        const result = await Swal.fire({
          title: 'Image Size Exceeded',
          text: `The selected image exceeds ${this.maxImageSize} KB. Do you want to resize it?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, resize it!',
          confirmButtonColor: '#2893cc',
          cancelButtonText: 'No, upload a smaller image',
        });
        if (result.isConfirmed) {
          const resizedResponse =
            await this.filesService.compressAndUploadAirlineOrCardImage(
              formData,
            );
          if (resizedResponse && resizedResponse.data) {
            const resizedFileName = resizedResponse.data['fileName'];
            this.cardForm.patchValue({ cardImageHandle: resizedFileName });
            this.currentImageHandle = resizedFileName;
          } else {
            this.filesService.showError(
              'Error!',
              'Failed to resize the image.',
            );
          }
        } else {
          this.loadingService.hide();
          return;
        }
      } else {
        try {
          const response =
            await this.filesService.uploadAirlineOrCardImageFile(formData);
          if (response && response.data) {
            const fileName = response.data['fileName'];
            this.cardForm.patchValue({ cardImageHandle: fileName });
            this.currentImageHandle = fileName;
          } else {
            this.filesService.showError(
              'Error!',
              'Invalid file upload response.',
            );
          }
        } catch (error: any) {
          this.filesService.showError(
            'Error!',
            `File upload failed: ${error.message}`,
          );
        }
      }
      this.loadingService.hide();
    }
  }

  onDeleteImageHandle(): void {
    this.cardForm.patchValue({ cardImageHandle: '' });
    this.currentImageHandle = null;
  }

  getSwipeTypeName(type: number): string {
    return type === 1 ? 'Card' : 'Boarding Pass';
  }

  getGuestType(type: number): string {
    switch (type) {
      case 0:
        return 'None';
      case 1:
        return 'Pay Guests';
      case 2:
        return 'Family Guests';
      case 3:
        return 'Family And Pay Guests';
      case 4:
        return 'Complimentary Guests';
      case 5:
        return 'Complimentary And Pay Guests';
      case 6:
        return 'Complimentary And Family Guests';
      case 7:
        return 'Complimentary, Family And Pay Guests';
      default:
        return 'None';
    }
  }

  getMandatoryFields(type: 'passenger' | 'guest'): FieldConfig[] {
    const fields =
      type === 'passenger' ? this.passengerFields : this.guestFields;
    return fields.filter(
      (field) => this.cardForm.get(`${type}-${field.key}`)?.value === 1,
    );
  }

  onSubmit(): void {
    if (this.cardForm.valid) {
      this.computeFieldValues();
      const formValues = this.cardForm.value;
      formValues.validTo = moment(formValues.validTo).toISOString();
      if (!formValues.cardImageHandle) {
        formValues.cardImageHandle = this.defaultImageUrl;
      }
      const cardData: CardDTO = {
        ...formValues,
        cardId: this.isEditMode ? formValues.cardId : 0,
        grantAccess: true,
      };
      this.loadingService.show();
      if (this.isEditMode) {
        this.cardService.updateCard(cardData).subscribe(
          (updatedCard) => {
            this.loadingService.hide();
            Swal.fire({
              title: 'Success!',
              text: 'Card updated successfully.',
              icon: 'success',
              confirmButtonColor: '#2893cc',
              confirmButtonText: 'OK',
            }).then(() => {
              this.dialogRef.close(updatedCard);
              window.location.reload();
            });
          },
          (error) => {
            this.loadingService.hide();
            Swal.fire({
              title: 'Error!',
              text: `Failed to update card: ${error.message}`,
              icon: 'error',
              confirmButtonColor: '#2893cc',
              confirmButtonText: 'OK',
            });
          },
        );
      } else {
        this.cardService.addCard(cardData).subscribe(
          (newCard) => {
            this.loadingService.hide();
            Swal.fire({
              title: 'Success!',
              text: 'Card added successfully.',
              icon: 'success',
              confirmButtonColor: '#2893cc',
              confirmButtonText: 'OK',
            }).then(() => {
              this.dialogRef.close(newCard);
              window.location.reload();
            });
          },
          (error) => {
            this.loadingService.hide();
            Swal.fire({
              title: 'Error!',
              text: `Failed to add card: ${error.message}`,
              icon: 'error',
              confirmButtonColor: '#2893cc',
              confirmButtonText: 'OK',
            });
          },
        );
      }
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Please correct the errors in the form before submitting.',
        icon: 'error',
        confirmButtonColor: '#2893cc',
        confirmButtonText: 'OK',
      });
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
