<div class="dialog-container">
  <aims-loading></aims-loading>

  <button mat-icon-button class="close-button" (click)="onCancel()">
    <mat-icon>close</mat-icon>
  </button>

  <h2 class="dialog-title">Edit Card Validation Program</h2>

  <form [formGroup]="cardValidationProgramForm" (ngSubmit)="onSubmit()">
    <mat-horizontal-stepper [linear]="true" #stepper>
      <mat-step [stepControl]="cardValidationProgramForm">
        <form [formGroup]="cardValidationProgramForm">
          <ng-template matStepLabel
            >Update Validation Program Details</ng-template
          >
          <div class="input-group">
            <label for="validationProgram" class="input-label">
              Validation Program <span class="required-star">*</span>
            </label>
            <input
              formControlName="validationProgram"
              type="text"
              id="validationProgram"
              class="input-field"
              placeholder="Enter program name"
            />
          </div>
          <div class="dialog-actions">
            <button
              mat-button
              matStepperNext
              class="modern-button next-button"
              [disabled]="!cardValidationProgramForm.valid"
            >
              Next
            </button>
          </div>
        </form>
      </mat-step>

      <mat-step>
        <ng-template matStepLabel>Review and Submit</ng-template>
        <div class="review-section">
          <p>
            <strong>Validation Program:</strong>
            {{ cardValidationProgramForm.value?.validationProgram }}
          </p>
        </div>

        <div class="dialog-actions">
          <button
            mat-button
            matStepperPrevious
            class="modern-button back-button"
          >
            Back
          </button>
          <button
            type="submit"
            mat-raised-button
            color="primary"
            class="modern-button submit-button"
            [disabled]="
              !cardValidationProgramForm.valid ||
              cardValidationProgramForm.pristine
            "
          >
            Save Changes
          </button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </form>
</div>
