import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { WorkstationService } from '../../../services/workstation.service';
import { AirportService } from '../../../services/airport.service';
import { LoungeService } from '../../../services/lounge.service';
import { AirlineService } from '../../../services/airline.service';
import { AirportDTO } from '../../../models/dtos/airportDTO';
import { LoungeDTO } from '../../../models/dtos/loungeDTO';
import { WorkstationDTO } from '../../../models/dtos/workstationDTO';
import { AirlineDTO } from '../../../models/dtos/airlineDTO';
import { LoadingService } from '../../../services/helpers/loading.service';

@Component({
  selector: 'aims-edit-workstation',
  templateUrl: './edit-workstation.component.html',
  styleUrls: ['./edit-workstation.component.scss'],
  standalone: false,
})
export class EditWorkstationComponent implements OnInit {
  workstationForm!: FormGroup;
  airports: AirportDTO[] = [];
  lounges: LoungeDTO[] = [];
  filteredLounges: LoungeDTO[] = [];
  airlines: AirlineDTO[] = [];
  isSubmitting = false;
  initialWorkstationData: WorkstationDTO;
  isFormDirty = false;
  isFormInitialized = false;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditWorkstationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WorkstationDTO,
    private workstationService: WorkstationService,
    private airportService: AirportService,
    private loungeService: LoungeService,
    private airlineService: AirlineService,
    private loadingService: LoadingService,
  ) {
    this.initialWorkstationData = { ...data };
  }

  ngOnInit(): void {
    this.fetchInitialData();
  }

  fetchInitialData(): void {
    // Fetch airports, lounges, and airlines concurrently
    Promise.all([
      this.fetchAirports(),
      this.fetchLounges(),
      this.fetchAirlines(),
    ]).then(() => {
      this.initializeForm();
      this.subscribeToFormChanges();
      this.patchForm();
      this.isFormInitialized = true;
    });
  }

  fetchAirports(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.airportService.getAirports().subscribe(
        (response) => {
          if (
            response &&
            response.success &&
            response.data &&
            response.data.pageData
          ) {
            this.airports = response.data.pageData;
          } else {
            this.airports = [];
          }
          resolve();
        },
        (error) => {
          this.airportService.showError(
            'Error fetching airports:',
            error.message,
          );
          this.airports = [];
          reject();
        },
      );
    });
  }

  fetchLounges(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.loungeService.getLounges().subscribe(
        (response) => {
          if (
            response &&
            response.success &&
            response.data &&
            response.data.pageData
          ) {
            this.lounges = response.data.pageData;
          } else {
            this.lounges = [];
          }
          resolve();
        },
        (error) => {
          this.loungeService.showError(
            'Error fetching lounges:',
            error.message,
          );
          this.lounges = [];
          reject();
        },
      );
    });
  }

  fetchAirlines(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.airlineService.getAllAirlines().subscribe(
        (response) => {
          if (response && response.success && response.data) {
            this.airlines = response.data.pageData;
          } else {
            this.airlines = [];
          }
          resolve();
        },
        (error) => {
          this.airportService.showError(
            'Error fetching airlines:',
            error.message,
          );
          this.airlines = [];
          reject();
        },
      );
    });
  }

  initializeForm(): void {
    this.workstationForm = this.fb.group({
      airportId: [this.data.airportId, Validators.required],
      loungeId: [this.data.loungeId, Validators.required],
      workstationName: [this.data.workstationName, Validators.required],
      defaultAirlineId: [this.data.defaultAirlineId, Validators.required],
    });

    this.workstationForm
      .get('airportId')
      ?.valueChanges.subscribe((airportId) => {
        this.filterLounges(airportId);
      });
  }

  patchForm(): void {
    this.workstationForm.patchValue({
      airportId: this.data.airportId,
      loungeId: this.data.loungeId,
    });
    this.filterLounges(this.data.airportId); // Ensure lounges are filtered correctly
  }

  subscribeToFormChanges(): void {
    this.workstationForm.valueChanges.subscribe(() => {
      this.isFormDirty =
        JSON.stringify(this.initialWorkstationData) !==
        JSON.stringify(this.workstationForm.value);
    });
  }

  filterLounges(airportId: number): void {
    this.filteredLounges = this.lounges.filter(
      (lounge) => lounge.airportId === airportId,
    );
  }

  onSubmit(): void {
    if (this.workstationForm.valid) {
      this.isSubmitting = true;
      const workstation: WorkstationDTO = {
        workstationId: this.data.workstationId,
        airportId: this.workstationForm.get('airportId')?.value,
        loungeId: this.workstationForm.get('loungeId')?.value,
        workstationName: this.workstationForm.get('workstationName')?.value,
        defaultAirlineId: this.workstationForm.get('defaultAirlineId')?.value,
      };
      this.loadingService.show();

      this.workstationService
        .updateWorkstation(workstation)
        .subscribe(
          (response) => {
            this.loadingService.hide();
            Swal.fire({
              title: 'Success!',
              text: 'Workstation details updated successfully!',
              icon: 'success',
              confirmButtonColor: '#2893cc',
              confirmButtonText: 'OK',
            }).then(() => {
              this.dialogRef.close(response);
              window.location.reload();
            });
          },
          (error) => {
            this.loadingService.hide();
            this.workstationService.showError(
              'Error updating workstation:',
              error.message,
            );
          },
        )
        .add(() => {
          this.isSubmitting = false;
        });
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  getAirportName(airportId: number): string {
    const airport = this.airports.find((a) => a.airportId === airportId);
    return airport ? airport.airportName : '';
  }

  getLoungeName(loungeId: number): string {
    const lounge = this.lounges.find((l) => l.loungeId === loungeId);
    return lounge ? lounge.loungeName : '';
  }

  getAirlineName(airlineId: number): string {
    const airline = this.airlines.find((a) => a.airlineId === airlineId);
    return airline ? airline.airlineName : '';
  }
}
