import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { AuthService } from './services/helpers/auth.service';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
} from '@azure/msal-browser';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})
export class AppComponent implements OnInit {
  title = 'Ieg.AIMS.Web.Admin.UI';
  showSidebar = true;
  showHeader = true;

  @ViewChild('sidenav') sidenav!: MatSidenav;

  constructor(
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private authService: AuthService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS,
        ),
      )
      .subscribe((result: EventMessage) => {
        const authResult = result.payload as AuthenticationResult;
        if (authResult?.account) {
          this.msalService.instance.setActiveAccount(authResult.account);
        }
      });

    // Handle interaction status changes
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None,
        ),
      )
      .subscribe(() => {
        // Can add post-authentication logic here
      });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        const isAuthPage =
          event.url === '/login' ||
          event.url === '/logout' ||
          event.url === '/';
        const isAuthenticated = !!this.authService.getAccount();

        this.showSidebar = !(isAuthPage && !isAuthenticated);
        this.showHeader = !isAuthPage;
      });
  }

  toggleSidebar(): void {
    this.sidenav?.toggle();
  }
}
