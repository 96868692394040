<div class="airport-details-container">
  <div class="global-card-header">
    <div class="header-with-back">
      <button
        mat-icon-button
        (click)="navigateToAirports()"
        class="back-button"
      >
        <mat-icon>arrow_back</mat-icon>
      </button>
      <div class="header-titles">
        <h1 class="global-card-title" *ngIf="airportData">
          {{ airportData.airportName }}
        </h1>
        <h2 class="global-card-subtitle" *ngIf="airportData">
          {{ airportData.airportCode }}
        </h2>
      </div>
    </div>
    <div class="tab-nav">
      <span
        class="tab-link"
        [class.active]="activeTab === 'overview'"
        (click)="switchToOverview()"
      >
        Overview
      </span>
      <span
        class="tab-link"
        [class.active]="activeTab === 'lounges'"
        (click)="switchToLounges()"
      >
        Lounges
      </span>
      <div class="action-buttons" *ngIf="activeTab !== 'lounges'">
        <button mat-icon-button (click)="editAirport()">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button (click)="deleteAirport()">
          <mat-icon>delete</mat-icon>
        </button>
        <button mat-icon-button (click)="refresh()">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="card-overview" *ngIf="airportData">
    <div *ngIf="activeTab === 'overview'">
      <div class="card-row">
        <div class="airport-info-card card">
          <div class="card-header">
            <h3 class="card-title">Airport Details</h3>
          </div>
          <div class="card-body">
            <div class="detail-item">
              <span class="detail-title">Airport ID:</span>
              {{ airportData.airportId }}
            </div>
            <div class="detail-item">
              <span class="detail-title">Airport Name:</span>
              {{ airportData.airportName }}
            </div>
            <div class="detail-item">
              <span class="detail-title">Airport Code:</span>
              {{ airportData.airportCode }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="airportData && activeTab === 'lounges'" class="lounges">
      <!-- No lounges available -->
      <div *ngIf="filteredLounges.length === 0">
        <mat-card>
          <mat-card-content>
            <p>No lounges available for this airport.</p>
          </mat-card-content>
        </mat-card>
      </div>

      <!-- Render lounges if available -->
      <div *ngIf="filteredLounges.length > 0">
        <aims-lounges
          [airportId]="airportData.airportId"
          [showFullContent]="false"
          [showActions]="false"
          [showSearchBar]="false"
          [filteredLounges]="filteredLounges"
        ></aims-lounges>
      </div>
    </div>
  </div>
</div>
