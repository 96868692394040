<div class="dialog-container">
  <!-- Loading Component -->
  <aims-loading></aims-loading>

  <button mat-icon-button class="close-button" (click)="onCancel()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-vertical-stepper [linear]="true" #stepper>
    <!-- Step 1: Airport Information -->
    <mat-step [stepControl]="loungeForm.controls['airportId']">
      <ng-template matStepLabel>Airport Information</ng-template>
      <form [formGroup]="loungeForm">
        <div class="input-group">
          <mat-form-field appearance="fill">
            <mat-label>Airport Name</mat-label>
            <input
              type="text"
              matInput
              [formControl]="airportSearchControl"
              [matAutocomplete]="auto"
              placeholder="Search Airport"
              autocomplete="off"
              (focus)="showAllAirports()"
            />
            <mat-autocomplete
              #auto="matAutocomplete"
              [displayWith]="displayAirportName"
              (optionSelected)="onSelectAirport($event)"
            >
              <ng-container *ngIf="filteredAirports.length > 0; else noData">
                <mat-option
                  *ngFor="let airport of filteredAirports"
                  [value]="airport"
                >
                  {{ airport.airportName }}
                </mat-option>
              </ng-container>

              <ng-template #noData>
                <mat-option disabled>No airport to display</mat-option>
              </ng-template>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <button mat-raised-button color="primary" matStepperNext>Next</button>
      </form>
    </mat-step>

    <!-- Step 2: Lounge Details -->
    <mat-step [stepControl]="loungeForm.controls['loungeName']">
      <ng-template matStepLabel>Lounge Details</ng-template>
      <form [formGroup]="loungeForm">
        <div class="input-group">
          <mat-form-field appearance="fill">
            <mat-label>Lounge Name</mat-label>
            <input matInput formControlName="loungeName" type="text" />
          </mat-form-field>
        </div>
        <div class="input-group">
          <mat-form-field appearance="fill">
            <mat-label>Max Occupancy</mat-label>
            <input
              matInput
              formControlName="maxOccupancy"
              type="text"
              pattern="^(0|[1-9][0-9]*)$"
              placeholder="Enter a positive number or 0"
              required
              autocomplete="off"
            />
            <mat-error
              *ngIf="loungeForm.get('maxOccupancy')?.hasError('pattern')"
            >
              Please enter 0 or a positive integer (no leading zeros).
            </mat-error>
          </mat-form-field>
        </div>
        <button mat-raised-button color="warn" matStepperPrevious>Back</button>
        <button mat-raised-button color="primary" matStepperNext>Next</button>
      </form>
    </mat-step>

    <!-- Step 4: Review and Submit -->
    <mat-step>
      <ng-template matStepLabel>Review and Submit</ng-template>
      <div class="review-section">
        <p>
          <strong>Airport:</strong>
          {{ getAirportName(loungeForm.get("airportId")?.value) }}
        </p>
        <p>
          <strong>Lounge Name:</strong>
          {{ loungeForm.get("loungeName")?.value }}
        </p>
        <p>
          <strong>Max Occupancy:</strong>
          {{ loungeForm.get("maxOccupancy")?.value }}
        </p>
      </div>
      <div class="dialog-actions">
        <button mat-raised-button color="warn" matStepperPrevious>Back</button>
        <button
          mat-raised-button
          color="primary"
          [disabled]="isSubmitting"
          (click)="onSubmit()"
        >
          Submit
        </button>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</div>
