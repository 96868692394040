import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoungeHourDTO } from '../../../models/dtos/loungeHourDTO';
import { LoungeService } from '../../../services/lounge.service';
import Swal from 'sweetalert2';
import { LoadingService } from '../../../services/helpers/loading.service';

@Component({
  selector: 'aims-lounge-hours',
  templateUrl: './lounge-hours.component.html',
  styleUrls: ['./lounge-hours.component.scss'],
  standalone: false,
})
export class LoungeHoursComponent implements OnInit {
  loungeHoursForm: FormGroup;
  daysOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  deletedLoungeHours: number[] = [];

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<LoungeHoursComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { loungeId: number; loungeHours: LoungeHourDTO[] },
    private loungeService: LoungeService,
    private loadingService: LoadingService,
  ) {
    this.loungeHoursForm = this.fb.group({
      effectiveFromUTC: ['', Validators.required],
      effectiveToUTC: ['', Validators.required],
      hours: this.fb.array(
        this.daysOfWeek.map((_, index) => this.createDayFormGroup(index)),
      ),
    });
  }

  ngOnInit(): void {
    if (this.data.loungeHours && this.data.loungeHours.length) {
      this.loadLoungeHours(this.data.loungeHours);

      const effectiveFromUTC = this.parseDate(
        this.data.loungeHours[0]?.effectiveFromUTC,
      );
      const effectiveToUTC = this.parseDate(
        this.data.loungeHours[0]?.effectiveToUTC,
      );

      this.loungeHoursForm.patchValue({
        effectiveFromUTC,
        effectiveToUTC,
      });
    } else {
    }
  }

  createDayFormGroup(dayOfWeek: number): FormGroup {
    const group = this.fb.group({
      loungeHourId: [0],
      loungeId: [this.data.loungeId],
      dayOfWeek: [dayOfWeek],
      isOpen: [false],
      startTime: ['09:00', Validators.required],
      endTime: ['23:59', Validators.required],
    });

    // Track deletions when `isOpen` is unchecked
    group.get('isOpen')?.valueChanges.subscribe((isOpen) => {
      const loungeHourId = group.get('loungeHourId')?.value || 0; // Ensure number
      if (!isOpen && loungeHourId > 0) {
        this.deletedLoungeHours.push(loungeHourId); // Add to deletion list
      } else if (isOpen) {
        // Remove from deletion list if toggled back on
        this.deletedLoungeHours = this.deletedLoungeHours.filter(
          (id) => id !== loungeHourId,
        );
      }
    });
    return group;
  }

  getOpenHours(): any[] {
    return (
      this.loungeHoursForm
        .get('hours')
        ?.value.filter((hour: any) => hour.isOpen) || []
    );
  }

  get hours(): FormArray {
    return this.loungeHoursForm.get('hours') as FormArray;
  }

  loadLoungeHours(hours: LoungeHourDTO[]): void {
    this.hours.clear();
    this.deletedLoungeHours = [];

    this.daysOfWeek.forEach((_, dayOfWeek) => {
      const matchingHour = hours.find((hour) => hour.dayOfWeek === dayOfWeek);

      const dayGroup = this.createDayFormGroup(dayOfWeek);

      if (matchingHour) {
        dayGroup.patchValue({
          loungeHourId: matchingHour.loungeHourId,
          loungeId: matchingHour.loungeId,
          dayOfWeek: matchingHour.dayOfWeek,
          isOpen: true,
          startTime: matchingHour.startTime || '',
          endTime: matchingHour.endTime || '',
        });
      } else {
        dayGroup.patchValue({
          isOpen: false,
        });
      }

      this.hours.push(dayGroup);
    });
  }

  onSubmit(): void {
    if (this.loungeHoursForm.invalid) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Please ensure all fields are filled and times are valid.',
      });
      return;
    }

    const formValue = this.loungeHoursForm.value;

    if (
      new Date(formValue.effectiveFromUTC) >= new Date(formValue.effectiveToUTC)
    ) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Effective From date must be earlier than Effective To date.',
        confirmButtonColor: '#2893CC',
      });
      return;
    }

    const loungeHours: LoungeHourDTO[] = formValue.hours
      .filter((hour: any) => hour.isOpen)
      .map((hour: any) => ({
        loungeId: hour.loungeId,
        dayOfWeek: hour.dayOfWeek,
        startTime: this.formatTime(hour.startTime),
        endTime: this.formatTime(hour.endTime),
        effectiveFromUTC: new Date(formValue.effectiveFromUTC).toISOString(),
        effectiveToUTC: new Date(formValue.effectiveToUTC).toISOString(),
        loungeHourId: hour.loungeHourId || 0,
      }));

    const daysToDelete = this.deletedLoungeHours;

    if (loungeHours.length === 0 && daysToDelete.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Please select at least one open day or remove any unneeded days.',
        confirmButtonColor: '#2893CC',
      });
      return;
    }

    this.loadingService.show();

    const addUpdateApiCall = loungeHours.some((hour) => hour.loungeHourId > 0)
      ? this.loungeService.updateLoungeHours(loungeHours)
      : this.loungeService.addLoungeHours(loungeHours);

    const deleteApiCalls = daysToDelete.map((id) =>
      this.loungeService.removeLoungeHour(id),
    );

    Promise.all([
      addUpdateApiCall
        .toPromise()
        .catch((error) => this.handleError(error, 'add/update')),
      ...deleteApiCalls.map((call) =>
        call.toPromise().catch((error) => this.handleError(error, 'delete')),
      ),
    ])
      .then(() => this.handleSuccess('submitted'))
      .finally(() => this.loadingService.hide());
  }

  private formatTime(time: string): string {
    const [hour, minute] = time.split(':');
    return `${hour}:${minute}:00`;
  }

  private handleError(error: any, action: string): void {
    this.loadingService.hide();

    const errorMessage =
      error?.message ||
      error?.error?.message ||
      `Failed to ${action} lounge hours. Please ensure all fields are valid.`;

    Swal.fire({
      icon: 'error',
      title: 'Error!',
      text: errorMessage,
      confirmButtonColor: '#2893CC',
      confirmButtonText: 'OK',
    });
  }

  private handleSuccess(action: string): void {
    this.loadingService.hide();
    Swal.fire({
      title: 'Success!',
      text: `Lounge hours ${action} successfully.`,
      icon: 'success',
      confirmButtonColor: '#2893CC',
    }).then(() => {
      this.dialogRef.close(true);
      window.location.reload();
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  private parseDate(date: any): string {
    if (typeof date === 'string') {
      return date.split('T')[0];
    }
    return '';
  }
}
