import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'aims-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrl: './side-bar.component.scss',
  standalone: false,
})
export class SideBarComponent implements OnInit {
  sidebarImages: string[] = [
    '../../../assets/images/iegaims-image.png',
    '../../../assets/images/ieg_logo.png',
  ];

  currentIndex = 0;
  imageChangeInterval = 60000; //  for 1 minute

  ngOnInit(): void {
    setInterval(() => {
      this.changeImage();
    }, this.imageChangeInterval);
  }

  changeImage(): void {
    this.currentIndex = (this.currentIndex + 1) % this.sidebarImages.length;
  }

  get currentImage(): string {
    return this.sidebarImages[this.currentIndex];
  }
}
