import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService } from '../../services/helpers/auth.service';
import { MsalBroadcastService } from '@azure/msal-angular';
import { filter } from 'rxjs/operators';
import { EventMessage, EventType } from '@azure/msal-browser';

@Component({
  selector: 'aims-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: false,
})
export class HeaderComponent implements OnInit {
  @ViewChild('sidenav') sidenav!: MatSidenav;
  @Output() toggleSidebar = new EventEmitter<void>();
  username: string | null = null;

  constructor(
    private authService: AuthService,
    private msalBroadcastService: MsalBroadcastService,
  ) {}

  ngOnInit(): void {
    this.updateUsername();

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS,
        ),
      )
      .subscribe(() => {
        this.updateUsername();
      });
  }

  private updateUsername() {
    const account = this.authService.getAccount();
    if (account) {
      this.username =
        account.name ||
        account.username ||
        account.idTokenClaims?.preferred_username ||
        'User';
    }
  }

  onToggleSidebar(): void {
    this.toggleSidebar.emit();
  }

  onLogout(): void {
    this.authService.logout();
  }
}
