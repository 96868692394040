import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';

import { AirportDTO } from '../../../models/dtos/airportDTO';
import { AirportService } from '../../../services/airport.service';
import { EditAirportComponent } from '../edit-airport/edit-airport.component';
import { LoungeDTO } from '../../../models/dtos/loungeDTO';

@Component({
  selector: 'aims-airport-details',
  templateUrl: './airport-details.component.html',
  styleUrls: ['./airport-details.component.scss'],
  standalone: false,
})
export class AirportDetailsComponent implements OnInit {
  airportData?: AirportDTO;
  activeTab: 'overview' | 'lounges' = 'overview';

  lounges: LoungeDTO[] = [];
  filteredLounges: LoungeDTO[] = [];

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private airportService: AirportService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.getAirportDetails();
  }

  getAirportDetails(): void {
    const airportId = +this.route.snapshot.params['id'];
    this.airportService.getAirportById(airportId).subscribe(
      (response: any) => {
        if (response.success) {
          this.airportData = response.data;
          if (this.airportData?.airportId) {
            this.fetchLounges(this.airportData.airportId); // Fetch lounges for this airport
          }
        } else {
          this.airportService.showError('Airport details not found', 'error');
        }
      },
      (error) => {
        this.airportService.showError(
          'Error fetching airport details:',
          error.message,
        );
      },
    );
  }

  fetchLounges(airportId: number): void {
    this.airportService.getAirportLounges(airportId).subscribe(
      (response: any) => {
        if (response.success && response.data) {
          this.lounges = response.data;
          // Ensure filteredLounges matches lounges for this airport
          this.filteredLounges = this.lounges.filter(
            (lounge: LoungeDTO) => lounge.airportId === airportId,
          );
        } else {
          this.lounges = [];
          this.filteredLounges = [];
        }
      },
      (error) => {
        this.lounges = [];
        this.filteredLounges = [];
        Swal.fire({
          title: 'Error',
          text: `Failed to fetch lounges: ${error.message}`,
          icon: 'error',
          confirmButtonColor: '#2893cc',
          confirmButtonText: 'OK',
        });
      },
    );
  }

  editAirport(): void {
    const dialogRef = this.dialog.open(EditAirportComponent, {
      width: '480px',
      data: this.airportData,
    });

    dialogRef.afterClosed().subscribe((result: AirportDTO) => {
      if (result) {
        this.airportService.updateAirport(result).subscribe(
          (updatedAirport) => {
            this.airportData = updatedAirport;
          },
          (error) => {
            this.airportService.showError(
              'Airport details not found',
              error.message,
            );
          },
        );
      }
    });
  }

  deleteAirport(): void {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2893cc',
      cancelButtonColor: '#939597',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        if (this.airportData?.airportId) {
          this.airportService
            .removeAirport(this.airportData.airportId)
            .subscribe(
              () => {
                Swal.fire({
                  title: 'Deleted!',
                  text: 'Your airport has been deleted.',
                  icon: 'success',
                  confirmButtonColor: '#2893cc',
                  confirmButtonText: 'OK',
                }).then(() => {
                  this.router.navigate(['/app/airports']);
                });
              },
              (error) => {
                this.airportService.showError(
                  'Error deleting airport:',
                  error.message,
                );
              },
            );
        }
      }
    });
  }

  refresh(): void {
    this.getAirportDetails();
  }

  switchToOverview(): void {
    this.activeTab = 'overview';
  }

  switchToLounges(): void {
    this.activeTab = 'lounges';
  }

  navigateToAirports(): void {
    this.router.navigate(['/app/airports']);
  }
}
