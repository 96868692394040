<div class="lounge-details-container">
  <div class="global-card-header">
    <div class="header-with-back">
      <button mat-icon-button (click)="navigateToLounges()" class="back-button">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <div class="header-titles">
        <h1 class="global-card-title">{{ loungeData?.loungeName }}</h1>
        <h2 class="global-card-subtitle">
          {{ airportCode }} - {{ airportName }}
        </h2>
      </div>
    </div>
    <div class="tab-nav">
      <span
        class="tab-link"
        [class.active]="activeTab === 'overview'"
        (click)="switchToOverview()"
      >
        Overview
      </span>
      <span
        class="tab-link"
        [class.active]="activeTab === 'workstations'"
        (click)="switchToWorkstations()"
      >
        Workstations
      </span>
      <div class="action-buttons">
        <button mat-icon-button (click)="editLounge()">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button (click)="deleteLounge()">
          <mat-icon>delete</mat-icon>
        </button>
        <button mat-icon-button (click)="refresh()">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="loungeData && activeTab === 'overview'" class="lounge-overview">
    <div class="card-row">
      <div class="airport-info-card card">
        <div class="card-header">
          <h3 class="card-title">Airport Details</h3>
        </div>
        <div class="card-body">
          <div class="detail-item">
            <span class="detail-title">Airport ID:</span>
            {{ airportId }}
          </div>
          <div class="detail-item">
            <span class="detail-title">Airport Code:</span>
            {{ airportCode }}
          </div>
          <div class="detail-item">
            <span class="detail-title">Airport Name:</span>
            {{ airportName }}
          </div>
        </div>
      </div>

      <div class="lounge-info-card card">
        <div class="card-header">
          <h3 class="card-title">Lounge Information</h3>
        </div>
        <div class="card-body">
          <div class="detail-item">
            <span class="detail-title">Lounge ID:</span>
            {{ loungeData.loungeId }}
          </div>
          <div class="detail-item">
            <span class="detail-title">Lounge Name:</span>
            {{ loungeData.loungeName }}
          </div>
          <div class="detail-item">
            <span class="detail-title">Capacity:</span>
            {{ loungeData.maxOccupancy }}
          </div>
        </div>
      </div>
    </div>

    <div class="card-row">
      <div class="hours-info-card card">
        <div class="card-header">
          <h3 class="card-title">Operating Hours</h3>
          <button
            mat-stroked-button
            class="edit-hours-button"
            (click)="createOrUpdateLoungeHours()"
          >
            {{ loungeHours.length ? "Edit Hours" : "Create Hours" }}
          </button>
        </div>
        <div class="card-body" *ngIf="loungeHours.length">
          <div *ngFor="let hour of loungeHours">
            <div class="detail-item">
              <span class="detail-title"
                >{{ daysOfWeek[hour.dayOfWeek] }}:</span
              >
              <span *ngIf="hour.startTime && hour.endTime">
                Open from {{ formatTime(hour.startTime) }} to
                {{ formatTime(hour.endTime) }}
              </span>
              <span
                *ngIf="!hour.startTime || !hour.endTime"
                style="color: #dc3545; font-weight: bold"
              >
                Closed
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="loungeData && activeTab === 'workstations'" class="workstations">
    <div *ngIf="filteredWorkstations.length === 0">
      <mat-card>
        <mat-card-content>
          <p>No workstations available for this lounge.</p>
        </mat-card-content>
      </mat-card>
    </div>
    <div *ngIf="filteredWorkstations.length > 0">
      <aims-workstations
        [loungeId]="loungeData.loungeId"
        [showFullContent]="false"
        [showActions]="false"
        [showSearchBar]="false"
        [filteredWorkstations]="filteredWorkstations"
      ></aims-workstations>
    </div>
  </div>
</div>
