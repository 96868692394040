import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewChildren,
  QueryList,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { CdkDragDrop, CdkDragMove, CdkDragStart } from '@angular/cdk/drag-drop';
import { WorkstationDTO } from '../../models/dtos/workstationDTO';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AirportDTO } from '../../models/dtos/airportDTO';
import { LoungeDTO } from '../../models/dtos/loungeDTO';
import { AirportService } from '../../services/airport.service';
import { LoungeService } from '../../services/lounge.service';
import {
  CardPositionDTO,
  CardPositionOperationDTO,
} from '../../models/dtos/cardPositionDTO';

import { PositionsService } from '../../services/position.service';
import { AirlineService } from '../../services/airline.service';
import { FilesService } from '../../services/files.service';
import { forkJoin, of, startWith } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  AirlinePositionDTO,
  AirlinePositionOperationDTO,
} from '../../models/dtos/airlinePositionDTO';
import { AirlineDTO } from '../../models/dtos/airlineDTO';
import { CardService } from '../../services/card.service';
import { CardDTO } from '../../models/dtos/cardDTO';
import Swal from 'sweetalert2';
import { LoadingService } from '../../services/helpers/loading.service';
import { MatDialog } from '@angular/material/dialog';
import { SelectLoungeDialogComponent } from './select-lounge-dialog/select-lounge-dialog.component';
import { SelectAirportDialogComponent } from './select-airport-dialog/select-airport-dialog.component';
import { GroupAssociationService } from '../../services/group-association.service';

@Component({
  selector: 'aims-main-section',
  templateUrl: './main-section.component.html',
  styleUrl: './main-section.component.scss',
  standalone: false,
})
export class MainSectionComponent implements OnInit, AfterViewInit {
  isDefaultContentVisible: boolean = true;
  /* airlines grid */
  isEditMode: boolean = true;
  airlinesChangesMade: boolean = false;
  airlinesGrid: ({
    imageUrl: string;
    airlineCode: string;
    airlineId: number | null;
    allianceImageHandles: string[];
  } | null)[] = Array(50).fill(null);
  airlineDetailsMap = new Map<
    number,
    {
      airlineCode: string;
      imageUrl: string;
      allianceImageHandles: string[];
    }
  >();
  airlineOperationList: AirlinePositionOperationDTO[] = [];
  existingAirlines = new Set<number>();
  airlineSequenceNumber: number = 0;
  connectedAirlineDropLists: string[] = [];
  connectedSidebarDropListId: string = 'airlineSidebar';

  private scrollInterval: any;
  private dragPosition = { x: 0, y: 0 };

  /* access documents grid */
  documentsChangesMade: boolean = true;
  accessDocumentsGrid: ({
    imageUrl: string;
    cardName: string;
    cardId?: number;
  } | null)[] = Array(35).fill(null);

  accessDocumentDetailsMap = new Map<
    number,
    { cardName: string; imageUrl: string }
  >();
  accessDocumentOperationList: CardPositionOperationDTO[] = [];
  isAccessDocumentEditMode: boolean = true;
  connectedAccessDocumentDropLists: string[] = [];
  private accessDocumentSequenceNumber: number = 0;

  filterForm!: FormGroup;
  airports: AirportDTO[] = [];
  filteredAirports: AirportDTO[] = [];
  fetchedLounges: LoungeDTO[] = [];
  filteredLounges: LoungeDTO[] = [];
  fetchedWorkstations: WorkstationDTO[] = [];
  filteredWorkstations: WorkstationDTO[] = [];
  defaultImageUrl: string = '../../../../assets/images/image-icon.png';

  airportSearchControl = new FormControl('');
  loungeSearchControl = new FormControl('');
  workstationSearchControl = new FormControl('');

  selectedWorkstationId: number | null = null;
  selectedAirlineIndex: number | null = null;
  selectedAirlineId: number | null = null;

  currentDragContainer: HTMLElement | null = null;

  @ViewChildren('gridItem') gridItems!: QueryList<ElementRef>;

  constructor(
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private airportService: AirportService,
    private loungeService: LoungeService,
    private positionsService: PositionsService,
    private airlineService: AirlineService,
    private cardService: CardService,
    private fileService: FilesService,
    private loadingService: LoadingService,
    private dialog: MatDialog,
    private groupAssociationService: GroupAssociationService,
  ) {}

  onActivateComponent(event: any) {
    this.isDefaultContentVisible = false;
  }

  ngAfterViewInit(): void {
    this.updateConnectedDropLists();
    this.updateConnectedDropListsForAccessDocuments();
    this.initGridLayout();
    window.addEventListener('resize', () => this.initGridLayout());
  }

  ngOnDestroy(): void {
    if (this.scrollInterval) clearInterval(this.scrollInterval);
    window.removeEventListener('resize', () => this.initGridLayout());
  }

  private initGridLayout(): void {
    const container = document.querySelector('.airlines-grid');
    if (container) {
      container.setAttribute(
        'style',
        `display: grid;
         grid-template-columns: repeat(5, 1fr);
         gap: 8px`,
      );
    }
  }

  updateConnectedDropLists(): void {
    this.connectedAirlineDropLists = ['airlinesGrid'];

    if (this.isEditMode) {
      this.connectedAirlineDropLists.push(this.connectedSidebarDropListId);
    }

    this.cdr.detectChanges();
  }

  updateConnectedDropListsForAccessDocuments(): void {
    this.connectedAccessDocumentDropLists = ['accessDocumentGrid'];
    if (this.isAccessDocumentEditMode) {
      this.connectedAccessDocumentDropLists.push('accessDocumentSidebar');
    }
    this.cdr.detectChanges();
  }
  /** -------------------- FILTERING FORM & LOGIC -------------------- */

  ngOnInit(): void {
    this.initializeForm();
    this.fetchAirports();
    this.fetchAirlineDetails();
    this.fetchAccessDocumentDetails();
  }

  initializeForm(): void {
    this.filterForm = this.fb.group({
      airportId: [null],
      loungeId: [null],
      workstationId: [null],
    });

    this.filterForm.get('airportId')?.valueChanges.subscribe((airportId) => {
      this.filterLounges(airportId);
    });

    this.filterForm.get('loungeId')?.valueChanges.subscribe((loungeId) => {
      this.filterWorkstations(loungeId);
    });

    this.filterForm
      .get('workstationId')
      ?.valueChanges.subscribe((workstationId) => {
        this.selectedWorkstationId = workstationId;

        this.selectedAirlineId = null;
        this.selectedAirlineIndex = null;
        this.accessDocumentsGrid = Array(35).fill(null);

        this.documentsChangesMade = false;
        this.airlinesChangesMade = false;

        if (workstationId) {
          this.loadAirlineGrid(workstationId);
          this.loadAccessDocumentGrid(workstationId);
        }

        this.updateConnectedDropLists();
      });
  }

  fetchAirports(): void {
    this.airportService.getAirports().subscribe((response) => {
      this.airports = response?.data?.pageData || [];
      this.filteredAirports = [...this.airports];
    });
  }

  onSelectAirport(airport: AirportDTO): void {
    this.filterForm.get('airportId')?.setValue(airport.airportId);
  }

  onSelectLounge(lounge: LoungeDTO): void {
    this.filterForm.get('loungeId')?.setValue(lounge.loungeId);
    this.filterWorkstations(lounge.loungeId);
  }

  onSelectWorkstation(workstation: WorkstationDTO): void {
    this.filterForm.get('workstationId')?.setValue(workstation.workstationId);
  }

  displayAirportName(airport: AirportDTO): string {
    return airport ? airport.airportName : '';
  }

  displayLoungeName(lounge: LoungeDTO): string {
    return lounge ? lounge.loungeName : '';
  }

  displayWorkstationName(workstation: WorkstationDTO): string {
    return workstation ? workstation.workstationName : '';
  }

  filterLounges(airportId: number): void {
    if (airportId) {
      this.airportService
        .getAirportLounges(airportId)
        .subscribe((response: any) => {
          this.fetchedLounges = response.data;
          this.loungeSearchControl.setValue('');
        });
    } else {
      this.fetchedLounges = [];
    }
  }

  filterWorkstations(loungeId: number): void {
    if (loungeId) {
      this.loungeService
        .getLoungeWorkstations(loungeId)
        .subscribe((response: any) => {
          this.fetchedWorkstations = response.data;
          this.workstationSearchControl.setValue('');
        });
    } else {
      this.fetchedWorkstations = [];
    }
  }

  clearFilters(): void {
    this.filterForm.reset({
      airportId: null,
      loungeId: null,
      workstationId: null,
    });

    this.fetchedLounges = [];
    this.fetchedWorkstations = [];

    this.airportSearchControl.setValue('');
    this.loungeSearchControl.setValue('');
    this.workstationSearchControl.setValue('');

    this.selectedWorkstationId = null;
  }

  isWorkstationSelected(): boolean {
    return !!this.selectedWorkstationId;
  }

  /** -------------------- AIRLINE GRID FUNCTIONS -------------------- */

  loadAirlineGrid(workstationId: number): void {
    this.fetchAirlinePositions(workstationId);

    this.updateConnectedDropLists();
  }

  fetchAirlineDetails(): void {
    this.airlineService.getAllAirlines(1, 100).subscribe(
      (response: { data?: { pageData: AirlineDTO[] } }) => {
        const airlines = response?.data?.pageData || [];

        const groupAssociationIds = [
          ...new Set(
            airlines.flatMap((airline) =>
              airline.groupAssociations.map((ga) => ga.groupAssociationId),
            ),
          ),
        ];

        const groupRequests = groupAssociationIds.map((id) =>
          this.groupAssociationService
            .getGroupAssociationById(id)
            .pipe(catchError(() => of(null))),
        );

        forkJoin(groupRequests).subscribe({
          next: (groupResponses) => {
            const groupImageMap = new Map<number, string>();

            groupResponses.forEach((response) => {
              if (response?.success && response.data) {
                const ga = response.data;
                if (ga.groupImageHandle) {
                  groupImageMap.set(ga.groupAssociationId, ga.groupImageHandle);
                }
              }
            });

            const requests = airlines.map((airline) => {
              const airlineImage$ = this.fileService
                .getFileUri(airline.airlineImageHandle)
                .pipe(catchError(() => of(this.defaultImageUrl)));

              const allianceImageHandles = airline.groupAssociations
                .map((ga) => groupImageMap.get(ga.groupAssociationId))
                .filter((handle): handle is string => !!handle);

              return forkJoin([airlineImage$]).pipe(
                map(([airlineUrl]) => ({
                  id: airline.airlineId,
                  airlineCode: airline.airlineCode,
                  imageUrl: airlineUrl,
                  allianceImageHandles,
                })),
                catchError(() =>
                  of({
                    id: airline.airlineId,
                    airlineCode: airline.airlineCode,
                    imageUrl: this.defaultImageUrl,
                    allianceImageHandles: [],
                  }),
                ),
              );
            });

            forkJoin(requests).subscribe(
              (results) => {
                results.forEach((airline) => {
                  this.airlineDetailsMap.set(airline.id, {
                    airlineCode: airline.airlineCode,
                    imageUrl: airline.imageUrl,
                    allianceImageHandles: airline.allianceImageHandles,
                  });
                });
              },
              (error) => {
                Swal.fire({
                  title: 'Error Processing Airlines',
                  text: `Failed to process airline images: ${error.message}`,
                  icon: 'error',
                  confirmButtonText: 'OK',
                  confirmButtonColor: '#2893cc',
                });
              },
            );
          },
          error: (groupError) => {
            Swal.fire({
              title: 'Error Fetching Group Associations',
              text: `Failed to fetch group associations: ${groupError.message}`,
              icon: 'error',
              confirmButtonText: 'OK',
              confirmButtonColor: '#2893cc',
            });
          },
        });
      },
      (error) => {
        Swal.fire({
          title: 'Error Fetching Airlines',
          text: `Failed to fetch airlines: ${error.message}`,
          icon: 'error',
          confirmButtonText: 'OK',
          confirmButtonColor: '#2893cc',
        });
      },
    );
  }

  fetchAirlinePositions(workstationId: number): void {
    this.positionsService
      .getAirlinePositionsByWorkstation(workstationId)
      .subscribe((response: any) => {
        this.airlinesGrid = Array(50).fill(null);
        this.existingAirlines.clear();

        const positions: AirlinePositionDTO[] = response?.data || [];
        positions.forEach((pos) => {
          const airlineDetails = this.airlineDetailsMap.get(
            pos.airline.airlineId,
          );

          if (
            airlineDetails &&
            typeof pos.airlinePositionIndex === 'number' &&
            pos.airlinePositionIndex >= 0 &&
            pos.airlinePositionIndex < this.airlinesGrid.length
          ) {
            this.airlinesGrid[pos.airlinePositionIndex] = {
              imageUrl: airlineDetails.imageUrl,
              airlineCode: airlineDetails.airlineCode,
              airlineId: pos.airline.airlineId,
              allianceImageHandles: airlineDetails.allianceImageHandles,
            };
            this.existingAirlines.add(pos.airline.airlineId);
          }
        });
      });
  }

  onDropAirlines(event: CdkDragDrop<any>) {
    if (!this.isEditMode || !this.selectedWorkstationId) return;
    const newItem = event.item.data;

    if (newItem.id && this.existingAirlines.has(newItem.id)) {
      Swal.fire({
        title: 'Airline Already Exists',
        text: 'This airline is already on the grid. You cannot drop it again.',
        icon: 'error',
        confirmButtonColor: '#2893cc',
      });
      return;
    }

    const containerEl = event.container.element.nativeElement;
    const gridItems = containerEl.querySelectorAll(
      '.grid-item:not(.cdk-drag-placeholder)',
    );

    if (gridItems.length === 0) return;

    const dimensions = this.calculateCellDimensions(containerEl);
    if (!dimensions) return;

    const rect = containerEl.getBoundingClientRect();
    const scrollLeft = containerEl.scrollLeft;
    const scrollTop = containerEl.scrollTop;

    const x = event.dropPoint.x - rect.left + scrollLeft;
    const y = event.dropPoint.y - rect.top + scrollTop;

    const columns = Math.floor(
      (rect.width - scrollLeft) / dimensions.effectiveCellWidth,
    );
    const colIndex = Math.floor(x / dimensions.effectiveCellWidth);
    const rowIndex = Math.floor(y / dimensions.effectiveCellHeight);
    const droppedIndex = rowIndex * columns + colIndex;

    if (droppedIndex < 0 || droppedIndex >= this.airlinesGrid.length) {
      this.showInvalidPositionAlert();
      return;
    }

    if (this.airlinesGrid[droppedIndex] !== null) {
      this.showOccupiedPositionAlert();
      return;
    }

    if (event.previousContainer === event.container) {
      this.handleInternalDrop(event, droppedIndex);
    } else {
      this.handleExternalDrop(event, droppedIndex);
    }

    this.airlinesChangesMade = true;
    this.cdr.detectChanges();
  }

  private calculateCellDimensions(containerEl: HTMLElement): {
    cellWidth: number;
    cellHeight: number;
    effectiveCellWidth: number;
    effectiveCellHeight: number;
  } | null {
    const firstCell = containerEl.querySelector('.grid-item');
    if (!firstCell) return null;

    const rect = firstCell.getBoundingClientRect();
    const cellWidth = rect.width;
    const cellHeight = rect.height;

    let gapX = 0;
    const secondCell = containerEl.querySelector('.grid-item:nth-child(2)');
    if (secondCell) {
      const secondRect = secondCell.getBoundingClientRect();
      gapX = secondRect.left - (rect.left + rect.width);
    }

    let gapY = 0;
    const sixthCell = containerEl.querySelector('.grid-item:nth-child(6)');
    if (sixthCell) {
      const sixthRect = sixthCell.getBoundingClientRect();
      gapY = sixthRect.top - (rect.top + rect.height);
    }

    return {
      cellWidth,
      cellHeight,
      effectiveCellWidth: cellWidth + gapX,
      effectiveCellHeight: cellHeight + gapY,
    };
  }

  private handleInternalDrop(
    event: CdkDragDrop<any>,
    droppedIndex: number,
  ): void {
    const { item, index: oldIndex } = event.item.data;

    if (this.airlinesGrid[droppedIndex] !== null) {
      this.showOccupiedPositionAlert();
      return;
    }

    this.airlinesGrid[oldIndex] = null;
    this.airlinesGrid[droppedIndex] = item;

    this.airlinesGrid = [...this.airlinesGrid];

    this.addAirlineOperation(1, this.airlinesGrid[droppedIndex], droppedIndex);
  }

  private handleExternalDrop(
    event: CdkDragDrop<any>,
    droppedIndex: number,
  ): void {
    const newItem = event.item.data;

    this.airlinesGrid[droppedIndex] = {
      imageUrl: newItem.imageUrl,
      airlineCode: newItem.airlineCode,
      airlineId: newItem.id,
      allianceImageHandles: newItem.allianceImageHandles || [],
    };

    this.addAirlineOperation(0, this.airlinesGrid[droppedIndex], droppedIndex);
  }

  onDragStarted(event: CdkDragStart<any>): void {
    this.currentDragContainer = event.source.element.nativeElement.closest(
      '.airlines-grid-container, .access-document-grid-container',
    );
    this.scrollInterval = setInterval(() => {
      this.handleAutoScroll();
    }, 50);
  }

  onDragEnded(): void {
    if (this.scrollInterval) {
      clearInterval(this.scrollInterval);
      this.scrollInterval = null;
    }

    this.currentDragContainer = null;
  }

  onDragMoved(event: CdkDragMove<any>): void {
    this.dragPosition = event.pointerPosition;
    this.handleAutoScroll();
  }

  private handleAutoScroll(): void {
    const container = this.currentDragContainer;
    if (!container) return;

    const rect = container.getBoundingClientRect();
    const scrollSpeed = 25;
    const edgeThreshold = 50;

    if (this.dragPosition.x > rect.right - edgeThreshold) {
      container.scrollLeft += scrollSpeed;
    } else if (this.dragPosition.x < rect.left + edgeThreshold) {
      container.scrollLeft -= scrollSpeed;
    }

    if (this.dragPosition.y > rect.bottom - edgeThreshold) {
      container.scrollTop += scrollSpeed;
    } else if (this.dragPosition.y < rect.top + edgeThreshold) {
      container.scrollTop -= scrollSpeed;
    }
  }

  private showInvalidPositionAlert(): void {
    Swal.fire({
      title: 'Invalid Drop Location',
      text: 'Please drop within the valid grid positions.',
      icon: 'warning',
      confirmButtonColor: '#2893cc',
    });
  }

  private showOccupiedPositionAlert(): void {
    Swal.fire({
      title: 'Position Occupied',
      text: 'That slot is already occupied. Please choose an empty cell.',
      icon: 'warning',
      confirmButtonColor: '#2893cc',
    });
  }

  trackByFn(index: number, item: any): number {
    return item?.airlineId || index;
  }

  addAirlineOperation(
    operationType: number, // 0 = Insert, 1 = Update, 2 = Delete
    airline: any,
    positionIndex: number,
  ): void {
    if (!airline) return;
    this.airlineOperationList.push({
      operationSequenceNumber: this.airlineSequenceNumber++,
      operation: operationType,
      airlinePosition: {
        airline: { airlineId: airline?.airlineId },
        workstationId: this.selectedWorkstationId ?? 0,
        airlinePositionIndex: positionIndex,
      },
    });
    this.airlinesChangesMade = true;
  }

  confirmDeleteAirline(index: number): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this airline?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2893cc',
      cancelButtonColor: '#939597',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteAirline(index);
        Swal.fire({
          title: 'Deleted!',
          text: 'The airline has been removed from the grid.',
          icon: 'success',
          confirmButtonColor: '#2893cc',
        });
      }
    });
  }

  deleteAirline(index: number): void {
    const airline = this.airlinesGrid[index];
    if (
      airline &&
      this.selectedWorkstationId !== null &&
      airline.airlineId !== null
    ) {
      this.airlineOperationList.push({
        operationSequenceNumber: this.airlineSequenceNumber++,
        operation: 2,
        airlinePosition: {
          airline: { airlineId: airline.airlineId },
          workstationId: this.selectedWorkstationId,
          airlinePositionIndex: index,
        },
      });
      this.airlinesGrid[index] = null;
      this.airlinesChangesMade = true;
    }
  }

  saveAirlineChanges(): void {
    if (!this.airlinesChangesMade) {
      return;
    }

    Swal.fire({
      title: 'Apply Airline Changes',
      html: `
        <div style="text-align: left;">
          <div>
            <label>
              <input type="radio" name="applyTo" value="workstation" checked>
              Current Workstation
            </label>
          </div>
          <div style="margin-top: 10px;">
            <label>
              <input type="radio" name="applyTo" value="lounge">
              Apply changes to Lounges
            </label>
          </div>
          <div style="margin-top: 10px;">
            <label>
              <input type="radio" name="applyTo" value="airport">
              Apply changes to Airports
            </label>
          </div>
        </div>
      `,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: 'Proceed',
      confirmButtonColor: '#2893cc',
      preConfirm: () => {
        const selectedInput = document.querySelector(
          'input[name="applyTo"]:checked',
        ) as HTMLInputElement;
        if (!selectedInput) {
          Swal.showValidationMessage('Please select an option.');
          return;
        }
        return selectedInput.value;
      },
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        const selectedOption = result.value;
        if (selectedOption === 'workstation') {
          this.callWorkstationSave();
        } else if (selectedOption === 'lounge') {
          const dialogRef = this.dialog.open(SelectLoungeDialogComponent, {
            width: '400px',
          });
          dialogRef.afterClosed().subscribe((selectedLounges: LoungeDTO[]) => {
            if (selectedLounges && selectedLounges.length > 0) {
              this.callLoungeSave(selectedLounges);
            }
          });
        } else if (selectedOption === 'airport') {
          const dialogRef = this.dialog.open(SelectAirportDialogComponent, {
            width: '400px',
          });
          dialogRef
            .afterClosed()
            .subscribe((selectedAirports: AirportDTO[]) => {
              if (selectedAirports && selectedAirports.length > 0) {
                this.callAirportSave(selectedAirports);
              }
            });
        }
      }
    });
  }

  private callWorkstationSave(): void {
    this.loadingService.show();
    this.positionsService
      .runAirlinePositionOperations(this.airlineOperationList)
      .subscribe(
        () => {
          this.resetAirlineChanges();
          Swal.fire({
            title: 'Success',
            text: 'Airline positions saved successfully.',
            icon: 'success',
            confirmButtonColor: '#2893cc',
          });
        },
        (error) => {
          this.loadingService.hide();
          Swal.fire({
            title: 'Error',
            text: `Failed to save airline positions: ${error.message}`,
            icon: 'error',
            confirmButtonColor: '#2893cc',
          }).then(() => {
            this.resetAirlineChanges();
          });
        },
      );
  }

  private callLoungeSave(selectedLounges: LoungeDTO[]): void {
    this.loadingService.show();
    const loungeIds = selectedLounges.map((l) => l.loungeId);
    this.positionsService
      .runAirlinePositionOperationsOnLounges(
        this.airlineOperationList,
        loungeIds,
      )
      .subscribe(
        () => {
          this.resetAirlineChanges();
          Swal.fire({
            title: 'Success',
            text: 'Airline positions saved successfully for the selected lounge(s).',
            icon: 'success',
            confirmButtonColor: '#2893cc',
          });
        },
        (error) => {
          this.loadingService.hide();
          Swal.fire({
            title: 'Error',
            text: `Failed to save airline positions for lounges: ${error.message}`,
            icon: 'error',
            confirmButtonColor: '#2893cc',
          });
        },
      );
  }

  private callAirportSave(selectedAirports: AirportDTO[]): void {
    this.loadingService.show();
    const airportIds = selectedAirports.map((a) => a.airportId);
    this.positionsService
      .runAirlinePositionOperationsOnAirports(
        this.airlineOperationList,
        airportIds,
      )
      .subscribe(
        () => {
          this.resetAirlineChanges();
          Swal.fire({
            title: 'Success',
            text: 'Airline positions saved successfully for the selected airport(s).',
            icon: 'success',
            confirmButtonColor: '#2893cc',
          });
        },
        (error) => {
          this.loadingService.hide();
          Swal.fire({
            title: 'Error',
            text: `Failed to save airline positions for airports: ${error.message}`,
            icon: 'error',
            confirmButtonColor: '#2893cc',
          });
        },
      );
  }

  private resetAirlineChanges(): void {
    this.airlineOperationList = [];
    this.airlineSequenceNumber = 0;
    this.airlinesChangesMade = false;
    if (this.selectedWorkstationId) {
      this.loadAirlineGrid(this.selectedWorkstationId);
    }
    this.loadingService.hide();
  }

  cancelAirlineChanges(): void {
    this.airlineOperationList = [];
    this.airlineSequenceNumber = 0;
    this.airlinesChangesMade = false;

    if (this.selectedWorkstationId) {
      this.loadAirlineGrid(this.selectedWorkstationId);
    }
  }

  getAirlineGridClass(): string {
    return this.selectedWorkstationId ? '' : 'blurred';
  }

  onEditModeToggle(): void {
    if (!this.isEditMode && this.airlinesChangesMade) {
      Swal.fire({
        title: 'Unsaved Changes',
        text: 'You have unsaved changes. If you turn off edit mode, changes will be lost.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2893cc',
        cancelButtonColor: '#939597',
        confirmButtonText: 'Discard Changes',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          this.cancelAirlineChanges();
          this.isEditMode = false;
        } else {
          this.isEditMode = true;
        }
      });
    }
  }

  /** -------------------- ACCESS DOCUMENT GRID -------------------- */

  loadAccessDocumentGrid(workstationId: number): void {
    if (this.selectedAirlineId !== null) {
      this.fetchCardPositions(workstationId, this.selectedAirlineId);
    } else {
      this.accessDocumentsGrid = Array(35).fill(null);
    }
    this.updateConnectedDropLists();
  }

  selectAirline(airlineId: number | null, index: number): void {
    if (!this.isEditMode && airlineId !== null) {
      this.selectedAirlineId = airlineId;
      this.selectedAirlineIndex = index;

      if (
        this.selectedWorkstationId !== null &&
        this.selectedAirlineId !== null
      ) {
        this.fetchCardPositions(
          this.selectedWorkstationId,
          this.selectedAirlineId,
        );
      }
    }
  }

  fetchAccessDocumentDetails(): void {
    this.cardService.getAllCards(1, 100).subscribe(
      (cards: CardDTO[]) => {
        const requests = cards.map((card) =>
          this.fileService.getFileUri(card.cardImageHandle).pipe(
            map((url) => ({
              id: card.cardId,
              cardName: card.cardName,
              imageUrl: url,
            })),
            catchError(() =>
              of({
                id: card.cardId,
                cardName: card.cardName,
                imageUrl: this.defaultImageUrl,
              }),
            ),
          ),
        );

        forkJoin(requests).subscribe(
          (results) => {
            results.forEach((card) => {
              this.accessDocumentDetailsMap.set(card.id, {
                cardName: card.cardName,
                imageUrl: card.imageUrl,
              });
            });
          },
          (error) => {
            Swal.fire({
              title: 'Error Fetching Access Documents',
              text: `Failed to fetch card details: ${error.message}`,
              icon: 'error',
              confirmButtonText: 'OK',
              confirmButtonColor: '#2893cc',
            });
          },
        );
      },
      (error) => {
        Swal.fire({
          title: 'Error Fetching Access Documents',
          text: `Failed to fetch cards: ${error.message}`,
          icon: 'error',
          confirmButtonText: 'OK',
          confirmButtonColor: '#2893cc',
        });
      },
    );
  }

  fetchCardPositions(workstationId: number, airlineId: number): void {
    this.positionsService
      .getCardPositionsByWorkstationAndAirline(workstationId, airlineId)
      .subscribe(
        (response) => {
          const positions: CardPositionDTO[] = Array.isArray(response?.data)
            ? response.data.map((item: any) => ({
                cardId: item.cardId,
                workstationId: item.workstationId,
                cardPositionIndex: item.cardPositionIndex,
              }))
            : [];

          this.accessDocumentsGrid = Array(35).fill(null);

          positions.forEach((pos) => {
            const cardDetails = this.accessDocumentDetailsMap.get(pos.cardId);

            if (cardDetails && typeof pos.cardPositionIndex === 'number') {
              if (
                pos.cardPositionIndex >= 0 &&
                pos.cardPositionIndex < this.accessDocumentsGrid.length
              ) {
                this.accessDocumentsGrid[pos.cardPositionIndex] = {
                  imageUrl: cardDetails.imageUrl,
                  cardName: cardDetails.cardName,
                  cardId: pos.cardId,
                };
              } else {
                Swal.fire({
                  title: 'Invalid Position Index',
                  text: `Position index ${pos.cardPositionIndex} is out of bounds.`,
                  icon: 'warning',
                  confirmButtonColor: '#2893cc',
                });
              }
            } else {
              Swal.fire({
                title: 'Missing Card Details',
                text: `Details not found for card ID: ${pos.cardId}`,
                icon: 'warning',
                confirmButtonColor: '#2893cc',
              });
            }
          });
        },
        (error) => {
          Swal.fire({
            title: 'Error Fetching Card Positions',
            text: `Failed to fetch card positions: ${error.message}`,
            icon: 'error',
            confirmButtonText: 'OK',
            confirmButtonColor: '#2893cc',
          });
        },
      );
  }

  onDropAccessDocuments(event: CdkDragDrop<any>) {
    if (
      !this.isAccessDocumentEditMode ||
      !this.selectedWorkstationId ||
      !this.selectedAirlineId
    )
      return;
    const newItem = event.item.data;

    const containerEl = event.container.element.nativeElement;
    const gridItems = containerEl.querySelectorAll(
      '.grid-item:not(.cdk-drag-placeholder)',
    );
    if (gridItems.length === 0) return;

    const dimensions = this.calculateCellDimensions(containerEl);
    if (!dimensions) return;

    const rect = containerEl.getBoundingClientRect();
    const scrollLeft = containerEl.scrollLeft;
    const scrollTop = containerEl.scrollTop;
    const x = event.dropPoint.x - rect.left + scrollLeft;
    const y = event.dropPoint.y - rect.top + scrollTop;

    const columns = Math.floor(
      (rect.width - scrollLeft) / dimensions.effectiveCellWidth,
    );
    const colIndex = Math.floor(x / dimensions.effectiveCellWidth);
    const rowIndex = Math.floor(y / dimensions.effectiveCellHeight);
    const droppedIndex = rowIndex * columns + colIndex;

    if (droppedIndex < 0 || droppedIndex >= this.accessDocumentsGrid.length) {
      this.showInvalidPositionAlert();
      return;
    }

    if (this.accessDocumentsGrid[droppedIndex] !== null) {
      this.showOccupiedPositionAlert();
      return;
    }

    if (event.previousContainer === event.container) {
      this.handleInternalDropAccessDocuments(event, droppedIndex);
    } else {
      this.handleExternalDropAccessDocuments(event, droppedIndex);
    }

    this.documentsChangesMade = true;
    this.cdr.detectChanges();
  }

  handleInternalDropAccessDocuments(
    event: CdkDragDrop<any>,
    droppedIndex: number,
  ): void {
    const { item, index: oldIndex } = event.item.data;
    if (this.accessDocumentsGrid[droppedIndex] !== null) {
      this.showOccupiedPositionAlert();
      return;
    }
    this.accessDocumentsGrid[oldIndex] = null;
    this.accessDocumentsGrid[droppedIndex] = item;
    this.accessDocumentsGrid = [...this.accessDocumentsGrid];
    this.addAccessDocumentOperation(
      1,
      this.accessDocumentsGrid[droppedIndex],
      droppedIndex,
    );
  }

  handleExternalDropAccessDocuments(
    event: CdkDragDrop<any>,
    droppedIndex: number,
  ): void {
    const newItem = event.item.data;
    this.accessDocumentsGrid[droppedIndex] = {
      imageUrl: newItem.imageUrl,
      cardName: newItem.name,
      cardId: newItem.id ?? -1,
    };
    this.addAccessDocumentOperation(
      0,
      this.accessDocumentsGrid[droppedIndex],
      droppedIndex,
    );
  }

  addAccessDocumentOperation(
    operationType: number,
    document: any,
    positionIndex: number,
  ): void {
    if (!document || !this.selectedWorkstationId || !this.selectedAirlineId) {
      Swal.fire({
        title: 'Missing Required Information',
        text: 'Workstation or airline context is missing',
        icon: 'error',
        confirmButtonColor: '#2893cc',
      });
      return;
    }

    const existingOp = this.accessDocumentOperationList.find(
      (op) =>
        op.cardPosition.cardId === document.cardId &&
        op.cardPosition.cardPositionIndex === positionIndex,
    );

    if (existingOp) {
      return;
    }

    const cardPosition = {
      cardId: document?.cardId,
      workstationId: this.selectedWorkstationId ?? 0,
      airlineId: this.selectedAirlineId,
      cardPositionIndex: positionIndex,
    };

    const newOp: CardPositionOperationDTO = {
      operationSequenceNumber: this.accessDocumentSequenceNumber++,
      operation: operationType,
      cardPosition: cardPosition,
    };

    this.accessDocumentOperationList.push(newOp);
    this.documentsChangesMade = true;
  }

  saveAccessDocumentChanges(): void {
    if (!this.documentsChangesMade) {
      return;
    }
    Swal.fire({
      title: 'Apply Access Document Changes',
      html: `
      <div style="text-align: left;">
        <div>
          <label>
            <input type="radio" name="applyTo" value="workstation" checked>
            Current Workstation
          </label>
        </div>
        <div style="margin-top: 10px;">
          <label>
            <input type="radio" name="applyTo" value="lounge">
            Apply changes to Lounges
          </label>
        </div>
        <div style="margin-top: 10px;">
          <label>
            <input type="radio" name="applyTo" value="airport">
            Apply changes to Airports
          </label>
        </div>
      </div>
    `,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: 'Proceed',
      confirmButtonColor: '#2893cc',
      preConfirm: () => {
        const selectedInput = document.querySelector(
          'input[name="applyTo"]:checked',
        ) as HTMLInputElement;
        if (!selectedInput) {
          Swal.showValidationMessage('Please select an option.');
          return;
        }
        return selectedInput.value;
      },
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        const selectedOption = result.value;
        if (selectedOption === 'workstation') {
          this.callWorkstationSaveForAccessDocuments();
        } else if (selectedOption === 'lounge') {
          const dialogRef = this.dialog.open(SelectLoungeDialogComponent, {
            width: '400px',
          });
          dialogRef.afterClosed().subscribe((selectedLounges: LoungeDTO[]) => {
            if (selectedLounges && selectedLounges.length > 0) {
              this.callLoungeSaveForAccessDocuments(selectedLounges);
            }
          });
        } else if (selectedOption === 'airport') {
          const dialogRef = this.dialog.open(SelectAirportDialogComponent, {
            width: '400px',
          });
          dialogRef
            .afterClosed()
            .subscribe((selectedAirports: AirportDTO[]) => {
              if (selectedAirports && selectedAirports.length > 0) {
                this.callAirportSaveForAccessDocuments(selectedAirports);
              }
            });
        }
      }
    });
  }

  private callWorkstationSaveForAccessDocuments(): void {
    this.loadingService.show();
    this.positionsService
      .runCardPositionOperations(this.accessDocumentOperationList)
      .subscribe(
        () => {
          this.resetAccessDocumentChanges();
          Swal.fire({
            title: 'Success',
            text: 'Access document positions saved successfully.',
            icon: 'success',
            confirmButtonColor: '#2893cc',
          });
        },
        (error) => {
          this.loadingService.hide();
          Swal.fire({
            title: 'Error',
            text: `Failed to save access document positions: ${error.message}`,
            icon: 'error',
            confirmButtonColor: '#2893cc',
          });
        },
      );
  }

  private callLoungeSaveForAccessDocuments(selectedLounges: LoungeDTO[]): void {
    this.loadingService.show();
    const loungeIds = selectedLounges.map((l) => l.loungeId);
    this.positionsService
      .runCardPositionOperationsOnLounges(
        this.accessDocumentOperationList,
        loungeIds,
      )
      .subscribe(
        () => {
          this.resetAccessDocumentChanges();
          Swal.fire({
            title: 'Success',
            text: 'Access document positions saved successfully for the selected lounge(s).',
            icon: 'success',
            confirmButtonColor: '#2893cc',
          });
        },
        (error) => {
          this.loadingService.hide();
          Swal.fire({
            title: 'Error',
            text: `Failed to save access document positions for lounges: ${error.message}`,
            icon: 'error',
            confirmButtonColor: '#2893cc',
          });
        },
      );
  }

  private callAirportSaveForAccessDocuments(
    selectedAirports: AirportDTO[],
  ): void {
    this.loadingService.show();
    const airportIds = selectedAirports.map((a) => a.airportId);
    this.positionsService
      .runCardPositionOperationsOnAirports(
        this.accessDocumentOperationList,
        airportIds,
      )
      .subscribe(
        () => {
          this.resetAccessDocumentChanges();
          Swal.fire({
            title: 'Success',
            text: 'Access document positions saved successfully for the selected airport(s).',
            icon: 'success',
            confirmButtonColor: '#2893cc',
          });
        },
        (error) => {
          this.loadingService.hide();
          Swal.fire({
            title: 'Error',
            text: `Failed to save access document positions for airports: ${error.message}`,
            icon: 'error',
            confirmButtonColor: '#2893cc',
          });
        },
      );
  }

  private resetAccessDocumentChanges(): void {
    this.accessDocumentOperationList = [];
    this.accessDocumentSequenceNumber = 0;
    this.documentsChangesMade = false;
    if (this.selectedWorkstationId && this.selectedAirlineId) {
      this.fetchCardPositions(
        this.selectedWorkstationId,
        this.selectedAirlineId,
      );
    }
    this.loadingService.hide();
  }

  confirmDeleteAccessDocument(index: number): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this access document?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2893cc',
      cancelButtonColor: '#939597',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteAccessDocument(index);
        Swal.fire({
          title: 'Deleted!',
          text: 'The access document has been removed from the grid.',
          icon: 'success',
          confirmButtonColor: '#2893cc',
        });
      }
    });
  }

  deleteAccessDocument(index: number): void {
    const document = this.accessDocumentsGrid[index];
    if (document && document.cardId !== undefined) {
      this.accessDocumentOperationList.push({
        operationSequenceNumber: this.accessDocumentSequenceNumber++,
        operation: 2,
        cardPosition: {
          cardId: document.cardId,
          workstationId: this.selectedWorkstationId ?? 0,
          cardPositionIndex: index,
        },
      });
      this.accessDocumentsGrid[index] = null;
      this.documentsChangesMade = true;
    }
  }

  cancelAccessDocumentChanges(): void {
    this.accessDocumentOperationList = [];
    this.accessDocumentSequenceNumber = 0;
    this.documentsChangesMade = false;
    if (this.selectedWorkstationId && this.selectedAirlineId) {
      this.fetchCardPositions(
        this.selectedWorkstationId,
        this.selectedAirlineId,
      );
    }
  }

  onAccessDocumentEditModeToggle(): void {
    if (!this.isAccessDocumentEditMode && this.documentsChangesMade) {
      Swal.fire({
        title: 'Unsaved Changes',
        text: 'You have unsaved changes. If you turn off edit mode, changes will be lost.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2893cc',
        cancelButtonColor: '#939597',
        confirmButtonText: 'Discard Changes',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          this.cancelAccessDocumentChanges();
          this.isAccessDocumentEditMode = false;
        } else {
          this.isAccessDocumentEditMode = true;
        }
      });
    }
  }
}
