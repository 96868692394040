import { Component } from '@angular/core';
import { AuthService } from '../../services/helpers/auth.service';

@Component({
  selector: 'aims-logout',
  standalone: false,

  templateUrl: './logout.component.html',
  styleUrl: './logout.component.scss',
})
export class LogoutComponent {
  constructor(private authService: AuthService) {}

  loginAgain(): void {
    this.authService.login();
  }
}
