import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { FilesService } from '../../services/files.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'aims-alliance-logos',
  standalone: false,

  templateUrl: './alliance-logos.component.html',
  styleUrl: './alliance-logos.component.scss',
})
export class AllianceLogosComponent implements OnChanges {
  @Input() allianceImageHandles: string[] = [];
  allianceImageUrls: string[] = [];

  constructor(private fileService: FilesService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['allianceImageHandles']) {
      this.fetchAllianceImages();
    }
  }

  private fetchAllianceImages(): void {
    if (!this.allianceImageHandles?.length) {
      this.allianceImageUrls = [];
      return;
    }

    const requests = this.allianceImageHandles.map((handle) =>
      this.fileService.getFileUri(handle).pipe(catchError(() => of(null))),
    );

    forkJoin(requests).subscribe((urls) => {
      // Add type predicate to ensure TypeScript understands nulls are filtered
      this.allianceImageUrls = urls.filter(
        (url): url is string => url !== null,
      );
    });
  }
}
