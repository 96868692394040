<div class="login-container">
  <div class="login-content">
    <div class="login-info">
      <img src="assets/images/ieg_logo.png" alt="IEG Logo" class="login-logo" />
      <h1 class="login-title">
        <strong>SIGN IN</strong> WITH IEG AZURE ACCOUNT TO GET AUTHORIZATION
      </h1>
      <p class="login-text">
        Your IEG azure authorized account will give you access to AIMS Admin.
      </p>
    </div>

    <div class="login-form">
      <h2 class="login-header"><strong>SIGN IN</strong> TO YOUR IEG ACCOUNT</h2>

      <button
        mat-raised-button
        color="primary"
        class="login-btn"
        (click)="login()"
      >
        <mat-icon>login</mat-icon> Sign in with Azure AD
      </button>

      <div *ngIf="isAuthenticated" class="loading-message">
        <mat-spinner diameter="40"></mat-spinner>
        <p>Redirecting to application...</p>
      </div>
    </div>
  </div>
</div>
