import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2';

import { AirportDTO } from '../../models/dtos/airportDTO';
import { AirportService } from '../../services/airport.service';
import { CreateAirportComponent } from './create-airport/create-airport.component';
import { EditAirportComponent } from './edit-airport/edit-airport.component';
import { PaginationModel } from '../../models/pagination-model';
import { of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
  tap,
} from 'rxjs/operators';
import { SearchBarComponent } from '../../layout/search-bar/search-bar.component';
import { AirlineDTO } from '../../models/dtos/airlineDTO';

@Component({
  selector: 'aims-airports',
  templateUrl: './airports.component.html',
  styleUrls: ['./airports.component.scss'],
  standalone: false,
})
export class AirportsComponent implements OnInit, AfterViewInit {
  dataSource = new MatTableDataSource<AirportDTO>([]);
  paginationModel: PaginationModel = new PaginationModel();
  searchTerm: string = '';
  noResultsMessage: string = '';
  popupShown: boolean = false;

  @ViewChild(SearchBarComponent) searchBarComponent!: SearchBarComponent;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private airportService: AirportService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.searchTerm = params['search.terms'] || '';
      this.paginationModel.pageIndex = params['page.index'] || 0;
      this.fetchAirports();
      this.setupDynamicSearch();
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  setupDynamicSearch(): void {
    this.route.queryParams
      .pipe(
        debounceTime(300),
        distinctUntilChanged(
          (prev, curr) => prev['search.terms'] === curr['search.terms'],
        ),
      )
      .subscribe((params) => {
        const term = params['search.terms'] || '';
        if (term.length >= 2) {
          this.searchAirports(term);
        } else {
          this.fetchAllOnClear();
        }
      });
  }

  fetchAllOnClear(): void {
    this.searchTerm = '';
    this.router
      .navigate([], {
        queryParams: { 'search.terms': null, 'page.index': 0 },
        replaceUrl: true,
      })
      .then(() => {
        this.fetchAirports();
      });
  }

  searchAirports(term: string): void {
    this.noResultsMessage = '';
    this.popupShown = false;

    this.airportService
      .searchAirportsByKeyword(term)
      .pipe(
        tap((response: any) => {
          this.dataSource.data = response?.data || [];
          this.paginationModel.totalCount = response?.data?.length || 0;

          if (this.dataSource.data.length === 0 && !this.popupShown) {
            this.noResultsMessage =
              'No airports are available for this search term.';
            this.popupShown = true;
            Swal.fire({
              title: 'No Results',
              text: this.noResultsMessage,
              icon: 'info',
              confirmButtonText: 'OK',
              confirmButtonColor: '#2893cc',
            }).then(() => {
              this.refresh();
            });
          }
        }),
      )
      .subscribe(
        () => this.dataSource._updateChangeSubscription(),
        (error) => {
          this.handleFetchError('searching airports', error);
        },
      );
  }

  fetchAirports(): void {
    const pageIndex = this.paginationModel.pageIndex + 1;
    const pageSize = this.paginationModel.pageSize;

    this.noResultsMessage = '';
    this.popupShown = false;

    this.airportService
      .getAirports(pageIndex, pageSize)
      .pipe(
        catchError((error) => {
          this.airportService.showError(
            'Error fetching airports',
            error.message,
          );
          return of({ data: { pageData: [], totalCount: 0 } });
        }),
      )
      .subscribe((response) => {
        if (response.data) {
          this.dataSource.data = response.data?.pageData || [];
          this.paginationModel.totalCount = response?.data?.totalCount || 0;
          if (this.dataSource.data.length === 0) {
            this.noResultsMessage = 'No airports are available.';
          }
        }
      });
  }

  handleFetchError(action: string, error: any): void {
    Swal.fire({
      title: `Error ${action}`,
      text: `There was an error: ${error.message}`,
      icon: 'error',
      confirmButtonText: 'OK',
      confirmButtonColor: '#2893cc',
    });
  }

  onSearchResults(data: { results: AirportDTO[]; searchTerm: string }): void {
    this.searchTerm = data.searchTerm;
    this.dataSource.data = data.results;
    this.paginationModel.totalCount = data.results.length;
    this.noResultsMessage =
      data.results.length === 0
        ? 'No airports are available for this search term.'
        : '';
    this.updateQueryParams();
  }

  updateQueryParams(): void {
    this.router.navigate([], {
      queryParams: { 'search.terms': this.searchTerm || null, 'page.index': 0 },
      replaceUrl: true,
    });
  }

  onPageChange(event: { pageIndex: number; pageSize: number }): void {
    this.paginationModel.pageIndex = event.pageIndex;
    this.paginationModel.pageSize = event.pageSize;
    this.fetchAirports();
  }

  onReset() {
    this.searchTerm = '';
    this.refresh();
  }

  refresh(): void {
    this.searchTerm = '';
    this.noResultsMessage = '';
    this.paginationModel.pageIndex = 0;

    this.router
      .navigate([], {
        queryParams: { 'search.terms': null, 'page.index': 0 },
        replaceUrl: true,
      })
      .then(() => {
        this.fetchAirports();
        this.searchBarComponent.clearSearch();
      });
  }

  addNew(): void {
    const dialogRef = this.dialog.open(CreateAirportComponent);

    dialogRef.afterClosed().subscribe((result: AirportDTO) => {
      if (result) {
        this.dataSource.data.push(result);
        this.dataSource._updateChangeSubscription();
        this.fetchAirports();
      }
    });
  }

  deleteAirport(airport: AirportDTO): void {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2893cc',
      cancelButtonColor: '#939597',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.airportService.removeAirport(airport.airportId).subscribe(
          () => {
            Swal.fire({
              title: 'Deleted!',
              text: 'The airport has been deleted.',
              icon: 'success',
              confirmButtonColor: '#2893cc',
              confirmButtonText: 'OK',
            }).then(() => {
              this.fetchAirports();
            });
          },
          (error) => {
            this.airportService.showError(
              'Error Deleting the airport',
              error.message,
            );
          },
        );
      }
    });
  }

  openEditDialog(airport: AirportDTO): void {
    const dialogRef = this.dialog.open(EditAirportComponent, {
      width: '480px',
      data: airport,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.airportService
          .updateAirport(result)
          .subscribe((updatedAirport) => {
            const index = this.dataSource.data.findIndex(
              (p) => p.airportId === updatedAirport.airportId,
            );
            if (index !== -1) {
              this.dataSource.data[index] = updatedAirport;
              this.dataSource._updateChangeSubscription();
            }
          });
      }
    });
  }

  redirectToAirportDetails(airportId: number): void {
    this.router.navigate(['/app/airports', airportId]);
  }
}
