<div class="card-validation-container">
  <ng-container *ngIf="showFullContent">
    <div class="header-container">
      <div class="header-content">
        <h2 class="header-title">Card Validation</h2>
        <p class="description">
          List of card validations with key information and actions.
        </p>
      </div>
      <button
        mat-raised-button
        color="primary"
        (click)="redirectToCardValidationPrograms()"
      >
        Go To Card Validation Programs
      </button>
    </div>

    <div class="filters-container">
      <div class="actions">
        <button mat-icon-button (click)="addNewCardValidation()">
          <mat-icon>add</mat-icon>
        </button>
        <button mat-icon-button (click)="refresh()">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>

      <div class="filters-row">
        <form [formGroup]="filterForm" class="filter-form">
          <aims-searchable-select
            [label]="'Airport'"
            [placeholder]="'Search or Select Airport'"
            [options]="airports"
            [displayFn]="displayAirportName"
            [control]="airportSearchControl"
            (optionSelected)="onSelectAirport($event)"
          ></aims-searchable-select>

          <aims-searchable-select
            [label]="'Lounge'"
            [placeholder]="'Search Lounge'"
            [options]="fetchedLounges"
            [displayFn]="displayLoungeName"
            [control]="loungeSearchControl"
            (optionSelected)="onSelectLounge($event)"
          ></aims-searchable-select>

          <aims-searchable-select
            [label]="'Workstation'"
            [placeholder]="'Search Workstation'"
            [options]="fetchedWorkstations"
            [displayFn]="displayWorkstationName"
            [control]="workstationSearchControl"
            (optionSelected)="onSelectWorkstation($event)"
          ></aims-searchable-select>
        </form>
        <button
          mat-icon-button
          (click)="clearLeftFilters()"
          class="clear-filters"
        >
          <mat-icon>minimize</mat-icon>
        </button>
      </div>

      <div class="filters-row">
        <form [formGroup]="filterForm" class="filter-form">
          <aims-searchable-select
            [label]="'Airline'"
            [placeholder]="'Search or Select Airline'"
            [options]="airlines"
            [displayFn]="displayAirlineName"
            [control]="airlineSearchControl"
            (optionSelected)="onSelectAirline($event)"
          ></aims-searchable-select>

          <aims-searchable-select
            [label]="'Access Document'"
            [placeholder]="'Search or Select Access Document'"
            [options]="cards"
            [displayFn]="displayCardName"
            [control]="cardSearchControl"
            (optionSelected)="onSelectCard($event)"
          ></aims-searchable-select>
        </form>
        <button
          mat-icon-button
          (click)="clearMiddleFilters()"
          class="clear-filters"
        >
          <mat-icon>minimize</mat-icon>
        </button>
      </div>
    </div>
  </ng-container>

  <div class="table-responsive">
    <table mat-table class="mat-elevation-z8" [dataSource]="dataSource">
      <ng-container matColumnDef="cardName">
        <th mat-header-cell *matHeaderCellDef>Access Document Name</th>
        <td mat-cell *matCellDef="let cardValidation">
          <span>{{ cardValidation.cardName }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="workstationName">
        <th mat-header-cell *matHeaderCellDef>Workstation Name</th>
        <td mat-cell *matCellDef="let cardValidation">
          <span>{{ cardValidation.workstationName }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="airlineName">
        <th mat-header-cell *matHeaderCellDef>Airline Name</th>
        <td mat-cell *matCellDef="let cardValidation">
          <span>{{ cardValidation.airlineName }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="validationProgramName">
        <th mat-header-cell *matHeaderCellDef>Validation Program</th>
        <td mat-cell *matCellDef="let cardValidation">
          <span>{{
            cardValidation.cardValidationProgram.validationProgram
          }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let cardValidation">
          <button
            mat-icon-button
            (click)="
              editCardValidation(cardValidation); $event.stopPropagation()
            "
            aria-label="Edit card validation"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="
              deleteCardValidation(cardValidation); $event.stopPropagation()
            "
            aria-label="Delete card validation"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="[
          'cardName',
          'workstationName',
          'airlineName',
          'validationProgramName',
          'action',
        ]"
      ></tr>
      <tr
        mat-row
        *matRowDef="
          let row;
          columns: [
            'cardName',
            'workstationName',
            'airlineName',
            'validationProgramName',
            'action',
          ]
        "
        (click)="redirectToCardValidationDetails(row.cardValidationId)"
      ></tr>
    </table>
  </div>

  <div class="paginator-container">
    <aims-query-page
      [paginationModel]="paginationModel"
      (pageChange)="onPageChange($event)"
    ></aims-query-page>
  </div>
</div>
