<div class="card-details-container">
  <div class="global-card-header">
    <div class="header-with-back">
      <button
        mat-icon-button
        (click)="navigateToAccessDocuments()"
        class="back-button"
      >
        <mat-icon>arrow_back</mat-icon>
      </button>
      <div class="header-titles">
        <h1 class="global-card-title">{{ cardData?.cardName }}</h1>
        <h2 class="global-card-subtitle">{{ airlineCode }}</h2>
      </div>
    </div>
    <div class="tab-nav">
      <span class="tab-link active">Overview</span>
      <div class="action-buttons">
        <button mat-icon-button (click)="editCard()">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button (click)="deleteCard()">
          <mat-icon>delete</mat-icon>
        </button>
        <button mat-icon-button (click)="refresh()">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <!-- Card Sections Below -->
  <div class="card-overview">
    <ng-container *ngIf="cardData">
      <div class="card-row">
        <!-- Card 1: Basic Info -->
        <div class="basic-info-card card">
          <div class="card-header">
            <img
              *ngIf="imageUrl; else defaultImage"
              [src]="imageUrl"
              alt="Card Image"
              class="card-image"
            />
            <ng-template #defaultImage>
              <img
                src="../../../../assets/images/default-access-doc-image.png"
                alt="Default Image"
                class="card-image"
              />
            </ng-template>
          </div>
          <div class="card-body">
            <div class="detail-item">
              <span class="detail-title">Access Document ID:</span>
              {{ cardData.cardId }}
            </div>
            <div class="detail-item">
              <span class="detail-title">Access Document Name:</span>
              {{ cardData.cardName }}
            </div>
            <div class="detail-item">
              <span class="detail-title">Swipe Type:</span>
              {{
                cardData.cardSwipeType !== undefined
                  ? getSwipeTypeName(cardData.cardSwipeType)
                  : "Unknown"
              }}
            </div>
            <div class="detail-item">
              <span class="detail-title">Valid To:</span>
              {{ cardData.validTo | date: "mediumDate" }}
            </div>
          </div>
          <div class="card-body">
            <div class="detail-item">
              <span class="detail-title">Grant Access:</span>
              {{ cardData.hide ? "Yes" : "No" }}
            </div>
          </div>
        </div>

        <!-- Card 2: Fields Info -->
        <div class="fields-info-card card">
          <div class="card-header">
            <h3><strong>Fields Information</strong></h3>
          </div>
          <div class="card-body">
            <div class="detail-item">
              <span class="detail-title">Passenger Mandatory Fields:</span>
              <ul>
                <li
                  *ngFor="
                    let field of getFieldNamesFromInteger(
                      cardData?.passengerMandatoryFields || 0,
                      passengerFields
                    )
                  "
                >
                  {{ field }}
                </li>
              </ul>
            </div>
            <div class="detail-item">
              <span class="detail-title">Guest Mandatory Fields:</span>
              <ul>
                <li
                  *ngFor="
                    let field of getFieldNamesFromInteger(
                      cardData?.guestMandatoryFields || 0,
                      guestFields
                    )
                  "
                >
                  {{ field }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="card-row">
        <!-- Card 4: Additional Details -->
        <div class="additional-details-card card">
          <div class="card-header">
            <h3><strong>Additional Details</strong></h3>
          </div>
          <div class="card-body" *ngIf="cardData">
            <div class="detail-item">
              <span class="detail-title">Accepted Guest Types:</span>
              {{
                cardData.guestTypes !== undefined
                  ? getGuestTypesDescription(cardData.guestTypes)
                  : "Unknown"
              }}
            </div>
            <div
              class="detail-item"
              *ngIf="cardData?.maxFamilyGuests !== undefined"
            >
              <span class="detail-title">Max Family Guests:</span>
              {{ cardData.maxFamilyGuests }}
            </div>
            <div
              class="detail-item"
              *ngIf="cardData?.maxComplimentaryGuests !== undefined"
            >
              <span class="detail-title">Max Complimentary Guests:</span>
              {{ cardData.maxComplimentaryGuests }}
            </div>
            <div
              class="detail-item"
              *ngIf="cardData?.maxPayGuests !== undefined"
            >
              <span class="detail-title">Max Pay Guests:</span>
              {{ cardData.maxPayGuests }}
            </div>
            <div
              class="detail-item"
              *ngIf="cardData?.guestPayAmount !== undefined"
            >
              <span class="detail-title">Guest Pay Amount:</span>
              {{ cardData.guestPayAmount }} {{ cardData.guestPayCurrency }}
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
