<div class="dialog-container">
  <aims-loading></aims-loading>
  <button mat-icon-button class="close-button" (click)="onCancel()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-vertical-stepper [linear]="true" #stepper>
    <!-- Step 1: Airline Information using aims-searchable-select -->
    <mat-step [stepControl]="cardForm.controls['airlineId']">
      <ng-template matStepLabel>Airline Information</ng-template>
      <form [formGroup]="cardForm">
        <aims-searchable-select
          label="Airline"
          placeholder="Select Airline"
          [options]="airlineOptions"
          [control]="airlineControl"
          [displayFn]="displayAirlineName.bind(this)"
          (optionSelected)="onAirlineSelected($event)"
        ></aims-searchable-select>
        <div class="button-container">
          <button mat-raised-button color="primary" matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>

    <!-- Step 2: Card Details -->
    <mat-step [stepControl]="cardForm.controls['cardName']">
      <ng-template matStepLabel>Card Details</ng-template>
      <form [formGroup]="cardForm">
        <div class="input-group">
          <mat-form-field appearance="fill">
            <mat-label>Card Name</mat-label>
            <input
              matInput
              formControlName="cardName"
              type="text"
              placeholder="Enter card name"
            />
          </mat-form-field>
        </div>
        <div class="input-group">
          <mat-form-field appearance="fill">
            <mat-label>Card Swipe Type</mat-label>
            <mat-select formControlName="cardSwipeType">
              <mat-option [value]="1">Card</mat-option>
              <mat-option [value]="2">Boarding Pass</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="input-group">
          <mat-form-field appearance="fill">
            <mat-label>Valid To</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              formControlName="validTo"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="input-group">
          <div *ngIf="currentImageHandle">
            <mat-form-field appearance="fill">
              <mat-label>Card Image Handle</mat-label>
              <input
                matInput
                formControlName="cardImageHandle"
                type="text"
                [value]="currentImageHandle"
                readonly
              />
              <button mat-icon-button matSuffix (click)="onDeleteImageHandle()">
                <mat-icon>clear</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div *ngIf="!currentImageHandle">
            <input type="file" (change)="onFileSelected($event)" />
          </div>
        </div>
        <div class="button-container">
          <button mat-raised-button color="warn" matStepperPrevious>
            Back
          </button>
          <button mat-raised-button color="primary" matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>

    <!-- Step 3: Guest Configuration -->
    <mat-step [stepControl]="cardForm.controls['guestTypes']">
      <ng-template matStepLabel>Guest Configuration</ng-template>
      <form [formGroup]="cardForm">
        <div class="input-group">
          <mat-checkbox
            formControlName="payGuests"
            (change)="onGuestTypeChange()"
            >Pay Guests</mat-checkbox
          >
          <mat-checkbox
            formControlName="familyGuests"
            (change)="onGuestTypeChange()"
            >Family Guests</mat-checkbox
          >
          <mat-checkbox
            formControlName="complimentaryGuests"
            (change)="onGuestTypeChange()"
            >Complimentary Guests</mat-checkbox
          >
          <input type="hidden" formControlName="guestTypes" />
        </div>
        <div *ngIf="cardForm.get('payGuests')?.value">
          <mat-form-field appearance="fill">
            <mat-label>Max Pay Guests</mat-label>
            <input matInput formControlName="maxPayGuests" type="number" />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Guest Pay Amount</mat-label>
            <input matInput formControlName="guestPayAmount" type="number" />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Guest Pay Currency</mat-label>
            <mat-select formControlName="guestPayCurrency">
              <mat-option *ngFor="let cur of validCurrencies" [value]="cur">{{
                cur
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="cardForm.get('familyGuests')?.value">
          <mat-form-field appearance="fill">
            <mat-label>Max Family Guests</mat-label>
            <input matInput formControlName="maxFamilyGuests" type="number" />
          </mat-form-field>
        </div>
        <div *ngIf="cardForm.get('complimentaryGuests')?.value">
          <mat-form-field appearance="fill">
            <mat-label>Max Complimentary Guests</mat-label>
            <input
              matInput
              formControlName="maxComplimentaryGuests"
              type="number"
            />
          </mat-form-field>
        </div>
        <div class="button-container">
          <button mat-raised-button color="warn" matStepperPrevious>
            Back
          </button>
          <button mat-raised-button color="primary" matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>

    <!-- Step 4: Passenger Fields -->
    <mat-step>
      <ng-template matStepLabel>Passenger Fields</ng-template>
      <form [formGroup]="cardForm">
        <div *ngFor="let field of passengerFields" class="field-group">
          <label>{{ field.label }}</label>
          <mat-radio-group [formControlName]="'passenger-' + field.key">
            <mat-radio-button [value]="0">Disabled</mat-radio-button>
            <mat-radio-button [value]="1">Mandatory</mat-radio-button>
            <mat-radio-button [value]="2">Optional</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="button-container">
          <button mat-raised-button color="warn" matStepperPrevious>
            Back
          </button>
          <button
            mat-raised-button
            color="primary"
            (click)="computeFieldValues(); stepper.next()"
          >
            Next
          </button>
        </div>
      </form>
    </mat-step>

    <!-- Step 5: Guest Fields -->
    <mat-step>
      <ng-template matStepLabel>Guest Fields</ng-template>
      <form [formGroup]="cardForm">
        <div *ngFor="let field of guestFields" class="field-group">
          <label>{{ field.label }}</label>
          <mat-radio-group [formControlName]="'guest-' + field.key">
            <mat-radio-button [value]="0">Disabled</mat-radio-button>
            <mat-radio-button [value]="1">Mandatory</mat-radio-button>
            <mat-radio-button [value]="2">Optional</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="button-container">
          <button mat-raised-button color="warn" matStepperPrevious>
            Back
          </button>
          <button
            mat-raised-button
            color="primary"
            (click)="computeFieldValues(); stepper.next()"
          >
            Next
          </button>
        </div>
      </form>
    </mat-step>

    <!-- Step 5:  Hide Card -->
    <mat-step [stepControl]="cardForm.controls['grantAccess']">
      <ng-template matStepLabel>Hide Card</ng-template>
      <form [formGroup]="cardForm">
        <div class="input-group">
          <mat-slide-toggle formControlName="hide">Hide</mat-slide-toggle>
        </div>
        <div class="button-container">
          <button mat-raised-button color="warn" matStepperPrevious>
            Back
          </button>
          <button mat-raised-button color="primary" matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>

    <!-- Step 6: Review and Submit -->
    <mat-step>
      <ng-template matStepLabel>Review and Submit</ng-template>
      <p>Please review all card details and confirm submission.</p>
      <ul>
        <li>
          <strong>Airline:</strong>
          {{ getAirlineName(cardForm.get("airlineId")?.value) }}
        </li>
        <li>
          <strong>Card Name:</strong>
          {{ cardForm.get("cardName")?.value }}
        </li>
        <li>
          <strong>Card Swipe Type:</strong>
          {{ getSwipeTypeName(cardForm.get("cardSwipeType")?.value) }}
        </li>
        <li>
          <strong>Valid To:</strong> {{ cardForm.get("validTo")?.value | date }}
        </li>
        <li>
          <strong>Card Image URL:</strong>
          {{ cardForm.get("cardImageHandle")?.value || "Default" }}
        </li>
        <li>
          <strong>Guest Types:</strong>
          {{ getGuestType(cardForm.get("guestTypes")?.value) }}
        </li>
        <li>
          <strong>Max Family Guests:</strong>
          {{ cardForm.get("maxFamilyGuests")?.value }}
        </li>
        <li>
          <strong>Max Complimentary Guests:</strong>
          {{ cardForm.get("maxComplimentaryGuests")?.value }}
        </li>
        <li>
          <strong>Max Pay Guests:</strong>
          {{ cardForm.get("maxPayGuests")?.value }}
        </li>
        <li>
          <strong>Guest Pay Currency:</strong>
          {{ cardForm.get("guestPayCurrency")?.value }}
        </li>
        <li>
          <strong>Passenger Mandatory Fields:</strong>
          <ul>
            <li *ngFor="let field of getMandatoryFields('passenger')">
              {{ field.label }}
            </li>
          </ul>
        </li>
        <li>
          <strong>Guest Mandatory Fields:</strong>
          <ul>
            <li *ngFor="let field of getMandatoryFields('guest')">
              {{ field.label }}
            </li>
          </ul>
        </li>
        <li>
          <strong>Hide:</strong>
          {{ cardForm.get("hide")?.value ? "Yes" : "No" }}
        </li>
      </ul>
      <div class="button-container">
        <button mat-raised-button color="warn" matStepperPrevious>Back</button>
        <button mat-raised-button color="primary" (click)="onSubmit()">
          Submit
        </button>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</div>
