import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { LoungeDTO } from '../models/dtos/loungeDTO';
import { ResponseDTO } from '../models/dtos/responseDTO';
import { LoungeHourDTO } from '../models/dtos/loungeHourDTO';
import { BaseService } from './helpers/base.service';
import { ConfigService } from './helpers/config.service';
import { LoadingService } from './helpers/loading.service';
import { WorkstationDTO } from '../models/dtos/workstationDTO';

@Injectable({
  providedIn: 'root',
})
export class LoungeService extends BaseService {
  private apiUrl: string;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private loadingService: LoadingService,
  ) {
    super();
    this.apiUrl = this.configService.getApiUrl() + '/Lounges';
  }

  searchLoungesByKeyword(keyword: string): Observable<LoungeDTO[]> {
    return this.http
      .get<LoungeDTO[]>(`${this.apiUrl}/SearchByKeyword`, {
        params: { keyword },
      })
      .pipe(catchError(this.handleError));
  }

  getLoungeById(loungeId: number): Observable<LoungeDTO> {
    return this.http
      .get<LoungeDTO>(`${this.apiUrl}/GetById/${loungeId}`)
      .pipe(catchError(this.handleError.bind(this)));
  }

  getLounges(
    index: number = 1,
    size: number = 10,
  ): Observable<ResponseDTO<LoungeDTO>> {
    const params = new HttpParams()
      .set('Index', index.toString())
      .set('Size', size.toString());
    this.loadingService.setLoading(true);

    return this.http
      .get<ResponseDTO<LoungeDTO>>(`${this.apiUrl}/GetAll`, { params })
      .pipe(
        map((response) => response),
        catchError(this.handleError.bind(this)),
        tap(() => this.loadingService.setLoading(false)),
      );
  }

  getLoungeWorkstations(loungeId: number): Observable<WorkstationDTO[]> {
    return this.http
      .get<WorkstationDTO[]>(`${this.apiUrl}/GetWorkstations/${loungeId}`)
      .pipe(catchError(this.handleError.bind(this)));
  }

  addLounge(lounge: LoungeDTO): Observable<LoungeDTO> {
    return this.http
      .post<LoungeDTO>(`${this.apiUrl}/Add`, lounge)
      .pipe(catchError(this.handleError.bind(this)));
  }

  updateLounge(lounge: LoungeDTO): Observable<LoungeDTO> {
    return this.http
      .patch<LoungeDTO>(`${this.apiUrl}/Update`, lounge)
      .pipe(catchError(this.handleError.bind(this)));
  }

  removeLounge(loungeId: number): Observable<any> {
    return this.http
      .delete(`${this.apiUrl}/Delete/${loungeId}`)
      .pipe(catchError(this.handleError.bind(this)));
  }

  // getLoungeRules(loungeId: number): Observable<LoungeRuleDTO[]> {
  //   return this.http.get<LoungeRuleDTO[]>(`${this.apiUrl}/GetRules/${loungeId}`)
  //     .pipe(catchError(this.handleError));
  // }
  //
  // addLoungeRules(rules: LoungeRuleDTO[]): Observable<LoungeRuleDTO[]> {
  //   return this.http.post<LoungeRuleDTO[]>(`${this.apiUrl}/AddRules`, rules)
  //     .pipe(catchError(this.handleError));
  // }
  //
  // updateLoungeRules(rules: LoungeRuleDTO[]): Observable<LoungeRuleDTO[]> {
  //   return this.http.patch<LoungeRuleDTO[]>(`${this.apiUrl}/UpdateRules`, rules)
  //     .pipe(catchError(this.handleError));
  // }
  //
  // removeLoungeRule(ruleId: number): Observable<any> {
  //   return this.http.delete(`${this.apiUrl}/DeleteRule/${ruleId}`)
  //     .pipe(catchError(this.handleError));
  // }
  //
  // removeLoungeRules(loungeId: number): Observable<any> {
  //   return this.http.delete(`${this.apiUrl}/DeleteRules/${loungeId}`)
  //     .pipe(catchError(this.handleError));
  // }

  getLoungeHours(loungeId: number): Observable<LoungeHourDTO[]> {
    return this.http
      .get<LoungeHourDTO[]>(`${this.apiUrl}/GetWorkingHours/${loungeId}`)
      .pipe(catchError(this.handleError.bind(this)));
  }

  addLoungeHours(hours: LoungeHourDTO[]): Observable<LoungeHourDTO[]> {
    return this.http
      .post<LoungeHourDTO[]>(`${this.apiUrl}/AddWorkingHours`, hours)
      .pipe(catchError(this.handleError.bind(this)));
  }

  updateLoungeHours(hours: LoungeHourDTO[]): Observable<LoungeHourDTO[]> {
    return this.http
      .patch<LoungeHourDTO[]>(`${this.apiUrl}/UpdateWorkingHours`, hours)
      .pipe(catchError(this.handleError.bind(this)));
  }

  removeLoungeHour(hourId: number): Observable<any> {
    return this.http
      .delete(`${this.apiUrl}/DeleteWorkingHour/${hourId}`)
      .pipe(catchError(this.handleError.bind(this)));
  }

  removeLoungeHours(loungeId: number): Observable<any> {
    return this.http
      .delete(`${this.apiUrl}/DeleteWorkingHours/${loungeId}`)
      .pipe(catchError(this.handleError.bind(this)));
  }
}
