<div class="logout-container">
  <div class="logout-card">
    <mat-icon class="logout-icon">logout</mat-icon>
    <h2 class="logout-header">YOU HAVE BEEN <strong>LOGGED OUT</strong></h2>
    <p class="logout-text">
      Click <a href="#" (click)="loginAgain()">here</a> to return to the
      application if you have not been redirected.
    </p>
  </div>
</div>
