import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PaginationModel } from '../../../models/pagination-model';

@Component({
  selector: 'aims-query-page',
  templateUrl: './query-page.component.html',
  styleUrl: './query-page.component.scss',
  standalone: false,
})
export class QueryPageComponent {
  @Input() paginationModel: PaginationModel = new PaginationModel();
  @Output() pageChange: EventEmitter<{ pageIndex: number; pageSize: number }> =
    new EventEmitter();

  constructor() {}

  onPageChange(event: any): void {
    this.pageChange.emit({
      pageIndex: event.pageIndex,
      pageSize: event.pageSize,
    });
  }
}
