<div class="partners-container">
  <div class="header-container">
    <div class="header-content">
      <h2 class="header-title">Partners</h2>
      <p class="description">
        List of partners with key information and actions.
      </p>
    </div>
  </div>

  <aims-layout-toolbar>
    <button mat-icon-button (click)="addNew()">
      <mat-icon>add</mat-icon>
    </button>
    <button mat-icon-button (click)="refresh()">
      <mat-icon>refresh</mat-icon>
    </button>
  </aims-layout-toolbar>

  <aims-query-layout>
    <aims-search-bar
      left
      componentName="partners"
      matTooltip="Enter at least 2 characters for search"
      matTooltipPosition="above"
      (termsChanged$)="onSearchResults($event)"
      (reset$)="onReset()"
      (clearTerms$)="fetchAllOnClear()"
    ></aims-search-bar>
  </aims-query-layout>

  <div class="table-responsive">
    <table mat-table class="mat-elevation-z8" [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let partner">
          <div class="name-and-image-container">
            <img
              [src]="getImageUri(partner.airlineId) || defaultImageUrl"
              alt="Partner Image"
              class="partner-image"
            />
            <span class="partner-name">{{ partner.airlineName }}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef>Code</th>
        <td mat-cell *matCellDef="let partner">{{ partner.airlineCode }}</td>
      </ng-container>

      <ng-container matColumnDef="airlineId">
        <th mat-header-cell *matHeaderCellDef>Partner ID</th>
        <td mat-cell *matCellDef="let partner">{{ partner.airlineId }}</td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let partner">
          <button
            mat-icon-button
            (click)="openEditDialog(partner); $event.stopPropagation()"
            aria-label="Edit partner"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="deletePartner(partner); $event.stopPropagation()"
            aria-label="Delete partner"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="['name', 'code', 'airlineId', 'action']"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['name', 'code', 'airlineId', 'action']"
        (click)="redirectToPartnerDetails(row.airlineId)"
      ></tr>
    </table>
  </div>

  <div class="paginator-container">
    <aims-query-page
      [paginationModel]="paginationModel"
      (pageChange)="onPageChange($event)"
    ></aims-query-page>
  </div>
</div>
