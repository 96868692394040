import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2';

import { forkJoin, of } from 'rxjs';
import {
  tap,
  finalize,
  map,
  catchError,
  distinctUntilChanged,
  debounceTime,
} from 'rxjs/operators';
import { PaginationModel } from '../../../models/pagination-model';
import { GroupAssociationDTO } from '../../../models/dtos/groupAssociationDTO';
import { GroupAssociationService } from '../../../services/group-association.service';
import { FilesService } from '../../../services/files.service';
import { SearchBarComponent } from '../../../layout/search-bar/search-bar.component';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupAssociationDialogComponent } from './group-association-dialog/group-association-dialog.component';

@Component({
  selector: 'aims-group-associations',
  templateUrl: './group-associations.component.html',
  styleUrls: ['./group-associations.component.scss'],
  standalone: false,
})
export class GroupAssociationsComponent implements OnInit, AfterViewInit {
  dataSource = new MatTableDataSource<GroupAssociationDTO>([]);
  paginationModel: PaginationModel = new PaginationModel();
  imageUrls: Map<number, string> = new Map();
  loadingImages: boolean = true;
  defaultImageUrl: string = '../../../../assets/images/image-icon.png';
  searchTerm: string = '';
  noResultsMessage: string = '';
  popupShown: boolean = false;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(SearchBarComponent) searchBarComponent!: SearchBarComponent;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private groupAssociationService: GroupAssociationService,
    private fileService: FilesService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.searchTerm = params['search.terms'] || '';
      this.paginationModel.pageIndex = params['page.index'] || 0;
      this.fetchGroupAssociations();
      this.setupDynamicSearch();
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  setupDynamicSearch(): void {
    this.route.queryParams
      .pipe(
        debounceTime(300),
        distinctUntilChanged(
          (prev, curr) => prev['search.terms'] === curr['search.terms'],
        ),
      )
      .subscribe((params) => {
        const term = params['search.terms'] || '';
        if (term.length >= 3) {
          this.searchGroupAssociations(term);
        } else {
          this.fetchAllOnClear();
        }
      });
  }

  fetchAllOnClear(): void {
    this.searchTerm = '';
    this.router
      .navigate([], {
        queryParams: { 'search.terms': null, 'page.index': 0 },
        replaceUrl: true,
      })
      .then(() => {
        this.fetchGroupAssociations();
      });
  }

  searchGroupAssociations(term: string): void {
    this.loadingImages = true;
    this.noResultsMessage = '';
    this.popupShown = false;

    this.groupAssociationService
      .searchGroupAssociationsByKeyword(term)
      .pipe(
        tap((response: any) => {
          this.dataSource.data = response?.data || [];
          this.paginationModel.totalCount = response?.data?.length || 0;

          if (this.dataSource.data.length === 0 && !this.popupShown) {
            this.noResultsMessage =
              'No group associations are available for this search term.';
            this.popupShown = true;
            Swal.fire({
              title: 'No Results',
              text: this.noResultsMessage,
              icon: 'info',
              confirmButtonText: 'OK',
              confirmButtonColor: '#2893cc',
            }).then(() => {
              this.refresh();
            });
          }
        }),
        finalize(() => (this.loadingImages = false)),
      )
      .subscribe(
        () => this.dataSource._updateChangeSubscription(),
        (error) => {
          this.loadingImages = false;
          this.handleFetchError('searching group associations', error);
        },
      );
  }

  fetchGroupAssociations(): void {
    const pageIndex = this.paginationModel.pageIndex + 1;
    const pageSize = this.paginationModel.pageSize;

    this.loadingImages = true;
    this.noResultsMessage = '';
    this.popupShown = false;

    this.groupAssociationService
      .getAllGroupAssociations(pageIndex, pageSize)
      .pipe(
        tap((response: any) => {
          this.dataSource.data = response.data.pageData;
          this.paginationModel.totalCount = response.data.totalCount;
          if (this.dataSource.data.length === 0) {
            this.noResultsMessage = 'No group associations are available.';
          }
        }),
        finalize(() => (this.loadingImages = false)),
      )
      .subscribe(
        (response: any) => {
          const requests = this.dataSource.data
            .filter((group) => group.groupImageHandle)
            .map((group) =>
              this.fileService
                .getFileUri(group.groupImageHandle ?? this.defaultImageUrl)
                .pipe(
                  map((url) => ({
                    groupAssociationId: group.groupAssociationId,
                    url,
                  })),
                  catchError(() =>
                    of({
                      groupAssociationId: group.groupAssociationId,
                      url: this.defaultImageUrl,
                    }),
                  ),
                ),
            );

          if (requests.length > 0) {
            forkJoin(requests).subscribe((results) => {
              results.forEach((result) => {
                this.imageUrls.set(result.groupAssociationId, result.url);
              });
            });
          }
        },
        (error) => {
          this.handleFetchError('fetching group associations', error);
        },
      );
  }

  handleFetchError(action: string, error: any): void {
    this.loadingImages = false;
    Swal.fire({
      title: `Error ${action}`,
      text: `There was an error: ${error.message}`,
      icon: 'error',
      confirmButtonText: 'OK',
      confirmButtonColor: '#2893cc',
    });
  }

  onSearchResults(data: {
    results: GroupAssociationDTO[];
    searchTerm: string;
  }): void {
    this.searchTerm = data.searchTerm;
    this.dataSource.data = data.results;
    this.paginationModel.totalCount = data.results.length;
    this.noResultsMessage =
      data.results.length === 0
        ? 'No group associations are available for this search term.'
        : '';
    this.updateQueryParams();
  }

  updateQueryParams(): void {
    this.router.navigate([], {
      queryParams: { 'search.terms': this.searchTerm || null, 'page.index': 0 },
      replaceUrl: true,
    });
  }

  onPageChange(event: { pageIndex: number; pageSize: number }): void {
    this.paginationModel.pageIndex = event.pageIndex;
    this.paginationModel.pageSize = event.pageSize;
    this.fetchGroupAssociations();
  }

  getGroupImageUri(groupAssociationId: number): string | undefined {
    return this.imageUrls.get(groupAssociationId);
  }

  onReset() {
    this.searchTerm = '';
    this.refresh();
  }

  refresh(): void {
    this.searchTerm = '';
    this.noResultsMessage = '';
    this.paginationModel.pageIndex = 0;

    this.router
      .navigate([], {
        queryParams: { 'search.terms': null, 'page.index': 0 },
        replaceUrl: true,
      })
      .then(() => {
        this.fetchGroupAssociations();
        this.searchBarComponent.clearSearch();
      });
  }

  addNew(): void {
    const dialogRef = this.dialog.open(GroupAssociationDialogComponent, {
      data: { groupAssociation: null },
    });

    dialogRef.afterClosed().subscribe((result: GroupAssociationDTO) => {
      if (result) {
        this.fetchGroupAssociations();
      }
    });
  }

  openEditDialog(group: GroupAssociationDTO): void {
    const dialogRef = this.dialog.open(GroupAssociationDialogComponent, {
      data: { groupAssociation: group },
    });

    dialogRef.afterClosed().subscribe((result: GroupAssociationDTO) => {
      if (result) {
        this.fetchGroupAssociations();
      }
    });
  }

  deleteGroup(group: GroupAssociationDTO): void {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2893cc',
      cancelButtonColor: '#939597',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.groupAssociationService
          .deleteGroupAssociation(group.groupAssociationId)
          .subscribe(
            () => {
              Swal.fire({
                title: 'Deleted!',
                text: 'The group has been deleted.',
                icon: 'success',
                confirmButtonColor: '#2893cc',
                confirmButtonText: 'OK',
              }).then(() => {
                this.fetchGroupAssociations();
              });
            },
            (error) => {
              Swal.fire({
                title: 'Error deleting group',
                text: `Error deleting group association: ${error.message}`,
                icon: 'error',
                confirmButtonColor: '#2893cc',
                confirmButtonText: 'OK',
              });
            },
          );
      }
    });
  }
}
