import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { LoungeService } from '../../../services/lounge.service';
import { AirportService } from '../../../services/airport.service';
import { LoungeDTO } from '../../../models/dtos/loungeDTO';
import { AirportDTO } from '../../../models/dtos/airportDTO';
import { LoadingService } from '../../../services/helpers/loading.service';
import { startWith } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'aims-edit-lounge',
  templateUrl: './edit-lounge.component.html',
  styleUrls: ['./edit-lounge.component.scss'],
  standalone: false,
})
export class EditLoungeComponent implements OnInit {
  loungeForm!: FormGroup;
  airportCode: string = '';
  airportSearchControl = new FormControl('');
  airports: AirportDTO[] = [];
  filteredAirports: AirportDTO[] = [];
  isSubmitting = false;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditLoungeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LoungeDTO,
    private loungeService: LoungeService,
    private airportService: AirportService,
    private loadingService: LoadingService,
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    this.fetchAllAirports();
    this.setupAirportSearch();
  }

  initializeForm(): void {
    this.loungeForm = this.fb.group({
      loungeId: [this.data.loungeId],
      airportId: [this.data.airportId, Validators.required],
      loungeName: [this.data.loungeName, Validators.required],
      maxOccupancy: [
        this.data.maxOccupancy,
        [Validators.required, Validators.min(0)],
      ],
    });
  }

  fetchAllAirports(): void {
    this.loadingService.show();
    this.airportService.getAirports().subscribe(
      (response) => {
        if (response && response.success && response.data?.pageData) {
          this.airports = response.data.pageData;
          this.filteredAirports = [...this.airports];

          const selectedAirport = this.airports.find(
            (airport) => airport.airportId === this.data.airportId,
          );
          if (selectedAirport) {
            this.airportSearchControl.setValue(selectedAirport.airportName, {
              emitEvent: false,
            });
            this.airportCode = selectedAirport.airportCode;
          }
        } else {
          this.airports = [];
          this.filteredAirports = [];
        }
        this.loadingService.hide();
      },
      (error) => {
        this.airports = [];
        this.filteredAirports = [];
        this.loadingService.hide();
        this.airportService.showError(
          'Error',
          `Could not fetch airports: ${error.message}`,
        );
      },
    );
  }

  setupAirportSearch(): void {
    this.airportSearchControl.valueChanges
      .pipe(
        startWith(''),
        map((value) => this.filterAirports(value || '')),
      )
      .subscribe((filtered) => (this.filteredAirports = filtered));
  }

  filterAirports(searchText: string): AirportDTO[] {
    const lowercaseSearch = searchText.toLowerCase();
    return this.airports.filter((airport) =>
      airport.airportName.toLowerCase().includes(lowercaseSearch),
    );
  }

  onSelectAirport(event: any): void {
    const selectedAirport: AirportDTO = event.option.value;
    this.loungeForm.patchValue({
      airportId: selectedAirport.airportId,
    });
    this.airportSearchControl.setValue(selectedAirport.airportName, {
      emitEvent: false,
    });
    this.airportCode = selectedAirport.airportCode;
  }

  displayAirportName(airport: AirportDTO): string {
    return airport && airport.airportName ? airport.airportName : '';
  }

  getSelectedAirportName(): string {
    const selectedAirportId = this.loungeForm.get('airportId')?.value;
    const selectedAirport = this.airports.find(
      (airport) => airport.airportId === selectedAirportId,
    );
    return selectedAirport ? selectedAirport.airportName : '';
  }

  onSubmit(): void {
    if (this.loungeForm.valid) {
      this.isSubmitting = true;
      const lounge: LoungeDTO = {
        loungeId: this.data.loungeId,
        airportId: this.loungeForm.get('airportId')?.value,
        loungeName: this.loungeForm.get('loungeName')?.value,
        maxOccupancy: this.loungeForm.get('maxOccupancy')?.value,
      };

      this.loadingService.show();

      this.loungeService.updateLounge(lounge).subscribe(
        (response) => {
          this.loadingService.hide();
          Swal.fire({
            title: 'Success!',
            text: 'Lounge updated successfully.',
            icon: 'success',
            confirmButtonColor: '#2893cc',
            confirmButtonText: 'OK',
          }).then(() => {
            this.dialogRef.close(response);
            window.location.reload();
          });
        },
        (error) => {
          this.loadingService.hide();
          this.loungeService.showError(
            'Failed to update lounge. Please try again.',
            error.error.message,
          );
        },
      );
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
