import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(public msalService: MsalService) {}

  login() {
    this.msalService.loginRedirect({
      scopes: environment.azureAd.scopes,
      redirectUri: environment.azureAd.redirectUri,
      redirectStartPage: '/app',
    });
  }

  logout() {
    this.msalService.logoutRedirect({
      postLogoutRedirectUri: environment.azureAd.postLogoutRedirectUri,
    });
  }

  getAccount() {
    return this.msalService.instance.getActiveAccount();
  }
}
