<div class="dialog-container">
  <!-- Loading Component -->
  <aims-loading></aims-loading>

  <button mat-icon-button class="close-button" (click)="onCancel()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-vertical-stepper [linear]="true" #stepper>
    <!-- Step 1: Airport Selection -->
    <mat-step [stepControl]="cardValidationForm.controls['airportId']">
      <ng-template matStepLabel>Airport Selection (Optional)</ng-template>
      <form [formGroup]="cardValidationForm">
        <div class="input-group">
          <mat-form-field appearance="fill">
            <mat-label>Airport Name</mat-label>
            <input
              type="text"
              matInput
              [formControl]="airportSearchControl"
              [matAutocomplete]="auto"
              placeholder="Search Airport"
              autocomplete="off"
              (focus)="showAllAirports()"
            />
            <mat-autocomplete
              #auto="matAutocomplete"
              [displayWith]="displayAirportName"
              (optionSelected)="onSelectAirport($event)"
            >
              <ng-container *ngIf="filteredAirports.length > 0; else noData">
                <mat-option
                  *ngFor="let airport of filteredAirports"
                  [value]="airport"
                >
                  {{ airport.airportName }}
                </mat-option>
              </ng-container>

              <ng-template #noData>
                <mat-option disabled>No airport to display</mat-option>
              </ng-template>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <button mat-raised-button color="primary" matStepperNext>Next</button>
      </form>
    </mat-step>

    <!-- Step 2: Lounge Selection -->
    <mat-step [stepControl]="cardValidationForm.controls['loungeId']">
      <ng-template matStepLabel>Lounge Selection (Optional)</ng-template>
      <form [formGroup]="cardValidationForm">
        <div class="input-group">
          <mat-form-field appearance="fill">
            <mat-label>Lounge Name</mat-label>
            <input
              type="text"
              matInput
              [formControl]="loungeSearchControl"
              [matAutocomplete]="loungeAuto"
              placeholder="Search Lounge"
              autocomplete="off"
              (focus)="showAllLounges()"
            />
            <mat-autocomplete
              #loungeAuto="matAutocomplete"
              [displayWith]="displayLoungeName"
              (optionSelected)="onSelectLounge($event)"
            >
              <ng-container *ngIf="filteredLounges.length > 0; else noData">
                <mat-option
                  *ngFor="let lounge of filteredLounges"
                  [value]="lounge"
                >
                  {{ lounge.loungeName }}
                </mat-option>
              </ng-container>
              <ng-template #noData>
                <mat-option disabled>No lounges to display</mat-option>
              </ng-template>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <button mat-raised-button color="warn" matStepperPrevious>Back</button>
        <button mat-raised-button color="primary" matStepperNext>Next</button>
      </form>
    </mat-step>

    <!-- Step 3: Workstation Selection -->
    <mat-step [stepControl]="cardValidationForm.controls['workstationId']">
      <ng-template matStepLabel>Workstation Selection</ng-template>
      <form [formGroup]="cardValidationForm">
        <div class="input-group">
          <mat-form-field appearance="fill">
            <mat-label>Workstation Name</mat-label>
            <input
              type="text"
              matInput
              [formControl]="workstationSearchControl"
              [matAutocomplete]="workstationAuto"
              placeholder="Search Workstation"
              autocomplete="off"
              (focus)="showAllWorkstations()"
            />
            <mat-autocomplete
              #workstationAuto="matAutocomplete"
              [displayWith]="displayWorkstationName"
              (optionSelected)="onSelectWorkstation($event)"
            >
              <ng-container
                *ngIf="filteredWorkstations.length > 0; else noData"
              >
                <mat-option
                  *ngFor="let workstation of filteredWorkstations"
                  [value]="workstation"
                >
                  {{ workstation.workstationName }}
                </mat-option>
              </ng-container>
              <ng-template #noData>
                <mat-option disabled>No workstations to display</mat-option>
              </ng-template>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <button mat-raised-button color="warn" matStepperPrevious>Back</button>
        <button mat-raised-button color="primary" matStepperNext>Next</button>
      </form>
    </mat-step>

    <!-- Step 4: Airline Selection -->
    <mat-step [stepControl]="cardValidationForm.controls['airlineId']">
      <ng-template matStepLabel>Airline Selection</ng-template>
      <form [formGroup]="cardValidationForm">
        <div class="input-group">
          <mat-form-field appearance="fill">
            <mat-label>Airline Name</mat-label>
            <mat-select formControlName="airlineId">
              <ng-container
                *ngIf="airlines && airlines.length > 0; else noAirlines"
              >
                <mat-option
                  *ngFor="let airline of airlines"
                  [value]="airline.airlineId"
                >
                  {{ airline.airlineName }}
                </mat-option>
              </ng-container>
              <ng-template #noAirlines>No airlines available</ng-template>
            </mat-select>
          </mat-form-field>
        </div>
        <button mat-raised-button color="warn" matStepperPrevious>Back</button>
        <button mat-raised-button color="primary" matStepperNext>Next</button>
      </form>
    </mat-step>

    <!-- Step 5: Access Document Selection -->
    <mat-step [stepControl]="cardValidationForm.controls['cardId']">
      <ng-template matStepLabel>Access Document Selection</ng-template>
      <form [formGroup]="cardValidationForm">
        <div class="input-group">
          <mat-form-field appearance="fill">
            <mat-label>Access Document</mat-label>
            <mat-select formControlName="cardId">
              <ng-container *ngIf="filteredCards.length > 0; else noData">
                <mat-option
                  *ngFor="let card of filteredCards"
                  [value]="card.cardId"
                >
                  {{ card.cardName }}
                </mat-option>
              </ng-container>
              <ng-template #noData>No Access Documents available</ng-template>
            </mat-select>
          </mat-form-field>
        </div>
        <button mat-raised-button color="warn" matStepperPrevious>Back</button>
        <button mat-raised-button color="primary" matStepperNext>Next</button>
      </form>
    </mat-step>

    <!-- Step 6: Validation Program Selection -->
    <mat-step
      [stepControl]="cardValidationForm.controls['validationProgramId']"
    >
      <ng-template matStepLabel>Validation Program Selection</ng-template>
      <form [formGroup]="cardValidationForm">
        <div class="input-group">
          <mat-form-field appearance="fill">
            <mat-label>Validation Program</mat-label>
            <mat-select formControlName="validationProgramId">
              <mat-option
                *ngFor="let program of validationPrograms"
                [value]="program.cardValidationProgramId"
              >
                {{ program.validationProgram }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <button mat-raised-button color="warn" matStepperPrevious>Back</button>
        <button mat-raised-button color="primary" matStepperNext>Next</button>
      </form>
    </mat-step>

    <!-- Step 7: Review and Submit -->
    <mat-step>
      <ng-template matStepLabel>Review and Submit</ng-template>
      <div class="review-section">
        <h3>Review Card Validation Details</h3>
        <p>
          <strong>Airport:</strong>
          {{ getAirportName(cardValidationForm.get("airportId")?.value) }}
        </p>
        <p>
          <strong>Lounge:</strong>
          {{ getLoungeName(cardValidationForm.get("loungeId")?.value) }}
        </p>
        <p>
          <strong>Workstation:</strong>
          {{
            getWorkstationName(cardValidationForm.get("workstationId")?.value)
          }}
        </p>
        <p>
          <strong>Airline:</strong>
          {{ getAirlineName(cardValidationForm.get("airlineId")?.value) }}
        </p>
        <p>
          <strong>Access Document:</strong>
          {{ getCardName(cardValidationForm.get("cardId")?.value) }}
        </p>
        <p>
          <strong>Validation Program:</strong>
          {{
            getValidationProgramName(
              cardValidationForm.get("validationProgramId")?.value
            )
          }}
        </p>
      </div>
      <button mat-raised-button color="warn" matStepperPrevious>Back</button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="!cardValidationForm.valid || isSubmitting"
        (click)="onSubmit()"
      >
        Submit
      </button>
    </mat-step>
  </mat-vertical-stepper>
</div>
