import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, map } from 'rxjs';

import { catchError, tap } from 'rxjs/operators';
import { BaseService } from './helpers/base.service';
import { ConfigService } from './helpers/config.service';
import { LoadingService } from './helpers/loading.service';
import { ResponseDTO } from '../models/dtos/responseDTO';
import {
  AirlinePositionDTO,
  AirlinePositionOperationDTO,
} from '../models/dtos/airlinePositionDTO';
import {
  CardPositionDTO,
  CardPositionOperationDTO,
} from '../models/dtos/cardPositionDTO';

@Injectable({
  providedIn: 'root',
})
export class PositionsService extends BaseService {
  private apiUrl: string;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private loadingService: LoadingService,
  ) {
    super();
    this.apiUrl = this.configService.getApiUrl() + '/Positions';
  }

  getAirlinePositionsByWorkstation(
    workstationId: number,
  ): Observable<ResponseDTO<AirlinePositionDTO>> {
    this.loadingService.setLoading(true);

    return this.http
      .get<
        ResponseDTO<AirlinePositionDTO>
      >(`${this.apiUrl}/GetAirlinePositionsByWorkstation/${workstationId}`)
      .pipe(
        map((response) => response),
        catchError(this.handleError.bind(this)),
        tap(() => this.loadingService.setLoading(false)),
      );
  }

  runAirlinePositionOperations(
    operations: AirlinePositionOperationDTO[],
  ): Observable<any> {
    this.loadingService.setLoading(true);

    return this.http
      .post<any>(`${this.apiUrl}/RunAirlinePositionOperations`, operations)
      .pipe(
        catchError(this.handleError.bind(this)),
        tap(() => this.loadingService.setLoading(false)),
      );
  }

  runAirlinePositionOperationsOnLounges(
    operations: AirlinePositionOperationDTO[],
    loungeIds: number[],
  ): Observable<any> {
    this.loadingService.setLoading(true);

    let params = new HttpParams();
    loungeIds.forEach((id) => {
      params = params.append('loungeIds', id.toString());
    });

    return this.http
      .post<any>(
        `${this.apiUrl}/RunAirlinePositionOperationsOnLounges`,
        operations,
        { params },
      )
      .pipe(
        catchError(this.handleError.bind(this)),
        tap(() => this.loadingService.setLoading(false)),
      );
  }

  runAirlinePositionOperationsOnAirports(
    operations: AirlinePositionOperationDTO[],
    airportIds: number[],
  ): Observable<any> {
    this.loadingService.setLoading(true);

    let params = new HttpParams();
    airportIds.forEach((id) => {
      params = params.append('airportIds', id.toString());
    });

    return this.http
      .post<any>(
        `${this.apiUrl}/RunAirlinePositionOperationsOnAirports`,
        operations,
        { params },
      )
      .pipe(
        catchError(this.handleError.bind(this)),
        tap(() => this.loadingService.setLoading(false)),
      );
  }

  getCardPositionsByWorkstation(
    workstationId: number,
  ): Observable<ResponseDTO<CardPositionDTO>> {
    this.loadingService.setLoading(true);

    return this.http
      .get<
        ResponseDTO<CardPositionDTO>
      >(`${this.apiUrl}/GetCardPositionsByWorkstation/${workstationId}`)
      .pipe(
        map((response) => response),
        catchError(this.handleError.bind(this)),
        tap(() => this.loadingService.setLoading(false)),
      );
  }

  getCardPositionsByWorkstationAndAirline(
    workstationId: number,
    airlineId: number,
  ): Observable<ResponseDTO<CardPositionDTO>> {
    this.loadingService.setLoading(true);

    let params = new HttpParams()
      .set('workstationId', workstationId.toString())
      .set('airlineId', airlineId.toString());

    return this.http
      .get<
        ResponseDTO<CardPositionDTO>
      >(`${this.apiUrl}/GetCardPositionsByWorkstationAndAirline`, { params })
      .pipe(
        map((response) => response),
        catchError(this.handleError.bind(this)),
        tap(() => this.loadingService.setLoading(false)),
      );
  }

  getWorkstationsAndCardPositionsByCard(
    cardId: number,
  ): Observable<ResponseDTO<CardPositionDTO>> {
    this.loadingService.setLoading(true);

    return this.http
      .get<
        ResponseDTO<CardPositionDTO>
      >(`${this.apiUrl}/GetWorkstationsAndCardPositionsByCard/${cardId}`)
      .pipe(
        map((response) => response),
        catchError(this.handleError.bind(this)),
        tap(() => this.loadingService.setLoading(false)),
      );
  }

  runCardPositionOperations(
    operations: CardPositionOperationDTO[],
  ): Observable<any> {
    this.loadingService.show();
    return this.http
      .post<any>(`${this.apiUrl}/RunCardPositionOperations`, operations)
      .pipe(
        catchError(this.handleError.bind(this)),
        tap(() => this.loadingService.setLoading(false)),
      );
  }

  runCardPositionOperationsOnLounges(
    operations: CardPositionOperationDTO[],
    loungeIds: number[],
  ): Observable<any> {
    this.loadingService.setLoading(true);

    let params = new HttpParams();
    loungeIds.forEach((id) => {
      params = params.append('loungeIds', id.toString());
    });

    return this.http
      .post<any>(
        `${this.apiUrl}/RunCardPositionOperationsOnLounges`,
        operations,
        { params },
      )
      .pipe(
        catchError(this.handleError.bind(this)),
        tap(() => this.loadingService.setLoading(false)),
      );
  }

  runCardPositionOperationsOnAirports(
    operations: CardPositionOperationDTO[],
    airportIds: number[],
  ): Observable<any> {
    this.loadingService.setLoading(true);

    let params = new HttpParams();
    airportIds.forEach((id) => {
      params = params.append('airportIds', id.toString());
    });

    return this.http
      .post<any>(
        `${this.apiUrl}/RunCardPositionOperationsOnAirports`,
        operations,
        { params },
      )
      .pipe(
        catchError(this.handleError.bind(this)),
        tap(() => this.loadingService.setLoading(false)),
      );
  }
}
