import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { AirportDTO } from '../../../models/dtos/airportDTO';
import { AirportService } from '../../../services/airport.service';
import { LoadingService } from '../../../services/helpers/loading.service';

@Component({
  selector: 'aims-create-airport',
  templateUrl: './create-airport.component.html',
  styleUrls: ['./create-airport.component.scss'],
  standalone: false,
})
export class CreateAirportComponent {
  airportForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private airportService: AirportService,
    private loadingService: LoadingService,
    public dialogRef: MatDialogRef<CreateAirportComponent>,
  ) {
    this.airportForm = this.fb.group({
      airportName: ['', Validators.required],
      airportCode: [
        '',
        [
          Validators.required,
          Validators.maxLength(3),
          Validators.pattern(/^[A-Z]{3}$/),
        ],
      ],
    });

    this.airportForm.get('airportCode')?.valueChanges.subscribe((value) => {
      this.airportForm
        .get('airportCode')
        ?.setValue(value.toUpperCase(), { emitEvent: false });
    });
  }

  onSubmit(): void {
    if (this.airportForm.valid) {
      const newAirport: AirportDTO = {
        airportId: 0,
        airportName: this.airportForm.value.airportName,
        airportCode: this.airportForm.value.airportCode,
      };
      this.loadingService.show();

      this.airportService.addAirport(newAirport).subscribe(
        (response) => {
          this.loadingService.hide();
          Swal.fire({
            title: 'Success!',
            text: 'Airport added successfully.',
            icon: 'success',
            confirmButtonColor: '#2893cc',
            confirmButtonText: 'OK',
          }).then(() => {
            this.dialogRef.close(response);
            window.location.reload();
          });
        },
        (error) => {
          this.loadingService.hide();
          this.airportService.showError(
            'Failed to add airport.',
            error.message,
          );
        },
      );
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
