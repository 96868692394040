<div class="group-associations-container">
  <div class="header-container">
    <div class="header-content">
      <h2 class="header-title">Group Associations</h2>
      <p class="description">
        List of group associations with key information and actions.
      </p>
    </div>
  </div>

  <aims-layout-toolbar>
    <button mat-icon-button (click)="addNew()">
      <mat-icon>add</mat-icon>
    </button>
    <button mat-icon-button (click)="refresh()">
      <mat-icon>refresh</mat-icon>
    </button>
  </aims-layout-toolbar>

  <aims-query-layout>
    <aims-search-bar
      left
      matTooltip="Enter at least 3 characters for search"
      matTooltipPosition="above"
      (termsChanged$)="onSearchResults($event)"
      (reset$)="onReset()"
      (clearTerms$)="fetchAllOnClear()"
    ></aims-search-bar>
  </aims-query-layout>

  <div class="table-responsive">
    <table mat-table class="mat-elevation-z8" [dataSource]="dataSource">
      <!-- Group Name Column -->
      <ng-container matColumnDef="groupName">
        <th mat-header-cell *matHeaderCellDef>Group Name</th>
        <td mat-cell *matCellDef="let group">
          <div class="name-and-image-container">
            <img
              [src]="
                getGroupImageUri(group.groupAssociationId) || defaultImageUrl
              "
              alt="Group Image"
              class="group-image"
            />
            <span class="group-name">{{ group.groupName }}</span>
          </div>
        </td>
      </ng-container>

      <!-- Group Association ID Column -->
      <ng-container matColumnDef="groupAssociationId">
        <th mat-header-cell *matHeaderCellDef>Group ID</th>
        <td mat-cell *matCellDef="let group">{{ group.groupAssociationId }}</td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let group">
          <button
            mat-icon-button
            (click)="openEditDialog(group); $event.stopPropagation()"
            aria-label="Edit group"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="deleteGroup(group); $event.stopPropagation()"
            aria-label="Delete group"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Define rows -->
      <tr
        mat-header-row
        *matHeaderRowDef="['groupName', 'groupAssociationId', 'action']"
      ></tr>
      <tr
        mat-row
        *matRowDef="
          let row;
          columns: ['groupName', 'groupAssociationId', 'action']
        "
      ></tr>
    </table>
  </div>

  <div class="paginator-container">
    <aims-query-page
      [paginationModel]="paginationModel"
      (pageChange)="onPageChange($event)"
    ></aims-query-page>
  </div>
</div>
