import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { AirportService } from '../../../services/airport.service';
import { LoungeService } from '../../../services/lounge.service';
import { WorkstationService } from '../../../services/workstation.service';
import { CardValidationService } from '../../../services/card-validation.service';
import { AirportDTO } from '../../../models/dtos/airportDTO';
import { LoungeDTO } from '../../../models/dtos/loungeDTO';
import { WorkstationDTO } from '../../../models/dtos/workstationDTO';
import { CardDTO } from '../../../models/dtos/cardDTO';
import { AirlineDTO } from '../../../models/dtos/airlineDTO';
import { CardValidationProgramDTO } from '../../../models/dtos/cardValidationProgramDTO';
import { LoadingService } from '../../../services/helpers/loading.service';
import { CardValidationDTO } from '../../../models/dtos/cardValidationDTO';
import { catchError, map, tap } from 'rxjs/operators';
import { of, startWith } from 'rxjs';
import { PaginationModel } from '../../../models/pagination-model';

@Component({
  selector: 'aims-create-card-validation',
  templateUrl: './create-card-validation.component.html',
  styleUrls: ['./create-card-validation.component.scss'],
  standalone: false,
})
export class CreateCardValidationComponent implements OnInit {
  paginationModel: PaginationModel = new PaginationModel();

  airportSearchControl = new FormControl('');
  loungeSearchControl = new FormControl('');
  workstationSearchControl = new FormControl('');

  cardValidationForm!: FormGroup;
  airports: AirportDTO[] = [];
  lounges: LoungeDTO[] = [];
  workstations: WorkstationDTO[] = [];
  airlines: AirlineDTO[] = [];
  validationPrograms: CardValidationProgramDTO[] = [];
  filteredAirports: AirportDTO[] = [];
  filteredLounges: LoungeDTO[] = [];
  filteredWorkstations: WorkstationDTO[] = [];
  filteredCards: CardDTO[] = [];

  isSubmitting = false;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CreateCardValidationComponent>,
    private airportService: AirportService,
    private loungeService: LoungeService,
    private workstationService: WorkstationService,
    private cardValidationService: CardValidationService,
    private loadingService: LoadingService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    this.fetchAirports();
    this.fetchLounges();
    this.fetchWorkstations();
    this.fetchValidationPrograms();

    this.airportSearchControl.valueChanges
      .pipe(
        startWith(''),
        map((value) => this.filterAirports(value || '')),
      )

      .subscribe((filtered) => (this.filteredAirports = filtered));

    this.loungeSearchControl.valueChanges
      .pipe(
        startWith(''),
        map((value) => this.filterLoungesByKeyword(value || '')),
      )
      .subscribe((filtered) => (this.filteredLounges = filtered));

    this.workstationSearchControl.valueChanges
      .pipe(
        startWith(''),
        map((value) => this.filterWorkstationsByKeyword(value || '')),
      )
      .subscribe((filtered) => (this.filteredWorkstations = filtered));
  }

  filterLoungesByKeyword(searchText: string): LoungeDTO[] {
    const lowercaseSearch = searchText.toLowerCase();
    return this.lounges.filter((lounge) =>
      lounge.loungeName.toLowerCase().includes(lowercaseSearch),
    );
  }

  filterWorkstationsByKeyword(searchText: string): WorkstationDTO[] {
    const lowercaseSearch = searchText.toLowerCase();
    return this.workstations.filter((workstation) =>
      workstation.workstationName.toLowerCase().includes(lowercaseSearch),
    );
  }

  initializeForm(): void {
    this.cardValidationForm = this.fb.group({
      airportId: [''],
      loungeId: [''],
      workstationId: ['', Validators.required],
      airlineId: ['', Validators.required],
      cardId: ['', Validators.required],
      validationProgramId: ['', Validators.required],
    });

    this.setupValueChangeHandlers();
  }

  setupValueChangeHandlers(): void {
    this.cardValidationForm
      .get('airportId')
      ?.valueChanges.subscribe((airportId) => {
        this.filterLounges(airportId);
        this.fetchWorkstations();
      });

    this.cardValidationForm
      .get('loungeId')
      ?.valueChanges.subscribe((loungeId) => {
        this.filterWorkstations(loungeId);
      });

    this.cardValidationForm
      .get('workstationId')
      ?.valueChanges.subscribe((workstationId) => {
        if (workstationId) {
          this.fetchAirlines(workstationId);
        }
      });

    this.cardValidationForm
      .get('airlineId')
      ?.valueChanges.subscribe((airlineId) => {
        const workstationId =
          this.cardValidationForm.get('workstationId')?.value;
        if (workstationId && airlineId) {
          this.fetchCards(workstationId, airlineId);
        }
      });
  }

  fetchAirports(): void {
    this.airportService
      .getAirports()
      .pipe(
        tap((response) => {
          if (response && response.success && response.data) {
            this.airports = response.data.pageData || [];
          } else {
            this.airports = [];
          }
        }),
        catchError((error) => {
          this.airportService.showError(
            'Error fetching airports:',
            error.message,
          );
          return of([]);
        }),
      )
      .subscribe();
  }

  filterAirports(searchText: string): AirportDTO[] {
    const lowercaseSearch = searchText.toLowerCase();
    return this.airports.filter((airport) =>
      airport.airportName.toLowerCase().includes(lowercaseSearch),
    );
  }

  onSelectAirport(event: any): void {
    const selectedAirport = event.option.value;
    this.cardValidationForm
      .get('airportId')
      ?.setValue(selectedAirport.airportId);
  }

  displayAirportName(airport: AirportDTO): string {
    return airport ? airport.airportName : '';
  }

  showAllAirports(): void {
    this.filteredAirports = [...this.airports];
  }

  fetchLounges(): void {
    this.loungeService
      .getLounges()
      .pipe(
        tap((response) => {
          if (response && response.success && response.data) {
            this.lounges = response.data.pageData || [];
          } else {
            this.lounges = [];
          }
        }),
        catchError((error) => {
          this.loungeService.showError(
            'Error fetching lounges:',
            error.message,
          );
          return of([]);
        }),
      )
      .subscribe();
  }

  onSelectLounge(event: any): void {
    const selectedLounge = event.option.value;
    this.cardValidationForm.get('loungeId')?.setValue(selectedLounge.loungeId);
  }

  displayLoungeName(lounge: LoungeDTO): string {
    return lounge ? lounge.loungeName : '';
  }

  showAllLounges(): void {
    this.filteredLounges = [...this.lounges];
  }

  fetchWorkstations(): void {
    this.workstationService
      .getWorkstations()
      .pipe(
        tap((response) => {
          if (response && response.success && response.data) {
            this.workstations = response.data.pageData || [];
            this.filteredWorkstations = this.workstations;
          } else {
            this.workstations = [];
            this.filteredWorkstations = [];
          }
        }),
        catchError((error) => {
          this.workstationService.showError(
            'Error fetching workstations:',
            error.message,
          );
          return of([]);
        }),
      )
      .subscribe();
  }

  displayWorkstationName(workstation: WorkstationDTO): string {
    return workstation ? workstation.workstationName : '';
  }

  onSelectWorkstation(event: any): void {
    const selectedWorkstation = event.option.value;
    this.cardValidationForm
      .get('workstationId')
      ?.setValue(selectedWorkstation.workstationId);
  }

  showAllWorkstations(): void {
    this.filteredWorkstations = [...this.workstations];
  }

  fetchAirlines(workstationId: number): void {
    this.workstationService
      .getExistingAirlines(workstationId)
      .pipe(
        tap((response: any) => {
          if (response && response.success && Array.isArray(response.data)) {
            this.airlines = response.data;
          } else {
            this.airlines = [];
          }
          this.cdr.detectChanges();

          if (this.airlines.length === 0) {
            Swal.fire({
              title: 'No Airlines Found',
              text: 'No airlines are available for the selected workstation. Please choose another workstation.',
              icon: 'warning',
              confirmButtonColor: '#2893cc',
              confirmButtonText: 'OK',
            }).then(() => {
              this.cardValidationForm.controls['workstationId'].setValue('');
              this.airlines = [];
            });
          }
        }),
        catchError((error) => {
          this.workstationService.showError(
            'Error fetching airlines:',
            error.message,
          );
          return of([]);
        }),
      )
      .subscribe();
  }

  fetchCards(workstationId: number, airlineId: number): void {
    this.workstationService
      .getExistingAirlineCards(workstationId, airlineId)
      .pipe(
        tap((response: any) => {
          if (response && Array.isArray(response)) {
            this.filteredCards = response;
          } else if (
            response &&
            response.data &&
            Array.isArray(response.data)
          ) {
            this.filteredCards = response.data;
          } else {
            this.filteredCards = [];
          }
          this.cdr.detectChanges();

          if (this.filteredCards.length === 0) {
            Swal.fire({
              title: 'No Cards Found',
              text: 'No access documents are available for the selected airline. Please choose another airline.',
              icon: 'warning',
              confirmButtonColor: '#2893cc',
              confirmButtonText: 'OK',
            }).then(() => {
              this.cardValidationForm.controls['airlineId'].setValue('');
              this.filteredCards = [];
            });
          }
        }),
        catchError((error) => {
          this.workstationService.showError(
            'Error fetching cards:',
            error.message,
          );
          return of([]);
        }),
      )
      .subscribe();
  }

  fetchValidationPrograms(): void {
    this.cardValidationService
      .getCardValidationProgramsByPagination(1, 100)
      .pipe(
        tap((response) => {
          if (response && response.success && response.data) {
            this.validationPrograms = response.data.pageData.flat() || [];
          } else {
            this.validationPrograms = [];
          }
        }),
        catchError((error) => {
          this.cardValidationService.showError(
            'Error fetching validation programs:',
            error.message,
          );
          return of([]);
        }),
      )
      .subscribe();
  }

  filterLounges(airportId: number): void {
    if (airportId) {
      this.filteredLounges = this.lounges.filter(
        (lounge) => lounge.airportId === airportId,
      );
    } else {
      this.filteredLounges = this.lounges;
    }
    this.cardValidationForm.controls['loungeId'].setValue('');
    this.filteredWorkstations = [];
  }

  filterWorkstations(loungeId: number): void {
    if (loungeId) {
      this.filteredWorkstations = this.workstations.filter(
        (workstation) => workstation.loungeId === loungeId,
      );
    } else {
      this.filteredWorkstations = this.workstations;
    }
    this.cardValidationForm.controls['workstationId'].setValue('');
  }

  onSubmit(): void {
    if (this.cardValidationForm.valid) {
      const formValues = this.cardValidationForm.value;

      const newCardValidation: CardValidationDTO = {
        cardValidationId: 0,
        cardId: formValues.cardId,
        cardName: '',
        workstationId: formValues.workstationId,
        workstationName: '',
        airlineId: formValues.airlineId,
        airlineName: '',
        cardValidationProgram: {
          cardValidationProgramId: formValues.validationProgramId,
          validationProgram: '',
        },
      };

      this.loadingService.show();

      this.cardValidationService
        .addCardValidations([newCardValidation])
        .subscribe(
          (response) => {
            this.loadingService.hide();
            Swal.fire({
              title: 'Success!',
              text: 'Card validation created successfully.',
              icon: 'success',
              confirmButtonColor: '#2893cc',
              confirmButtonText: 'OK',
            }).then(() => {
              this.dialogRef.close(response[0]);
              window.location.reload();
            });
          },
          (error) => {
            this.loadingService.hide();
            Swal.fire({
              title: 'Error!',
              text: `Error adding card validation: ${error.message}`,
              icon: 'error',
              confirmButtonColor: '#2893cc',
              confirmButtonText: 'OK',
            });
          },
        );
    } else {
      this.cardValidationService.showError(
        'Error!',
        'Please correct the errors in the form before submitting.',
      );
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  getAirportName(airportId: number): string {
    const airport = this.airports.find((a) => a.airportId === airportId);
    return airport ? airport.airportName : '';
  }

  getLoungeName(loungeId: number): string {
    const lounge = this.lounges.find((l) => l.loungeId === loungeId);
    return lounge ? lounge.loungeName : '';
  }

  getWorkstationName(workstationId: number): string {
    const workstation = this.workstations.find(
      (w) => w.workstationId === workstationId,
    );
    return workstation ? workstation.workstationName : '';
  }

  getAirlineName(airlineId: number): string {
    const airline = this.airlines.find((a) => a.airlineId === airlineId);
    return airline ? airline.airlineName : '';
  }

  getCardName(cardId: number): string {
    const card = this.filteredCards.find((c) => c.cardId === cardId);
    return card ? card.cardName : '';
  }

  getValidationProgramName(validationProgramId: number): string {
    const program = this.validationPrograms.find(
      (p) => p.cardValidationProgramId === validationProgramId,
    );
    return program ? program.validationProgram : 'None';
  }
}
