import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { AirlineDTO } from '../models/dtos/airlineDTO';
import { ResponseDTO } from '../models/dtos/responseDTO';
import { catchError, tap } from 'rxjs/operators';
import { BaseService } from './helpers/base.service';
import { ConfigService } from './helpers/config.service';
import { CardDTO } from '../models/dtos/cardDTO';
import { LoadingService } from './helpers/loading.service';

@Injectable({
  providedIn: 'root',
})
export class AirlineService extends BaseService {
  private apiUrl: string;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private loadingService: LoadingService,
  ) {
    super();
    this.apiUrl = this.configService.getApiUrl() + '/Airlines';
  }

  getAllAirlines(
    index: number = 1,
    size: number = 100,
  ): Observable<ResponseDTO<AirlineDTO>> {
    const params = new HttpParams()
      .set('Index', index.toString())
      .set('Size', size.toString());
    this.loadingService.setLoading(true);

    return this.http
      .get<ResponseDTO<AirlineDTO>>(`${this.apiUrl}/GetAll`, { params })
      .pipe(
        map((response) => response),
        catchError(this.handleError.bind(this)),
        tap(() => this.loadingService.setLoading(false)),
      );
  }

  getAirlineById(airlineId: number): Observable<AirlineDTO> {
    this.loadingService.setLoading(true);

    return this.http.get<any>(`${this.apiUrl}/GetById/${airlineId}`).pipe(
      map((response: any) => {
        return {
          airlineId: response.data.airlineId,
          airlineImageHandle: response.data.airlineImageHandle,
          airlineName: response.data.airlineName,
          airlineCode: response.data.airlineCode,
          passengerMandatoryFields: response.data.passengerMandatoryFields,
          passengerOptionalFields: response.data.passengerOptionalFields,
          guestOptionalFields: response.data.guestOptionalFields,
          guestMandatoryFields: response.data.guestMandatoryFields,
          groupAssociations: response.data.groupAssociations,
        } as AirlineDTO;
      }),
      catchError(this.handleError.bind(this)),
      tap(() => this.loadingService.setLoading(false)),
    );
  }

  addAirline(airline: AirlineDTO): Observable<AirlineDTO> {
    return this.http
      .post<AirlineDTO>(`${this.apiUrl}/Add`, airline)
      .pipe(catchError(this.handleError.bind(this)));
  }

  updateAirline(airline: AirlineDTO): Observable<AirlineDTO> {
    return this.http
      .patch<AirlineDTO>(`${this.apiUrl}/Update`, airline)
      .pipe(catchError(this.handleError.bind(this)));
  }

  deleteAirline(airlineId: number): Observable<any> {
    return this.http
      .delete(`${this.apiUrl}/Delete/${airlineId}`)
      .pipe(catchError(this.handleError.bind(this)));
  }

  searchAirlinesByKeyword(keyword: string): Observable<AirlineDTO[]> {
    return this.http
      .get<AirlineDTO[]>(`${this.apiUrl}/SearchByKeyword`, {
        params: { keyword },
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  getAirlineByCodeAndName(
    code: string,
    name: string,
  ): Observable<AirlineDTO[]> {
    return this.http
      .get<AirlineDTO[]>(`${this.apiUrl}/GetByCodeAndName`, {
        params: { code, name },
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  getAirlineCards(airlineId: number): Observable<CardDTO[]> {
    return this.http
      .get<CardDTO[]>(`${this.apiUrl}/GetCards/${airlineId}`)
      .pipe(catchError(this.handleError.bind(this)));
  }
}
