import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CardValidationProgramDTO } from '../../../models/dtos/cardValidationProgramDTO';
import { CardValidationService } from '../../../services/card-validation.service';
import { LoadingService } from '../../../services/helpers/loading.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'aims-edit-card-validation-program',
  templateUrl: './edit-card-validation-program.component.html',
  styleUrl: './edit-card-validation-program.component.scss',
  standalone: false,
})
export class EditCardValidationProgramComponent {
  cardValidationProgramForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private cardValidationService: CardValidationService,
    private loadingService: LoadingService,
    public dialogRef: MatDialogRef<EditCardValidationProgramComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CardValidationProgramDTO,
  ) {
    this.cardValidationProgramForm = this.fb.group({
      validationProgram: [data?.validationProgram || '', Validators.required],
    });
  }

  onSubmit(): void {
    if (this.cardValidationProgramForm.valid) {
      const updatedProgram: CardValidationProgramDTO = {
        cardValidationProgramId: this.data.cardValidationProgramId,
        validationProgram:
          this.cardValidationProgramForm.value.validationProgram,
      };

      this.loadingService.show();

      this.cardValidationService
        .updateCardValidationProgram(updatedProgram)
        .subscribe(
          (response) => {
            this.loadingService.hide();
            Swal.fire({
              title: 'Success!',
              text: 'Card validation program updated successfully.',
              icon: 'success',
              confirmButtonColor: '#2893cc',
              confirmButtonText: 'OK',
            }).then(() => {
              this.dialogRef.close(response);
            });
          },
          (error) => {
            this.loadingService.hide();
            Swal.fire({
              title: 'Error!',
              text: 'Failed to update the card validation program.',
              icon: 'error',
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
            });
          },
        );
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
