<div class="dialog-container">
  <!-- Loading Component -->
  <aims-loading></aims-loading>

  <button mat-icon-button class="close-button" (click)="onCancel()">
    <mat-icon>close</mat-icon>
  </button>
  <h2 class="dialog-title">Create New Airport</h2>
  <form [formGroup]="airportForm" (ngSubmit)="onSubmit()" id="form">
    <mat-horizontal-stepper [linear]="true" #stepper>
      <mat-step [stepControl]="airportForm">
        <form [formGroup]="airportForm">
          <ng-template matStepLabel>Enter Airport Details</ng-template>
          <div class="input-group">
            <label for="airportName" class="input-label">
              Airport Name <span class="required-star">*</span>
            </label>
            <input
              formControlName="airportName"
              type="text"
              id="airportName"
              class="input-field"
              placeholder="example: Montreal Airport"
            />
          </div>
          <div class="input-group">
            <label for="airportCode" class="input-label">
              Airport Code <span class="required-star">*</span>
            </label>
            <input
              formControlName="airportCode"
              type="text"
              id="airportCode"
              class="input-field"
              placeholder="example: YUL"
              maxlength="3"
            />
          </div>
          <div class="dialog-actions">
            <button
              mat-button
              matStepperNext
              class="modern-button next-button"
              [disabled]="!airportForm.valid"
            >
              Next
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Review and Submit</ng-template>
        <div class="review-section">
          <p>
            <strong>Airport Name:</strong> {{ airportForm.value?.airportName }}
          </p>
          <p>
            <strong>Airport Code:</strong> {{ airportForm.value?.airportCode }}
          </p>
        </div>
        <div class="dialog-actions">
          <button
            mat-button
            matStepperPrevious
            class="modern-button back-button"
          >
            Back
          </button>
          <button
            type="submit"
            mat-raised-button
            color="primary"
            class="modern-button submit-button"
            [disabled]="!airportForm.valid"
          >
            Submit
          </button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </form>
</div>
