import { Component } from '@angular/core';
import { LoadingService } from '../../services/helpers/loading.service';

@Component({
  selector: 'aims-loading',
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.scss',
  standalone: false,
})
export class LoadingComponent {
  constructor(public loadingService: LoadingService) {}
}
