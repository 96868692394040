import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';
import { startWith } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'aims-searchable-select',
  standalone: false,

  templateUrl: './searchable-select.component.html',
  styleUrl: './searchable-select.component.scss',
})
export class SearchableSelectComponent implements OnInit, OnChanges {
  @Input() label!: string;
  @Input() placeholder!: string;
  @Input() options: any[] = [];
  @Input() displayFn: (value: any) => string = (value) => value?.name || '';
  @Input() control!: FormControl;

  @Output() optionSelected = new EventEmitter<any>();

  filteredOptions: any[] = [];

  ngOnInit() {
    this.initFilter();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options']) {
      const currentOptions = changes['options'].currentValue || [];
      this.filteredOptions = this.filterOptions(this.control.value);
    }
  }

  private initFilter() {
    this.control.valueChanges
      .pipe(
        startWith(''),
        map((value) => this.filterOptions(value)),
      )
      .subscribe((filtered) => (this.filteredOptions = filtered));
  }

  private filterOptions(value: any): any[] {
    const searchText =
      typeof value === 'string'
        ? value.toLowerCase()
        : this.displayFn(value).toLowerCase();
    return this.options.filter((option) =>
      this.displayFn(option).toLowerCase().includes(searchText),
    );
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    this.optionSelected.emit(event.option.value);
  }
}
