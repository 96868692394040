import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { CardDTO } from '../models/dtos/cardDTO';
import { catchError, tap } from 'rxjs/operators';
import { BaseService } from './helpers/base.service';
import { ConfigService } from './helpers/config.service';
import { LoadingService } from './helpers/loading.service';
import { ResponseDTO } from '../models/dtos/responseDTO';

@Injectable({
  providedIn: 'root',
})
export class CardService extends BaseService {
  private apiUrl: string;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private loadingService: LoadingService,
  ) {
    super();
    this.apiUrl = this.configService.getApiUrl() + '/Cards';
  }
  getAllCards(index: number = 1, size: number = 100): Observable<CardDTO[]> {
    const params = new HttpParams()
      .set('Index', index.toString())
      .set('Size', size.toString());
    this.loadingService.setLoading(true);

    return this.http
      .get<ResponseDTO<CardDTO>>(`${this.apiUrl}/GetAll`, { params })
      .pipe(
        map((response) => response?.data?.pageData || []),
        catchError(this.handleError.bind(this)),
        tap(() => this.loadingService.setLoading(false)),
      );
  }

  getCardById(cardId: number): Observable<CardDTO> {
    return this.http.get<any>(`${this.apiUrl}/GetById/${cardId}`).pipe(
      map((response: any) => {
        return {
          cardId: response.data.cardId,
          cardName: response.data.cardName,
          airlineId: response.data.airlineId,
          cardImageHandle: response.data.cardImageHandle,
          cardSwipeType: response.data.cardSwipeType,
          validTo: response.data.validTo,
          passengerMandatoryFields: response.data.passengerMandatoryFields,
          passengerOptionalFields: response.data.passengerMandatoryFields,
          guestMandatoryFields: response.data.guestMandatoryFields,
          guestOptionalFields: response.data.guestOptionalFields,
          hide: response.data.hide,
          guestTypes: response.data.guestTypes,
          maxFamilyGuests: response.data.maxFamilyGuests,
          maxComplimentaryGuests: response.data.maxComplimentaryGuests,
          maxPayGuests: response.data.maxPayGuests,
          guestPayAmount: response.data.guestPayAmount,
          guestPayCurrency: response.data.guestPayCurrency,
        } as CardDTO;
      }),
      catchError(this.handleError.bind(this)),
    );
  }

  addCard(card: CardDTO): Observable<CardDTO> {
    return this.http
      .post<CardDTO>(`${this.apiUrl}/Add`, card)
      .pipe(catchError(this.handleError.bind(this)));
  }

  updateCard(card: CardDTO): Observable<CardDTO> {
    return this.http
      .patch<CardDTO>(`${this.apiUrl}/Update`, card)
      .pipe(catchError(this.handleError.bind(this)));
  }

  deleteCard(cardId: number): Observable<any> {
    return this.http
      .delete(`${this.apiUrl}/Delete/${cardId}`)
      .pipe(catchError(this.handleError.bind(this)));
  }
  searchCardByKeyword(keyword: string): Observable<CardDTO[]> {
    const params = new HttpParams().set('keyword', keyword);
    return this.http
      .get<CardDTO[]>(`${this.apiUrl}/SearchByKeyword`, { params })
      .pipe(catchError(this.handleError.bind(this)));
  }

  // getRulesByCardId(cardId: number): Observable<any> {
  //   return this.http.get<any>(`${this.apiUrl}/GetRules/${cardId}`).pipe(
  //     catchError(this.handleError),
  //   );
  // }

  // addRules(rules: any): Observable<any> {
  //   return this.http.post<any>(`${this.apiUrl}/AddRules`, rules).pipe(catchError(this.handleError));;
  // }
  //
  // updateRules(rules: any): Observable<any> {
  //   return this.http.patch<any>(`${this.apiUrl}/UpdateRules`, rules).pipe(catchError(this.handleError));;
  // }
  //
  // deleteRule(ruleId: number): Observable<any> {
  //   return this.http.delete(`${this.apiUrl}/DeleteRule/${ruleId}`).pipe(catchError(this.handleError));;
  // }
  //
  // deleteRules(cardId: number): Observable<any> {
  //   return this.http.delete(`${this.apiUrl}/DeleteRules/${cardId}`).pipe(catchError(this.handleError));;
  // }
  //
  // getSeparatorsByCardId(cardId: number): Observable<any> {
  //   return this.http.get<any>(`${this.apiUrl}/GetSeparators/${cardId}`).pipe(catchError(this.handleError));
  // }
  //
  // addSeparators(separators: any): Observable<any> {
  //   return this.http.post<any>(`${this.apiUrl}/AddSeparators`, separators).pipe(catchError(this.handleError));;
  // }
  //
  // updateSeparators(separators: any): Observable<any> {
  //   return this.http.patch<any>(`${this.apiUrl}/UpdateSeparators`, separators).pipe(catchError(this.handleError));;
  // }
  //
  // deleteSeparator(separatorId: number): Observable<any> {
  //   return this.http.delete(`${this.apiUrl}/DeleteSeparator/${separatorId}`).pipe(catchError(this.handleError));;
  // }
  //
  // deleteSeparators(cardId: number): Observable<any> {
  //   return this.http.delete(`${this.apiUrl}/DeleteSeparators/${cardId}`).pipe(catchError(this.handleError));;
  // }
  //
  // getParsersByCardId(cardId: number): Observable<any> {
  //   return this.http.get<any>(`${this.apiUrl}/GetParsers/${cardId}`).pipe(catchError(this.handleError));
  // }
  //
  // addParsers(parsers: any): Observable<any> {
  //   return this.http.post<any>(`${this.apiUrl}/AddParsers`, parsers).pipe(catchError(this.handleError));;
  // }
  //
  // updateParsers(parsers: any): Observable<any> {
  //   return this.http.patch<any>(`${this.apiUrl}/UpdateParsers`, parsers).pipe(catchError(this.handleError));;
  // }
  //
  // deleteParser(parserId: number): Observable<any> {
  //   return this.http.delete(`${this.apiUrl}/DeleteParser/${parserId}`).pipe(catchError(this.handleError));;
  // }
  //
  // deleteParsers(cardId: number): Observable<any> {
  //   return this.http.delete(`${this.apiUrl}/DeleteParsers/${cardId}`).pipe(catchError(this.handleError));;
  // }
}
