import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ResponseDTO } from '../models/dtos/responseDTO';
import { GroupAssociationDTO } from '../models/dtos/groupAssociationDTO';
import { BaseService } from './helpers/base.service';
import { ConfigService } from './helpers/config.service';

@Injectable({
  providedIn: 'root',
})
export class GroupAssociationService extends BaseService {
  private apiUrl: string;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
  ) {
    super();
    this.apiUrl = this.configService.getApiUrl() + '/GroupAssociations';
  }

  getAllGroupAssociations(
    index: number = 1,
    size: number = 100,
  ): Observable<ResponseDTO<GroupAssociationDTO>> {
    const params = new HttpParams()
      .set('Index', index.toString())
      .set('Size', size.toString());

    return this.http
      .get<ResponseDTO<GroupAssociationDTO>>(`${this.apiUrl}/GetAll`, {
        params,
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  getGroupAssociationById(
    groupAssociationId: number,
  ): Observable<{ success: boolean; data: GroupAssociationDTO }> {
    return this.http
      .get<{
        success: boolean;
        data: GroupAssociationDTO;
      }>(`${this.apiUrl}/GetById/${groupAssociationId}`)
      .pipe(catchError(this.handleError.bind(this)));
  }

  addGroupAssociation(
    group: GroupAssociationDTO,
  ): Observable<GroupAssociationDTO> {
    return this.http
      .post<GroupAssociationDTO>(`${this.apiUrl}/Add`, group)
      .pipe(catchError(this.handleError.bind(this)));
  }

  updateGroupAssociation(
    group: GroupAssociationDTO,
  ): Observable<GroupAssociationDTO> {
    return this.http
      .patch<GroupAssociationDTO>(`${this.apiUrl}/Update`, group)
      .pipe(catchError(this.handleError.bind(this)));
  }

  deleteGroupAssociation(groupAssocId: number): Observable<any> {
    return this.http
      .delete(`${this.apiUrl}/Delete/${groupAssocId}`)
      .pipe(catchError(this.handleError.bind(this)));
  }

  searchGroupAssociationsByKeyword(
    keyword: string,
  ): Observable<GroupAssociationDTO[]> {
    return this.http
      .get<GroupAssociationDTO[]>(`${this.apiUrl}/SearchByKeyword`, {
        params: { keyword },
      })
      .pipe(catchError(this.handleError.bind(this)));
  }
}
