<div class="airline-details-container">
  <div class="global-card-header">
    <div class="header-with-back">
      <button
        mat-icon-button
        (click)="navigateToPartners()"
        class="back-button"
      >
        <mat-icon>arrow_back</mat-icon>
      </button>
      <div class="header-titles">
        <h1 class="global-card-title">{{ airlineData?.airlineName }}</h1>
        <h2 class="global-card-subtitle">{{ airlineData?.airlineCode }}</h2>
      </div>
    </div>
    <div class="tab-nav">
      <span
        class="tab-link"
        [class.active]="activeTab === 'overview'"
        (click)="switchToOverview()"
        >Overview</span
      >
      <span
        class="tab-link"
        [class.active]="activeTab === 'accessDocuments'"
        (click)="switchToAccessDocuments()"
        >Access Documents</span
      >
      <div class="action-buttons" *ngIf="activeTab !== 'accessDocuments'">
        <button mat-icon-button (click)="editAirline()">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button (click)="deleteAirline()">
          <mat-icon>delete</mat-icon>
        </button>
        <button mat-icon-button (click)="refresh()">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <!-- Overview content -->
  <div *ngIf="activeTab === 'overview'" class="airline-overview">
    <div class="card-row">
      <div class="basic-info-card card">
        <div class="card-header">
          <img
            *ngIf="imageUrl; else defaultImage"
            [src]="imageUrl"
            alt="Airline Image"
            class="card-image"
          />
          <ng-template #defaultImage>
            <img alt="Default Image" class="card-image" />
          </ng-template>
        </div>
        <div class="card-body">
          <div class="detail-item">
            <span class="detail-title">Airline ID:</span>
            {{ airlineData?.airlineId }}
          </div>
          <div class="detail-item">
            <span class="detail-title">Airline Name:</span>
            {{ airlineData?.airlineName }}
          </div>
          <div class="detail-item">
            <span class="detail-title">Airline Code:</span>
            {{ airlineData?.airlineCode }}
          </div>
          <div class="detail-item">
            <span class="detail-title">Airline Association:</span>
            {{ extractGroupNames(airlineData?.groupAssociations || []) }}
          </div>
        </div>
      </div>

      <div class="fields-info-card card">
        <div class="card-header">
          <h3><strong>Fields Information</strong></h3>
        </div>
        <div class="card-body">
          <div class="detail-item">
            <span class="detail-title">Passenger Mandatory Fields:</span>
            <ul>
              <li
                *ngFor="
                  let field of getFieldNamesFromInteger(
                    airlineData?.passengerMandatoryFields || 0,
                    passengerFields
                  )
                "
              >
                {{ field }}
              </li>
            </ul>
          </div>
          <div class="detail-item">
            <span class="detail-title">Guest Mandatory Fields:</span>
            <ul>
              <li
                *ngFor="
                  let field of getFieldNamesFromInteger(
                    airlineData?.guestMandatoryFields || 0,
                    guestFields
                  )
                "
              >
                {{ field }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Access Documents content -->
  <div *ngIf="activeTab === 'accessDocuments'" class="access-documents">
    <div *ngIf="filteredCards.length === 0">
      <mat-card>
        <mat-card-content>
          <p>No cards available for this airline.</p>
        </mat-card-content>
      </mat-card>
    </div>
    <div *ngIf="filteredCards.length > 0">
      <aims-access-documents
        [airlineId]="airlineData?.airlineId"
        [showFullContent]="false"
        [showActions]="false"
        [showSearchBar]="false"
        [filteredCards]="filteredCards"
      ></aims-access-documents>
    </div>
  </div>
</div>
