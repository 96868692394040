import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { WorkstationService } from '../../../services/workstation.service';
import { AirportService } from '../../../services/airport.service';
import { LoungeService } from '../../../services/lounge.service';
import { AirlineService } from '../../../services/airline.service';
import { AirportDTO } from '../../../models/dtos/airportDTO';
import { LoungeDTO } from '../../../models/dtos/loungeDTO';
import { WorkstationDTO } from '../../../models/dtos/workstationDTO';
import { AirlineDTO } from '../../../models/dtos/airlineDTO';
import { LoadingService } from '../../../services/helpers/loading.service';
import { startWith } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'aims-create-workstation',
  templateUrl: './create-workstation.component.html',
  styleUrls: ['./create-workstation.component.scss'],
  standalone: false,
})
export class CreateWorkstationComponent implements OnInit {
  workstationForm!: FormGroup;
  airportSearchControl = new FormControl('');
  airports: AirportDTO[] = [];
  filteredAirports: AirportDTO[] = [];
  lounges: LoungeDTO[] = [];
  filteredLounges: LoungeDTO[] = [];
  airlines: AirlineDTO[] = [];
  isSubmitting = false;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CreateWorkstationComponent>,
    private workstationService: WorkstationService,
    private airportService: AirportService,
    private loungeService: LoungeService,
    private airlineService: AirlineService,
    private loadingService: LoadingService,
  ) {}

  ngOnInit(): void {
    this.fetchAirports();
    this.fetchLounges();
    this.fetchAirlines();
    this.initializeForm();

    this.airportSearchControl.valueChanges
      .pipe(
        startWith(''),
        map((value) => this.filterAirports(value || '')),
      )
      .subscribe((filtered) => (this.filteredAirports = filtered));
  }

  fetchAirports(): void {
    this.loadingService.show();
    this.airportService.getAirports().subscribe(
      (response) => {
        if (
          response &&
          response.success &&
          response.data &&
          response.data.pageData
        ) {
          this.airports = response.data.pageData;
        } else {
          this.airports = [];
        }
        this.loadingService.hide();
      },
      (error) => {
        this.airportService.showError(
          'Error fetching airports:',
          error.message,
        );
        this.airports = [];
      },
    );
  }

  filterAirports(searchText: string): AirportDTO[] {
    const lowercaseSearch = searchText.toLowerCase();
    return this.airports.filter((airport) =>
      airport.airportName.toLowerCase().includes(lowercaseSearch),
    );
  }

  onSelectAirport(event: any): void {
    const selectedAirport = event.option.value;
    this.workstationForm.get('airportId')?.setValue(selectedAirport.airportId);
  }

  displayAirportName(airport: AirportDTO): string {
    return airport ? airport.airportName : '';
  }

  showAllAirports(): void {
    this.filteredAirports = [...this.airports];
  }

  fetchLounges(): void {
    this.loungeService.getLounges().subscribe(
      (response) => {
        if (
          response &&
          response.success &&
          response.data &&
          response.data.pageData
        ) {
          this.lounges = response.data.pageData;
        } else {
          this.lounges = [];
        }
      },
      (error) => {
        this.loungeService.showError('Error fetching lounges:', error.message);
        this.lounges = [];
      },
    );
  }

  fetchAirlines(): void {
    this.airlineService.getAllAirlines().subscribe(
      (response) => {
        if (response && response.success && response.data) {
          this.airlines = response.data.pageData;
          this.initializeForm(); // Ensure form initialization after fetching airlines
        } else {
          this.airlines = [];
        }
      },
      (error) => {
        this.airportService.showError(
          'Error fetching airlines:',
          error.message,
        );
        this.airlines = [];
      },
    );
  }

  initializeForm(): void {
    this.workstationForm = this.fb.group({
      airportId: ['', Validators.required],
      loungeId: ['', Validators.required],
      workstationName: ['', Validators.required],
      defaultAirlineId: [1345, Validators.required], // @todo: change to current user airline id
    });

    this.workstationForm
      .get('airportId')
      ?.valueChanges.subscribe((airportId) => {
        this.filterLounges(airportId);
      });
  }

  filterLounges(airportId: number): void {
    this.filteredLounges = this.lounges.filter(
      (lounge) => lounge.airportId === airportId,
    );
    this.workstationForm.controls['loungeId'].setValue('');
  }

  onSubmit(): void {
    if (this.workstationForm.valid) {
      this.isSubmitting = true;
      const workstation: WorkstationDTO = {
        workstationId: 0,
        airportId: this.workstationForm.get('airportId')?.value,
        loungeId: this.workstationForm.get('loungeId')?.value,
        workstationName: this.workstationForm.get('workstationName')?.value,
        defaultAirlineId: this.workstationForm.get('defaultAirlineId')?.value,
      };

      this.loadingService.show();

      this.workstationService
        .addWorkstation(workstation)
        .subscribe(
          (response) => {
            this.loadingService.hide();
            Swal.fire({
              title: 'Success!',
              text: 'Workstation created successfully.',
              icon: 'success',
              confirmButtonColor: '#2893cc',
              confirmButtonText: 'OK',
            }).then(() => {
              this.dialogRef.close(response);
              window.location.reload();
            });
          },
          (error) => {
            this.loadingService.hide();
            this.workstationService.showError(
              'Error creating workstation:',
              error.message,
            );
          },
        )
        .add(() => {
          this.isSubmitting = false;
        });
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  getAirportName(airportId: number): string {
    const airport = this.airports.find((a) => a.airportId === airportId);
    return airport ? airport.airportName : '';
  }

  getLoungeName(loungeId: number): string {
    const lounge = this.lounges.find((l) => l.loungeId === loungeId);
    return lounge ? lounge.loungeName : '';
  }

  getAirlineName(airlineId: number): string {
    const airline = this.airlines.find((a) => a.airlineId === airlineId);
    return airline ? airline.airlineName : '';
  }
}
