<div class="dialog-container">
  <aims-loading></aims-loading>
  <button mat-icon-button class="close-button" (click)="onCancel()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-vertical-stepper [linear]="true" #stepper>
    <!-- Step 1: Airline Information -->
    <mat-step [stepControl]="partnerForm.controls['airlineName']">
      <ng-template matStepLabel>Airline Information</ng-template>
      <form [formGroup]="partnerForm">
        <div class="input-group">
          <mat-form-field appearance="fill">
            <mat-label>Airline Name</mat-label>
            <input
              matInput
              formControlName="airlineName"
              type="text"
              placeholder="example: Air Canada"
            />
          </mat-form-field>
        </div>
        <div class="input-group">
          <mat-form-field appearance="fill">
            <mat-label>Airline Code</mat-label>
            <input
              matInput
              formControlName="airlineCode"
              type="text"
              placeholder="example: AC"
              maxlength="3"
              minlength="2"
            />
          </mat-form-field>
        </div>
        <div class="input-group">
          <div *ngIf="currentImageHandle">
            <mat-form-field appearance="fill">
              <mat-label>Airline Image Handle</mat-label>
              <input
                matInput
                formControlName="airlineImageHandle"
                type="text"
                [value]="currentImageHandle"
                readonly
              />
              <button mat-icon-button matSuffix (click)="onDeleteImageHandle()">
                <mat-icon>clear</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div *ngIf="!currentImageHandle">
            <input type="file" (change)="onFileSelected($event)" />
          </div>
        </div>

        <div class="dialog-actions">
          <button mat-raised-button color="primary" matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>

    <!-- Step 2: Airline associations -->
    <mat-step [stepControl]="partnerForm">
      <ng-template matStepLabel>Airline Associations</ng-template>
      <form [formGroup]="partnerForm">
        <div class="input-group">
          <div
            *ngFor="let group of groupAssociations; let i = index"
            class="checkbox-group"
          >
            <mat-checkbox [formControl]="getGroupControl(i)">
              {{ group.groupName }}
            </mat-checkbox>
          </div>
        </div>
        <div class="dialog-actions">
          <button mat-raised-button color="warn" matStepperPrevious>
            Back
          </button>
          <button mat-raised-button color="primary" matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>

    <!-- Step 3: Passenger Fields -->
    <mat-step>
      <ng-template matStepLabel>Passenger Fields</ng-template>
      <form [formGroup]="partnerForm" class="form-horizontal">
        <div *ngFor="let field of passengerFields" class="field-group">
          <strong
            ><label>{{ field.label }}</label></strong
          >
          <mat-radio-group
            formControlName="passenger-{{ field.key }}"
            class="radio-group-horizontal"
          >
            <mat-radio-button [value]="0">Disabled</mat-radio-button>
            <mat-radio-button [value]="1">Mandatory</mat-radio-button>
            <mat-radio-button [value]="2">Optional</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="dialog-actions">
          <button mat-raised-button color="warn" matStepperPrevious>
            Back
          </button>
          <button
            mat-raised-button
            color="primary"
            (click)="computeFieldValues(); stepper.next()"
          >
            Next
          </button>
        </div>
      </form>
    </mat-step>

    <!-- Step 4: Guest Fields -->
    <mat-step>
      <ng-template matStepLabel>Guest Fields</ng-template>
      <form [formGroup]="partnerForm" class="form-horizontal">
        <div *ngFor="let field of guestFields" class="field-group">
          <strong
            ><label>{{ field.label }}</label></strong
          >
          <mat-radio-group
            formControlName="guest-{{ field.key }}"
            class="radio-group-horizontal"
          >
            <mat-radio-button [value]="0">Disabled</mat-radio-button>
            <mat-radio-button [value]="1">Mandatory</mat-radio-button>
            <mat-radio-button [value]="2">Optional</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="dialog-actions">
          <button mat-raised-button color="warn" matStepperPrevious>
            Back
          </button>
          <button
            mat-raised-button
            color="primary"
            (click)="computeFieldValues(); stepper.next()"
          >
            Next
          </button>
        </div>
      </form>
    </mat-step>

    <!-- Step 5: Review and Submit -->
    <mat-step>
      <ng-template matStepLabel>Review and Submit</ng-template>
      <p>Please review all details before submitting.</p>
      <ul>
        <li>
          <strong>Airline Name:</strong> {{ partnerForm.value?.airlineName }}
        </li>
        <li>
          <strong>Airline Code:</strong> {{ partnerForm.value?.airlineCode }}
        </li>
        <li>
          <strong id="airlineName">Airline Image Handle:</strong>
          {{ partnerForm.value?.airlineImageHandle || "Default" }}
        </li>
        <li>
          <strong>Airline Associations:</strong>
          {{ getSelectedGroupNames() }}
        </li>
        <li>
          <strong>Passenger Mandatory Fields:</strong>
          <ul>
            <li *ngFor="let field of getMandatoryFields('passenger')">
              {{ field.label }}
            </li>
          </ul>
        </li>
        <li>
          <strong>Guest Mandatory Fields:</strong>
          <ul>
            <li *ngFor="let field of getMandatoryFields('guest')">
              {{ field.label }}
            </li>
          </ul>
        </li>
      </ul>
      <div class="dialog-actions">
        <button mat-raised-button color="warn" matStepperPrevious>Back</button>
        <button mat-raised-button color="primary" (click)="onSubmit()">
          Submit
        </button>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</div>
