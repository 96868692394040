import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { SideBarComponent } from './layout/side-bar/side-bar.component';
import { MainSectionComponent } from './components/main-section/main-section.component';
import { AirportsComponent } from './components/airports/airports.component';
import { LoungesComponent } from './components/lounges/lounges.component';
import { WorkstationsComponent } from './components/workstations/workstations.component';
import { PartnersComponent } from './components/partners/partners.component';
import { AccessDocumentsComponent } from './components/access-documents/access-documents.component';
import { AirlineDetailsComponent } from './components/partners/airline-details/airline-details.component';
import { CardDetailsComponent } from './components/access-documents/card-details/card-details.component';
import { AirportDetailsComponent } from './components/airports/airport-details/airport-details.component';
import { CreateAirportComponent } from './components/airports/create-airport/create-airport.component';
import { EditAirportComponent } from './components/airports/edit-airport/edit-airport.component';
import { CreateLoungeComponent } from './components/lounges/create-lounge/create-lounge.component';
import { LoungeDetailsComponent } from './components/lounges/lounge-details/lounge-details.component';
import { EditLoungeComponent } from './components/lounges/edit-lounge/edit-lounge.component';
import { WorkstationDetailsComponent } from './components/workstations/workstation-details/workstation-details.component';
import { CreateWorkstationComponent } from './components/workstations/create-workstation/create-workstation.component';
import { EditWorkstationComponent } from './components/workstations/edit-workstation/edit-workstation.component';
import { LayoutToolbarComponent } from './layout/layout-toolbar/layout-toolbar.component';
import { SearchBarComponent } from './layout/search-bar/search-bar.component';
import { QueryLayoutComponent } from './layout/query-layout/query-layout.component';
import { QueryPageComponent } from './layout/query-page/query-page/query-page.component';

import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MomentDateModule,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from '@angular/material/core';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { LoungeHoursComponent } from './components/lounges/lounge-hours/lounge-hours.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { LoadingComponent } from './layout/loading/loading.component';
import { CardValidationComponent } from './components/card-validation/card-validation.component';
import { CreateCardValidationComponent } from './components/card-validation/create-card-validation/create-card-validation.component';
import { EditCardValidationComponent } from './components/card-validation/edit-card-validation/edit-card-validation.component';
import { CreateCardValidationProgramComponent } from './components/card-validation/create-card-validation-program/create-card-validation-program.component';
import { CardValidationProgramComponent } from './components/card-validation/card-validation-program/card-validation-program.component';
import { EditCardValidationProgramComponent } from './components/card-validation/edit-card-validation-program/edit-card-validation-program.component';
import { GroupAssociationsComponent } from './components/partners/group-associations/group-associations.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AirlineAccessDocumentsSidebarComponent } from './components/main-section/airline-access-documents-sidebar/airline-access-documents-sidebar.component';
import { CdkDrag, CdkDropList, DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { SelectLoungeDialogComponent } from './components/main-section/select-lounge-dialog/select-lounge-dialog.component';
import { SelectAirportDialogComponent } from './components/main-section/select-airport-dialog/select-airport-dialog.component';
import { MatChip, MatChipInput } from '@angular/material/chips';
import { MatProgressBar } from '@angular/material/progress-bar';
import { NgSelectModule } from '@ng-select/ng-select';

import {
  MsalModule,
  MsalRedirectComponent,
  MsalInterceptor,
  MsalBroadcastService,
  MsalGuard,
  MsalService,
  MsalGuardConfiguration,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { environment } from '../environments/environment';
import { LoginComponent } from './auth/login/login.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { AllianceLogosComponent } from './shared/alliance-logos/alliance-logos.component';
import { SearchableSelectComponent } from './shared/searchable-select/searchable-select.component';
import { AirlinePartnerDialogComponent } from './components/partners/airline-partner-dialog/airline-partner-dialog.component';
import { GroupAssociationDialogComponent } from './components/partners/group-associations/group-association-dialog/group-association-dialog.component';
import { CardDetailsDialogComponent } from './components/access-documents/card-details-dialog/card-details-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SideBarComponent,
    MainSectionComponent,
    AirportsComponent,
    LoungesComponent,
    WorkstationsComponent,
    PartnersComponent,
    AccessDocumentsComponent,
    AirlineDetailsComponent,
    CardDetailsComponent,
    AirportDetailsComponent,
    CreateAirportComponent,
    EditAirportComponent,
    CreateLoungeComponent,
    LoungeDetailsComponent,
    EditLoungeComponent,
    WorkstationDetailsComponent,
    CreateWorkstationComponent,
    EditWorkstationComponent,
    LayoutToolbarComponent,
    SearchBarComponent,
    QueryLayoutComponent,
    QueryPageComponent,
    LoungeHoursComponent,
    LoadingComponent,
    CardValidationComponent,
    CreateCardValidationComponent,
    EditCardValidationComponent,
    CreateCardValidationProgramComponent,
    CardValidationProgramComponent,
    EditCardValidationProgramComponent,
    GroupAssociationsComponent,
    AirlineAccessDocumentsSidebarComponent,
    SelectLoungeDialogComponent,
    SelectAirportDialogComponent,
    LoginComponent,
    LogoutComponent,
    AllianceLogosComponent,
    SearchableSelectComponent,
    AirlinePartnerDialogComponent,
    GroupAssociationDialogComponent,
    CardDetailsDialogComponent,
  ],
  exports: [AirlineAccessDocumentsSidebarComponent],
  bootstrap: [AppComponent, MsalRedirectComponent],
  imports: [
    BrowserModule,
    CarouselModule.forRoot(),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    MatTabsModule,
    MatCardModule,
    MatStepperModule,
    MatSelectModule,
    MatDatepickerModule,
    MomentDateModule,
    MatSlideToggle,
    MatRadioGroup,
    MatRadioButton,
    MatNativeDateModule,
    MatCheckbox,
    CdkDropList,
    CdkDrag,
    DragDropModule,
    CommonModule,
    MatChip,
    MatChipInput,
    MatProgressBar,
    NgSelectModule,
    MsalModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: environment.azureAd.clientId,
          authority: environment.azureAd.authority,
          redirectUri: environment.azureAd.redirectUri,
          postLogoutRedirectUri: environment.azureAd.postLogoutRedirectUri,
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: false,
        },
      }),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: environment.azureAd.scopes,
        },
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          [environment.apiUrl, environment.azureAd.scopes],
        ]),
      },
    ),
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    { provide: DateAdapter, useClass: MomentDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useValue: {
        interactionType: InteractionType.Redirect,
        loginFailedRoute: '/login', // Redirect to login page instead of Azure
      } as MsalGuardConfiguration,
    },
    MsalGuard,
    MsalService,
    MsalBroadcastService,
  ],
})
export class AppModule {}
