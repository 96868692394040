import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SearchProviderService } from '../../services/helpers/search-provider.service';

@Component({
  selector: 'aims-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  standalone: false,
})
export class SearchBarComponent implements OnInit, OnChanges {
  public form: FormGroup = new FormGroup({
    search: new FormGroup({
      terms: new FormControl(),
    }),
  });

  @Input() disabled!: boolean;
  @Input() useQuery = true;
  @Input() noMargin = true;
  @Input() key = 'search.terms';
  @Input() componentName!: string;

  @Output() public termsChanged$ = new EventEmitter<any>();
  @Output() public reset$ = new EventEmitter<void>();
  @Output() public clearTerms$ = new EventEmitter<void>();

  private minChars: { [key: string]: number } = {
    partners: 2,
    default: 3,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private searchProviderService: SearchProviderService,
  ) {}

  public get hasValue() {
    const searchTermsControl = this.form.get('search.terms');
    return !!searchTermsControl && !!searchTermsControl.value;
  }

  ngOnInit() {
    this.route.queryParams.pipe(take(1)).subscribe((q) => {
      if (this.useQuery) {
        const searchTermsControl = this.form.get('search.terms');
        if (searchTermsControl) {
          searchTermsControl.setValue(
            decodeURIComponent(q['search.terms'] || ''),
          );
        }
      }
    });

    const searchTermsControl = this.form.get('search.terms');
    if (searchTermsControl) {
      searchTermsControl.valueChanges
        .pipe(debounceTime(300), distinctUntilChanged())
        .subscribe((value: string | null) => {
          const minLength =
            this.minChars[this.componentName] || this.minChars['default'];
          if (value && value.length >= minLength) {
            if (this.useQuery) {
              this.router.navigate([], {
                relativeTo: this.route,
                queryParams: {
                  [this.key]: value === '' ? null : value,
                  'page.index': 0,
                },
                replaceUrl: true,
                queryParamsHandling: 'merge',
              });
            } else {
              this.search(value);
            }
          } else if (value === '' || (value && value.length < minLength)) {
            this.clearTerms$.emit();
          }
        });
    }
  }

  ngOnChanges() {
    const searchTermsControl = this.form.get('search.terms');
    if (searchTermsControl) {
      this.disabled
        ? searchTermsControl.disable({ emitEvent: false })
        : searchTermsControl.enable({ emitEvent: false });
    }
  }

  reset() {
    this.form.reset();
    this.reset$.emit();
  }

  clearSearch() {
    this.form.get('search.terms')?.setValue('');
  }

  search(value: string) {
    this.searchProviderService
      .searchByKeyword(this.componentName, value)
      .subscribe((results: any) => {
        this.termsChanged$.emit({ results, searchTerm: value });
      });
  }
}
