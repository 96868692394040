<div class="dialog-container">
  <!-- Loading Component -->
  <aims-loading></aims-loading>

  <button mat-icon-button class="close-button" (click)="onCancel()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-vertical-stepper [linear]="true" #stepper>
    <!-- Step 1: Effective Dates -->
    <mat-step [stepControl]="loungeHoursForm.controls['effectiveFromUTC']">
      <ng-template matStepLabel>Effective Dates</ng-template>
      <form [formGroup]="loungeHoursForm">
        <div class="step-section">
          <mat-form-field appearance="fill" class="form-field">
            <mat-label>Effective From</mat-label>
            <input
              matInput
              [matDatepicker]="pickerFrom"
              formControlName="effectiveFromUTC"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="pickerFrom"
            ></mat-datepicker-toggle>
            <mat-datepicker #pickerFrom></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="fill" class="form-field">
            <mat-label>Effective To</mat-label>
            <input
              matInput
              [matDatepicker]="pickerTo"
              formControlName="effectiveToUTC"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="pickerTo"
            ></mat-datepicker-toggle>
            <mat-datepicker #pickerTo></mat-datepicker>
          </mat-form-field>
        </div>
        <button mat-raised-button color="primary" matStepperNext>Next</button>
      </form>
    </mat-step>

    <!-- Step 2: Lounge Hours -->
    <mat-step [stepControl]="loungeHoursForm.controls['hours']">
      <ng-template matStepLabel>Lounge Hours</ng-template>
      <form [formGroup]="loungeHoursForm">
        <div class="hours-container" formArrayName="hours">
          <div
            *ngFor="let hour of hours.controls; let i = index"
            [formGroupName]="i"
            class="day-row"
          >
            <span class="day-name">{{ daysOfWeek[i] }}</span>
            <mat-checkbox formControlName="isOpen">Open</mat-checkbox>
            <mat-form-field
              appearance="fill"
              class="time-field"
              *ngIf="hour.get('isOpen')?.value"
            >
              <mat-label>Start Time</mat-label>
              <input matInput formControlName="startTime" type="time" />
            </mat-form-field>
            <mat-form-field
              appearance="fill"
              class="time-field"
              *ngIf="hour.get('isOpen')?.value"
            >
              <mat-label>End Time</mat-label>
              <input matInput formControlName="endTime" type="time" />
            </mat-form-field>
          </div>
        </div>
        <div class="buttons-container">
          <button mat-raised-button color="warn" matStepperPrevious>
            Back
          </button>
          <button mat-raised-button color="primary" matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>

    <!-- Step 3: Review and Submit -->
    <mat-step>
      <ng-template matStepLabel>Review and Submit</ng-template>
      <div class="review-section">
        <p>
          <strong>Effective From:</strong>
          {{
            loungeHoursForm.get("effectiveFromUTC")?.value | date: "yyyy-MM-dd"
          }}
        </p>
        <p>
          <strong>Effective To:</strong>
          {{
            loungeHoursForm.get("effectiveToUTC")?.value | date: "yyyy-MM-dd"
          }}
        </p>
        <div *ngFor="let hour of getOpenHours(); let i = index">
          <p>
            <strong>{{ daysOfWeek[hour.dayOfWeek] }}:</strong>
            {{ hour.startTime }} - {{ hour.endTime }}
          </p>
        </div>
      </div>
      <div class="buttons-container">
        <button mat-raised-button color="warn" matStepperPrevious>Back</button>
        <button mat-raised-button color="primary" (click)="onSubmit()">
          Submit
        </button>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</div>
