import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GroupAssociationService } from '../../../../services/group-association.service';
import { FilesService } from '../../../../services/files.service';
import { ConfigService } from '../../../../services/helpers/config.service';
import { LoadingService } from '../../../../services/helpers/loading.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GroupAssociationDTO } from '../../../../models/dtos/groupAssociationDTO';
import Swal from 'sweetalert2';

interface DialogData {
  groupAssociation: GroupAssociationDTO | null;
}

@Component({
  selector: 'aims-group-association-dialog',
  standalone: false,
  templateUrl: './group-association-dialog.component.html',
  styleUrls: ['./group-association-dialog.component.scss'],
})
export class GroupAssociationDialogComponent implements OnInit {
  isEditMode: boolean = false;
  groupAssociationForm: FormGroup;
  currentImageHandle: string | null = null;
  defaultImageUrl: string = 'TestStarAlliance.png';
  maxImageSize: number;

  constructor(
    private fb: FormBuilder,
    private groupAssociationService: GroupAssociationService,
    private filesService: FilesService,
    private configService: ConfigService,
    private loadingService: LoadingService,
    public dialogRef: MatDialogRef<GroupAssociationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    // Build the form
    this.groupAssociationForm = this.fb.group({
      groupName: ['', Validators.required],
      groupImageHandle: [''],
    });

    const maxImageSizeConfig = this.configService.getConfig(
      'maximumAirlineOrCardImageFileUploadSize',
    );
    this.maxImageSize = Math.ceil(maxImageSizeConfig / 1024);
  }

  ngOnInit(): void {
    this.isEditMode = !!(this.data && this.data.groupAssociation);
    if (this.isEditMode && this.data.groupAssociation) {
      this.loadExistingData();
    }
  }

  loadExistingData(): void {
    const group = this.data.groupAssociation;
    this.currentImageHandle = group?.groupImageHandle ?? null;
    this.groupAssociationForm.patchValue({
      groupName: group?.groupName ?? '',
      groupImageHandle: group?.groupImageHandle ?? this.defaultImageUrl,
    });
  }

  async onFileSelected(event: Event): Promise<void> {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      const formData = new FormData();
      formData.append('file', file);

      this.loadingService.show();

      if (file.size > this.maxImageSize * 1024) {
        const result = await Swal.fire({
          title: 'Image Size Exceeded',
          text: `The selected image size exceeds the maximum limit of ${this.maxImageSize} KB. Do you want to resize it?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, resize it!',
          confirmButtonColor: '#2893cc',
          cancelButtonText: 'No, I will upload a smaller image.',
        });

        if (result.isConfirmed) {
          const resizedResponse =
            await this.filesService.compressAndUploadAirlineOrCardImage(
              formData,
            );
          const resizedFileName = resizedResponse.data['fileName'];
          this.groupAssociationForm.patchValue({
            groupImageHandle: resizedFileName,
          });
          this.currentImageHandle = resizedFileName;
        } else {
          this.loadingService.hide();
          return;
        }
      } else {
        try {
          const response =
            await this.filesService.uploadAirlineOrCardImageFile(formData);
          const fileName = response.data['fileName'];
          this.groupAssociationForm.patchValue({ groupImageHandle: fileName });
          this.currentImageHandle = fileName;
        } catch (error: any) {
          Swal.fire({
            title: 'Error!',
            text: `File upload failed: ${error.message}`,
            icon: 'error',
            confirmButtonText: 'OK',
            confirmButtonColor: '#2893cc',
          });
        }
      }
      this.loadingService.hide();
    }
  }

  onDeleteImageHandle(): void {
    this.groupAssociationForm.patchValue({ groupImageHandle: '' });
    this.currentImageHandle = null;
  }

  onSubmit(): void {
    if (this.groupAssociationForm.valid) {
      const formValues = this.groupAssociationForm.value;
      const groupAssociation: GroupAssociationDTO = {
        groupAssociationId:
          this.isEditMode && this.data && this.data.groupAssociation
            ? this.data.groupAssociation.groupAssociationId
            : 0,
        groupName: formValues.groupName,
        groupImageHandle: formValues.groupImageHandle || this.defaultImageUrl,
      };

      this.loadingService.show();

      if (this.isEditMode) {
        this.groupAssociationService
          .updateGroupAssociation(groupAssociation)
          .subscribe(
            (response) => {
              this.loadingService.hide();
              Swal.fire({
                title: 'Success!',
                text: 'Group association updated successfully.',
                icon: 'success',
                confirmButtonColor: '#2893cc',
                confirmButtonText: 'OK',
              }).then(() => {
                this.dialogRef.close(response);
              });
            },
            (error) => {
              this.loadingService.hide();
              Swal.fire({
                title: 'Error!',
                text: `Failed to update group association: ${error.message}`,
                icon: 'error',
                confirmButtonColor: '#2893cc',
                confirmButtonText: 'OK',
              });
            },
          );
      } else {
        this.groupAssociationService
          .addGroupAssociation(groupAssociation)
          .subscribe(
            (response) => {
              this.loadingService.hide();
              Swal.fire({
                title: 'Success!',
                text: 'Group association added successfully.',
                icon: 'success',
                confirmButtonColor: '#2893cc',
                confirmButtonText: 'OK',
              }).then(() => {
                this.dialogRef.close(response);
              });
            },
            (error) => {
              this.loadingService.hide();
              Swal.fire({
                title: 'Error!',
                text: `Failed to add group association: ${error.message}`,
                icon: 'error',
                confirmButtonColor: '#2893cc',
                confirmButtonText: 'OK',
              });
            },
          );
      }
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Please fill out the form correctly before submitting.',
        icon: 'error',
        confirmButtonColor: '#2893cc',
        confirmButtonText: 'OK',
      });
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
